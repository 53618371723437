import { hasMembershipType, pgaTld } from '../utils'

const options = [
  {
    id: 516,
    label: 'My Account',
    to: '/',
    root: true
  },
  {
    id: 518,
    label: 'Jobs',
    to: `https://jobs.${pgaTld}`,
    external: true
  },
  {
    id: 519,
    label: 'Directory',
    to: `https://directory.${pgaTld}`,
    external: true
  },
  {
    id: 520,
    label: 'News',
    to: `https://news.${pgaTld}`,
    external: true
  },
  {
    id: 530,
    to: `https://events.${pgaTld}`,
    label: 'Events',
    external: true
  },
  {
    id: 517,
    label: 'Resources',
    to: `https://resources.${pgaTld}`,
    external: true
  },
  {
    id: 531,
    to: `https://resources.${pgaTld}/my-membership/pga-frisco`,
    label: 'PGA Frisco',
    external: true
  }
]

export const getMobileOptions = (me) => ([
  ...options,
  {
    id: 522,
    to: 'https://webmail.pgalinks.com/index.php',
    label: 'My Inbox',
    external: true,
    target: '_blank'
  },
  {
    id: 523,
    to: 'https://apps.pga.org/professionals/apps/membergolfshop/index.cfm?SMSESSION=NO',
    label: 'Shop',
    external: true,
    target: '_blank'
  },
  ...((hasMembershipType(me))
    ? [{
        to: '/change-email',
        label: 'Change My Email',
        external: false
      }]
    : []),
  {
    id: 521,
    label: 'Logout',
    to: '/logout'
  }
])

export const getDropdownLinks = (me) => ([
  {
    to: 'https://webmail.pgalinks.com/index.php',
    label: 'My Inbox',
    external: true,
    target: '_blank'
  },
  ...((hasMembershipType(me))
    ? [{
        to: '/change-email',
        label: 'Change My Email',
        external: false
      }]
    : [])
])

export default options
