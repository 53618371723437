import React, { Fragment, useState, useEffect } from 'react'
import { map, pathOr, take } from 'ramda'
import { Query } from '@apollo/client/react/components'
import { buildAlgoliaQuery, extractQueryParams } from '@pga/algolia-utils'

import Message, { NO_ADDITIONAL_JOBS, NO_JOBS, NO_PREFERENCES, ERROR } from './Messages'
import { initIndex } from '../../../lib/algolia'
import JobBlock from './JobBlock'
import { JOB_PREFERENCES_QUERY } from '../../queries/member'
import { Blocks } from './styled'
import LoadingRecommendations from './LoadingRecommendations'
import FlexRow from '../../FlexRow'
import JobsHeadLine from './JobsHeadLine'

export const JOB_DETAILS_URL = 'https://jobs.pga.org/detail/'
const JOBS_COUNT = 4

const getMessageTopic = jobsCount => jobsCount ? NO_ADDITIONAL_JOBS : NO_JOBS

export const RecommendedJobs = ({ jobs = [], searchStatus }) => {
  if (!searchStatus) {
    return (
      <Blocks>
        <Message topic={NO_PREFERENCES} size={JOBS_COUNT} />
      </Blocks>
    )
  }
  return (
    <Blocks>
      {
      map(job => <JobBlock job={job} key={job.jobId} />, jobs)
    }
      {
      jobs.length < JOBS_COUNT
        ? <Message topic={getMessageTopic(jobs.length)} size={JOBS_COUNT - jobs.length} />
        : null
    }
    </Blocks>
  )
}

export const JobPreferences = ({ me, jobsIndex, categories, jobPreference, setJobIds }) => {
  const [recommendedJobs, setJobs] = useState()
  const { jobSearchStatus } = jobPreference
  const { canSeePgaProJob } = me

  useEffect(() => {
    const getRecommendedJobs = async () => {
      const queryParams = extractQueryParams(jobPreference, categories, canSeePgaProJob)
      const algoliaQuery = buildAlgoliaQuery({ queryParams, hitsPerPage: 150 })

      if (!algoliaQuery) {
        setJobs([])
      } else {
        const { hits } = await jobsIndex.search('', algoliaQuery)
        setJobs(take(JOBS_COUNT, hits))
        setJobIds(hits.map(job => job.jobId))
      }
    }
    getRecommendedJobs()
  }, [])

  return recommendedJobs ? <RecommendedJobs jobs={recommendedJobs} searchStatus={jobSearchStatus} /> : <LoadingRecommendations />
}

export const jobPreferencesQueryHandler = ({ setJobIds }) => ({ loading, error, data }) => {
  const jobPreference = pathOr({}, ['me', 'jobPreference'], data)

  const jobsIndex = initIndex(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY,
    process.env.REACT_APP_ALGOLIA_JOBS_INDEX
  )

  if (loading) {
    return <LoadingRecommendations />
  }

  if (error) {
    return <Blocks><Message topic={ERROR} /></Blocks>
  }

  return (
    <JobPreferences
      jobPreference={jobPreference}
      categories={data.jobCategories}
      me={data.me}
      jobsIndex={jobsIndex}
      setJobIds={setJobIds}
    />
  )
}

export const RecommendedJobsWrapper = ({ setJobIds }) => <Query query={JOB_PREFERENCES_QUERY}>{jobPreferencesQueryHandler({ setJobIds })}</Query>

export default ({ me }) => {
  const [jobIds, setJobIds] = useState([])
  return (
    <>
      <FlexRow>
        <JobsHeadLine jobIds={jobIds} />
      </FlexRow>
      <FlexRow>
        <RecommendedJobsWrapper me={me} setJobIds={setJobIds} />
      </FlexRow>
    </>
  )
}
