import React from 'react'
import { Section, Flex, H1, Sub, IconPreferences, SearchIcon, PostJobsIcon } from './styles'

const JOBS_URL = 'https://jobs.pga.org'
const POST_JOBS_URL = `${JOBS_URL}/post`

export const goToUrl = url => { window.location.href = url }

export const ctaConfig = history => [{
  title: 'Search Jobs',
  subTitle: 'Find the latest jobs on the PGA Job Board.',
  IconComp: SearchIcon,
  goToFunc: () => goToUrl(JOBS_URL)
}, {
  title: 'Post Jobs',
  subTitle: 'Post jobs to the PGA Job Board.',
  IconComp: PostJobsIcon,
  goToFunc: () => goToUrl(POST_JOBS_URL)
}, {
  title: 'Job Preferences',
  subTitle: 'Receive job recommendations targeted to your career interests.',
  IconComp: IconPreferences,
  goToFunc: () => history.push('job-preferences')
}]

export default ({ title, subTitle, IconComp, goToFunc, history }) => (
  <Section onClick={goToFunc}>
    <Flex>
      <div>
        <H1>{title}</H1>
        <Sub>{subTitle}</Sub>
      </div>
      <IconComp />
    </Flex>
  </Section>
)
