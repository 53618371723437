import React from 'react'
import styled from 'styled-components'
import { darkgrey, primary, blueGrey } from '../../../assets/colors'
import { CheckIcon } from '../../Icons'
import { PlayfairDisplay, Montserrat } from '../../../assets/fonts'

export const EMPTY = 'empty'
export const CHECKED = 'checked'
export const CURRENT = 'current'

const isChecked = status => status === CHECKED
const isCurrent = status => status === CURRENT

export const TimelineWidget = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 10px;
  width: 100%;
`

export const TimelineBox = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
  margin: 0 auto;
  padding: 10px;
`
const borderColorFor = status => ({
  current: primary,
  empty: blueGrey,
  checked: darkgrey
}[status])

const LevelBox = styled.li`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border: 1.3px solid ${({ status }) => borderColorFor(status)};
  border-radius: 100%;
  ${({ status }) => isCurrent(status) && `background-color: ${primary};`}
  ${({ status }) => isChecked(status) && `background-color: ${darkgrey};`}

  &:before {
    content: ${({ label }) => `"${label}"`};
    position: absolute;
    margin-top: 25px;
    font-family: ${Montserrat};
    ${({ status }) => `color: ${status === CURRENT ? primary : blueGrey};`}
  }
`

export const Level = ({ status, label }) => <LevelBox status={status} label={label}>{isChecked(status) ? <CheckIcon className='p-0' /> : null}</LevelBox>

export const LevelNumber = styled.span`
  display: flex;
  align-content: center;
  align-items: center;
  padding-left: 10px;
  font-size: 32px;
  font-weight: bold;
  margin-top: -6px;
`

export const LevelConnector = styled.li`
  height: 1px;
  flex: 1;
  margin: 2px;
  ${({ status }) => `background-color: ${status !== EMPTY ? primary : blueGrey};`}
`

export const Title = styled.div`
  color: ${primary};
  font-family: ${PlayfairDisplay};
  font-size: 18px;
  font-weight: bold;
`
