import { map, values, keys, omit, compose, mergeRight } from 'ramda'

const hiddenCols = [
  'link',
  'id',
  'creditType'
]

export const transformCredit = options => map(opt => mergeRight(opt, {
  credits: `+${opt.credits}`
}), options)

export const visibleCols = omit(hiddenCols)

export const getColsLabel = compose(keys, visibleCols)
export const getRowFields = compose(values, visibleCols)
