import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { Theme, Button } from '@pga/pga-component-library'
import { PanelStyled, Flex, Wrapper, Title, Text, SaveCancelButtons, handleSubmit } from './shared'
import { CheckboxComp } from '../EditProfile/InputChanges/pieces/Checkbox'
import ErrorModal from '../utils/ErrorModal'

export const CheckWrapped = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin-top: 25px;
`

export const Label = styled.label`
  cursor: pointer;
`

export const ActionBtn = styled(Button)`
  font-family: ${Theme.fonts.Montserrat};
  border: 1px solid ${Theme.colors.lightBlue};
  ${props => props.noBottomBorder && 'border-bottom: 0px;'}
  font-size: 12px;
  height: 42px;
  width: ${props => props.large ? '200' : '150'}px;
  font-weight: 500;
  @media screen and (max-width: 575.98px) {
    width: 90%;
    align-self: center;
  }
`

export const ShowPublicBox = ({ values, setFieldValue }) => (
  <CheckWrapped>
    <Label onChange={() => setFieldValue('viewAddressMember', !values.viewAddressMember)}>
      <CheckboxComp name='viewAddressMember' checked={values.viewAddressMember} />
    </Label>
    <Text>Show on Member Directory</Text>
  </CheckWrapped>
)

export const FieldsAndButtons = ({
  formikProps: { values, setFieldValue, submitForm },
  showPerms,
  togglePerms,
  panelInfo: { title, panelData, link, Button, mappedFields }
}) => (
  <PanelStyled mobilePadded>
    <Flex>
      <Wrapper><Title>{title}</Title></Wrapper>
      <Wrapper grow>
        {mappedFields.map((item, i) => (
          <Fragment key={i}>
            <Text key={item}>{panelData[item]}</Text>
          </Fragment>
        ))}
        {showPerms && <ShowPublicBox {...{ values, setFieldValue }} />}
      </Wrapper>
      {showPerms
        ? <SaveCancelButtons togglePerms={togglePerms} submitForm={submitForm} />
        : (
          <Button
            options={[{
              label: 'EDIT VISIBILITY',
              permClick: togglePerms,
              noBottomBorder: true
            }, {
              link,
              label: 'UPDATE',
              noBottomBorder: false
            }]}
          />)}
    </Flex>
  </PanelStyled>
)

const fieldsAndBtnBuilder = (formikProps, showPerms, togglePerms, panelInfo) => <FieldsAndButtons {...{ formikProps, showPerms, togglePerms, panelInfo }} />

export default class extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      showPerms: false
    }
    this.togglePerms = this.togglePerms.bind(this)
    this.toggleErrorModal = this.toggleErrorModal.bind(this)
  }

  togglePerms () {
    this.setState({
      showPerms: !this.state.showPerms
    })
  }

  toggleErrorModal () {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render () {
    const { panelInfo, panelInfo: { panelData } } = this.props
    const { showPerms, isOpen } = this.state
    return (
      <>
        <Formik
          initialValues={{ viewAddressMember: panelData.viewAddressMember }}
          onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting, this.togglePerms, this.toggleErrorModal, this.props)}
          render={formikProps => fieldsAndBtnBuilder(formikProps, showPerms, this.togglePerms, panelInfo)}
        />
        <ErrorModal isOpen={isOpen} toggleModal={this.toggleErrorModal} />
      </>
    )
  }
}
