import React from 'react'
import Loadable from 'react-loadable'
import { LoadingComponent, withPageMetadata, metadata } from '@pga/pga-component-library'
import { PrivateRoute } from '../lib/auth'
import { Route, Switch, Redirect } from 'react-router-dom'
import CheckEducationStatus from './CheckEducationStatus'
import WrappedRouter from './WrappedRouter'
import { defaultRoutes, educationRoutes } from './RouteConfig'

export const loading = LoadingComponent

const AsyncLoadable = loader =>
  Loadable({
    loader,
    loading,
    delay: 300
  })

const NotFound = withPageMetadata(metadata('NOT FOUND'))(
  AsyncLoadable(() => import('../components/NotFound'))
)

export const Router = () => (
  <Switch>
    <Redirect from='/dashboard' to='/' />
    {defaultRoutes.map(({ exact, path, secured, component }) =>
      secured
        ? (
          <PrivateRoute
            key={path}
            exact={exact}
            path={path}
            component={component}
          />
          )
        : (
          <Route key={path} exact={exact} path={path} component={component} />
          )
    )}
    {educationRoutes.map(({ checkEducationStatus: rules, ...routeProps }, idx) =>
      (Array.isArray(rules) && rules.length > 0)
        ? <CheckEducationStatus key={idx} rules={rules} {...routeProps} />
        : <Route key={idx} {...routeProps} />
    )}
    <Route component={NotFound} />
  </Switch>
)

export default WrappedRouter
