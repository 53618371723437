import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'

export const ButtonLink = styled.a`
  padding: ${props => (props.outline && '13px 15px') || '15px 20px'};
  border: ${props => (props.outline && `solid 2px ${Theme.colors.lightBlue}`) || 'none'};
  color: ${props => (props.outline && Theme.colors.lightBlue) || 'white'};
  background-color: ${props => (props.outline && 'transparent') || Theme.colors.lightBlue};
  min-width: 120px;
  white-space: nowrap;
  margin: 5px;
  cursor: pointer;
  text-transform: uppercase;
  @media(max-width: 1199px){
    width: 100%;
    justify-content: center;
  }
  &:hover {
    text-decoration: none;
    color: white;
    background-color: ${Theme.colors.darkerLtGrey};
    border-color: ${Theme.colors.darkerLtGrey};
  }
`
