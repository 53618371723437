import React from 'react'
import { withAuthProvider } from '../lib/auth'
import { LoadingComponent, Error } from '@pga/pga-component-library'
import ContactMembership from './ContactMembership'
import TerminatedWrapper from '../components/Terminated'
import isTerminatedRules from '../components/Terminated/rules'
import { isTerminated, hasStaffAccess } from '../components/utils'
import { Router } from './Routes'
import { withRouter } from 'react-router-dom'
import ConsentWrapper from '../components/ConsentWrapper'
import { gql, useQuery } from '@apollo/client'

export const CONSENT_QUERY = gql`
  query ConsentQuery {
    me {
      id
      consentReceipts {
        id
      }
    }
  }
`

const Message = () => (
  <div>
    <h3>Something went wrong while logging in.</h3>
    <p>Please wait a few moments before trying again.</p>
    <ContactMembership />
  </div>
)

export const AppWrapper = ({ error, authReady, me, history }) => {
  const { data, error: consentError } = useQuery(CONSENT_QUERY)
  const { search, pathname } = history.location
  const params = new URLSearchParams(search)
  const isBypassConsent = params.get('bypassConsent') === 'true'
  const showConsent = data && !data?.me.consentReceipts.length && !isBypassConsent && !consentError

  if (error) return <Error Message={Message} />
  if (!authReady) return <LoadingComponent />

  if (showConsent) {
    return <ConsentWrapper me={me} history={history} />
  }

  if (me && isTerminated(me) && isTerminatedRules(me)) {
    if (hasStaffAccess(me) || pathname === '/background-check') {
      return <Router />
    }
    return <TerminatedWrapper me={me} />
  }

  return <Router />
}

export default withAuthProvider(withRouter(AppWrapper))
