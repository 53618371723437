import React from 'react'
import styled from 'styled-components'
import IntercomWithAuth from '../Intercom'
import RaygunUser from '../Raygun'
import Routes from './Routes'
import { AnalyticsWrapper as Analytics } from '../lib/Analytics'
import { withFeatureFlagProvider } from '../lib/feature-flags'
import { ImpersonationOverlay } from '../lib/auth'

export const MainBox = styled.main`
  height: 80vh;
`

export const App = () => (
  <MainBox>
    <ImpersonationOverlay />
    <RaygunUser />
    <Analytics />
    <Routes />
    <IntercomWithAuth />
  </MainBox>
)

export default withFeatureFlagProvider(App)
