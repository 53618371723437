import React from 'react'
import { InfoLabel, DuesButtons } from '../shared'
import { white, lightBlue } from '../../../assets/colors'
import { CardContainer, Container, FlexBox } from './shared'
import MediaQuery from 'react-responsive'

export default ({ refetch, reload }) => (
  <CardContainer>
    <Container>
      <FlexBox flexDirection='column' justifyContent='space-around' alignItems='center'>
        <InfoLabel>This widget failed to load. Please refresh.</InfoLabel>
        <MediaQuery maxWidth={767.98}><br /><br /></MediaQuery>
        <DuesButtons onClick={() => reload(refetch)} width='100%' color={white} bgColor={lightBlue}>REFRESH</DuesButtons>
      </FlexBox>
    </Container>
  </CardContainer>
)
