import { gql } from '@apollo/client'

export const getAnnouncementQuery = gql`
  query Announcements($group: String, $limit: Int, $section: String) {
    announcements(group: $group, limit: $limit, section: $section) {
      title
      message
      date
      label
      link
    }
  }
`
