import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import { InfoFrame } from '../shared'

export const CardContainer = styled.div`
  width: 100%;
  height: 157px;
  margin-bottom: 16px;

  @media screen and (max-width: 1199px) {
    height: 132px;
  }

  @media screen and (max-width: 991.97px) {
    height: 142px;
  }

  @media screen and (max-width: 767.98px) {
    min-height: 140px;
    height: auto;
  }
`

export const Links = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 25px;
  align-items: center;
`

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;

  @media screen and (max-width: 767.98px) {
    height: auto;
  }
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 ${Theme.colors.primaryShadow};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.25rem 1.5rem;
`

export const SubHeader = styled.div`
  font-family: ${Theme.fonts.Montserrat};
  font-size: 1em;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.8em;
`

export const Header = styled.div`
  font-family: ${Theme.fonts.Montserrat};
  font-size: 1.4em;
  color: black;
  font-weight: 500;
`

export const HeaderWrapper = styled.div`
  font-size: 12px;
  @media screen and (max-width: 1199px) {
    ${InfoFrame},
    ${SubHeader} {
      font-size: 0.9em;
    }
    ${Header} {
      font-size: 1.2em;
    }
  }

  @media screen and (max-width: 991.97px) {
    ${InfoFrame},
    ${SubHeader} {
      font-size: 1em;
    }
    ${Header} {
      font-size: 1.4em;
    }
  }
`

export const FlexBox = styled.div`
  display: flex;
  flex: 1;
  width: ${props => props.w || 'auto'};
  height: ${props => props.h || 'auto'};
  flex-direction: ${props => props.flexDirection || 'row'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: ${props => props.alignItems || 'stretch'};
`

export const AngleRight = styled.i.attrs({
  className: 'fa fa-angle-right pl-1'
})``
