import React from 'react'
import moment from 'moment'
import { propEq, propOr, equals, isNil, isEmpty, cond, always, T } from 'ramda'
import { SuccessTextComp, DeniedTextComp } from './TextComponents'
import { CppEnrollNowButton } from '../../Education/Progress/pieces'
import { MppProvisionButton } from '../../Education/MppProvision/index'

export const getApplyLinkByProgramCode = cond([
  [equals('CPP3'), always('/education/programs/cpp/apply')],
  [equals('MPP3'), always('/education/programs/mpp/apply')],
  [T, always('')]
])

export const getProgramNameByProgramCode = cond([
  [equals('CPP3'), always('Certified Professional Program')],
  [equals('MPP3'), always('Master Professional Program')],
  [T, always('')]
])

export const getEnrollButtonByProgramCode = cond([
  [equals('CPP3'), always(CppEnrollNowButton)],
  [equals('MPP3'), always(MppProvisionButton)],
  [T, always(null)]
])

export const getApprovedTextByProgramCode = cond([
  [equals('CPP3'), always('Just one more step and you’ll be on your way! To enroll and pay the $350 enrollment fee, please click on the link below.')],
  [equals('MPP3'), always('To get started, please click on the link below.')],
  [T, always('To get started, please click on the link below.')]
])

export const getModalConfig = (status, programCode) => {
  const applyLink = getApplyLinkByProgramCode(programCode)
  const programName = getProgramNameByProgramCode(programCode)
  if (status === 'APPR') {
    return {
      titleText: programName,
      subHeadlineText: 'Application Approved',
      textColor: 'green',
      TextComp: () => <SuccessTextComp programName={programName} text={getApprovedTextByProgramCode(programCode)} />,
      ButtonComp: getEnrollButtonByProgramCode(programCode)
    }
  }
  if (status === 'DEN') {
    return {
      titleText: programName,
      subHeadlineText: 'Application Denied',
      textColor: 'blue',
      TextComp: () => <DeniedTextComp programName={programName} />,
      buttonText: 'Reapply Now',
      buttonHandler: history => history.push(applyLink)
    }
  }
  return null
}

export const threeMonthsHasPassedSince = (pastDate) => {
  const pastMoment = moment(pastDate)
  const presentMoment = moment()
  if (isNil(pastDate) || isEmpty(pastDate) || !pastMoment.isValid()) {
    return false
  }
  const ninetyDaysAfterPastDate = pastMoment.add(90, 'days')
  return presentMoment.isAfter(ninetyDaysAfterPastDate)
}

export const isApprovedOrDenied = (status) => ['DEN', 'APPR'].includes(status)

export const doesModalMatchMemberStatus = (currentStatus, applicationInfo) => {
  const applicationStatus = propOr(null, 'latestApplicationStatusCode', applicationInfo)
  const applicationDate = propOr(null, 'applicationStatusStartDate', applicationInfo)
  return (
    isApprovedOrDenied(currentStatus) &&
    currentStatus === applicationStatus &&
    !threeMonthsHasPassedSince(applicationDate)
  )
}

export const ifDeniedSetOptOut = ({ currentStatus, modalCookie, memberId, cookies, cookieName }) => {
  if (equals('DEN', currentStatus) && (isNil(modalCookie) || !propEq('optedOut', true, modalCookie))) {
    cookies.set(cookieName, JSON.stringify({ id: memberId, optedOut: true }), { path: '/' })
  }
}
