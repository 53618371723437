export const primary = '#00234b'
export const primaryOpacity = 'rgba(0, 35, 75, 0.75)'
export const primaryWithOpacity = 'rgba(0, 35, 75, 0.5)'
export const primaryOpaque = 'rgba(0, 35, 75, 0)'
export const primaryShadow = 'rgba(0, 35, 75, 0.15)'
export const greyOpacity = 'rgba(0, 0, 0, 0.15)'
export const secondary = '#5A7485'
export const menu = '#46596b'
export const gold = '#b4975a'
export const grey = '#B1B1B1'
export const greyBlue = 'rgba(127, 145, 165, 0.5)'
export const lightgrey = '#f4f7f8'
export const darkgrey = '#dee2e3'
export const darkerLtGrey = '#868e96'
export const deepGrey = '#999999'
export const black = '#000000'
export const lightBlue = '#0082ce'
export const white = '#FFFFFF'
export const blueGrey = '#7f91a5'
export const editBlue = '#007bff'
export const msrDarkGray = '#354550'

export default {
  primary,
  secondary,
  menu,
  gold,
  lightBlue
}
