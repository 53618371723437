import * as flagrApi from './flagrApi'
import { flags } from './constants'
import { ExperimentFlags, InternalFlags } from './store'

const isExperimentFlag = (name) => name.startsWith('account-exp')

/* eslint-disable no-unused-vars */
const valueFlag = (name) => ({ name, init: (value) => value })
const booleanFlag = (name) => ({ name, init: (value) => value === 'true' })

const FLAGS = [
  booleanFlag(flags.FLAG_FEAT_MEMBERSHIP_COMMERCE_DUES),
  booleanFlag(flags.FLAG_FEAT_MERGE_CCMS_M360_INVOICE_REQS),
  booleanFlag(flags.FLAG_FEAT_EARN_CREDITS_REDIRECT)
]

const initializeFeatures = async user => {
  // retrieve experiment flag values from previous visits
  // also apply any internal overrides from /ab
  let flagValues = {
    ...ExperimentFlags.get(),
    ...InternalFlags.get()
  }

  // get the list of active flags for this application
  let activeFlagKeys = new Set()
  try {
    activeFlagKeys = await flagrApi.fetchActiveFlagKeys()
    // perform evaluations for any flags the user has not been evaluated for
    const userFlagKeys = new Set(Object.keys(flagValues))
    const flagKeysToEvaluate = Array.from(activeFlagKeys).filter(
      k => !userFlagKeys.has(k)
    )

    if (flagKeysToEvaluate.length > 0) {
      const flagEvaluations = await flagrApi.evaluateFlags(
        flagKeysToEvaluate,
        user
      )
      flagValues = {
        ...flagValues,
        ...flagEvaluations
      }
    }
  } catch (e) {
    window.rg4js &&
      window.rg4js('send', {
        error: e,
        tags: ['flagr']
      })
  }

  // prune any inactive flags
  flagValues = Object.keys(flagValues)
    .filter(f => activeFlagKeys.has(f))
    .reduce((acc, val) => {
      acc[val] = flagValues[val]
      return acc
    }, {})

  // set the experiment flag cookie for future visits
  if (user != null) {
    const experimentFlagValues = Object.keys(flagValues)
      .filter(f => isExperimentFlag(f))
      .reduce((acc, val) => {
        acc[val] = flagValues[val]
        return acc
      }, {})
    ExperimentFlags.set(experimentFlagValues)
  }

  return FLAGS.reduce(
    (acc, flag) => {
      if (flag.name in flagValues) {
        return {
          ...acc,
          [flag.name]: flag.init(flagValues[flag.name])
        }
      } else {
        return acc
      }
    },
    {}
  )
}

export default initializeFeatures
