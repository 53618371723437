import { gql } from '@apollo/client'

export const getMsr = gql`
  query Msr {
    me {
      id
      firstName
      msrStatus {
        cycle {
          endDate
        }
        credits {
          label
          earned
          required
          recovery
          totalDeficit
          new
        }
      }
    }
  }
`

export const JOB_PREFERENCES_QUERY = gql`
  query getMeJobPreferenceQuery {
    jobPreferenceFields {
      jobSearchStatus {
        label
        value
      }
      locations {
        label
        value
      }
    }
    jobCategories {
      label
      value
      subCategories {
        label
        value
        type
      }
    }

    me {
      id
      canSeePgaProJob
      jobPreference {
        id
        jobSearchStatus
        jobCategories

        minimumCompensation
        jobLocations
        openToSeasonal
        openToInternational
        optedInToJobSeekerEmails
      }
    }
  }
`

const addressFields = `
  address {
    address1
    address2
    address3
    address4
    zip
    country
    state
    city
  }
`

export const getMeIdQuery = gql`
  query getMeId {
    me {
      id
    }
  }
`

export const getMember = gql`
  query ProfileBriefing {
    me {
      id
      firstName
      lastName
      displayName
      class
      certified
      masterProfessional
      specialized
      photo
      type
      phoneNumber
      primaryEmail
      ${addressFields}
      section {
        id
        name
        website
      }
      primaryFacility {
        id
        name
        ${addressFields}
      }
    }
  }
`
export const EMAIL_MIGRATION_QUERY = gql`
  query EMAIL_MIGRATION_QUERY {
    meEmailMigrationData {
      id
      emailAddress
      tempPassword
      accessDate
      doNotShowAgain
    }
  }
`
export const EMAIL_MIGRATION_MUTATION = gql`
  mutation updateEmailMigration($id: ID!, $input: UpdateEmailMigrationInput) {
    updateEmailMigration(id: $id, input: $input) {
      id
      emailAddress
      tempPassword
      accessDate
      doNotShowAgain
    }
  }
`
export const USER_QUERY = gql`
  query USER_QUERY {
    me {
      id
      firstName
      lastName
      displayName
      class
      certified
      masterProfessional
      specialized
      photo
      type
      role
      phoneNumber
      primaryEmail
      electionDate
      loginEmail
      reciprocityEligible
      ${addressFields}
      section {
        id
        name
        website
      }
      termination {
        status
        type
        terminateCd
        terminateDate
      }
      education {
        mostRecentCompletedLevel
        levels {
          levelCd
        }
      }
    }
  }
`

export const getMeEducation = gql`
  query MeEducation {
    me {
      id
      type
      class
      committeeCodes
      reciprocityEligible
      gptpSuspension
      education {
        currentLevel
        companyName
        mostRecentCompletedLevel
        levels {
          levelCd
          completeCd
          startDate
          endDate
        }
      }
      termination {
        status
        type
      }
    }
  }
`

export const getCurrentProgram = gql`
  query CurrentProgramStatus {
    me {
      id
      type
      education {
        currentCppCareerPath
        currentMppCareerPath
        currentPrograms {
          programStatusCode
          programCode
          programStatusStartDate
          programStatusEndDate
          currentStatus
          programName
        }
        subscriptions {
          productId
        }
        currentCppApplicationInfo {
          careerPathCode
        }
        currentMppApplicationInfo {
          careerPathCode
        }
        cppProgramProgress {
          programStatusCd
          programCd
          careerPathCd
          levelCd
          levelStartDate
          levelEndDate
          nextLevelCd
          latestSubscriptionStartDate
          latestSubscriptionEndDate
          latestSubscriptionExpired
        }
        mppProgramProgress {
          programStatusCd
          programCd
          careerPathCd
          levelCd
          levelStartDate
          levelEndDate
          nextLevelCd
          latestSubscriptionStartDate
          latestSubscriptionEndDate
          latestSubscriptionExpired
        }
      }
    }
  }
`

export const getMySection = gql`
  query Section {
    me {
      id
      type
      section {
        id
        name
        careerConsultant {
          phone
          fullName
          photo
          email
        }
      }
    }
  }
`

export const getMsrHistory = gql`
  query MsrHistory {
    me {
      id
      msrHistory {
        addedDate
        creditsAccepted
        description1
      }
    }
  }
`

export const getDues = gql`
  query Dues {
    me {
      id
      type
      dues {
        duesBalance
        dueDate
        invoiceDate
        invoiceNum
      }
      termination {
        status
        type
      }
    }
  }
`
export const getEducationSubscription = gql`
  query EducationSubscription {
    me {
      id
      type
      education {
        subscriptions {
          productId
          startDate
          endDate
          description
        }
      }
    }
  }
`

export const getCPEHours = gql`
  query GetCPEHours {
    me {
      id
      type
      education {
        currentCppCareerPath
        cpeHours {
          id
          memberNumber
          courseTitle
          hours
          date
          careerPath
          approvalStatus
        }
      }
    }
  }
`

export const getMemberForEdit = gql`
  query GetMemberForEdit ($id: ID!) {
    member (id: $id) {
      id
      universalId
      firstName
      lastName
      class
      memberClassDescription
      type
      informalName
      displayName
      photo
      certified
      masterProfessional
      address {
        address1
        address2
        address3
        address4
        city
        country
        state
        zip
      }
      facilities {
        id
        name
        section {
          id
          name
        }
        address {
          state
        }
      }
      section {
        id
        name
        address {
            address1
            address2
            address3
            address4
            city
            state
            zip
          }
        primaryFacility {
          phoneNumber
          geolocation {
            lat
            lng
          }
        }
      }
      phoneNumber
      primaryMobile
      publicPhone
      publicMobile
      role
      primaryEmail
      publicEmail
      primaryFacility {
        id
        name
        phoneNumber
        geolocation {
          lat
          lng
        }
        address {
          address1
          address2
          address3
          address4
          city
          country
          state
          zip
        }
      }
      expertise {
        id
        name
      }
      social {
        facebook
        linkedin
        twitter
        youtube
        instagram
        website
      }
      personalCertifications {
        description
        effectiveYear
      }
      officialCertifications {
        description
        effectiveYear
      }
      personalAwards {
        description
        effectiveYear
      }
      officialAwards {
        description
        effectiveYear
      }
      personalAffiliations {
        description
        effectiveYear
      }
      officialAffiliations {
        description
        effectiveYear
      }
      overview
      viewingPermissions {
        viewCertificationsMember
        viewCertificationsPublic
        viewPhonePublic
        viewPhoneMember
        viewAddressPublic
        viewAddressMember
        viewExpertisePublic
        viewExpertiseMember
        viewEmailPublic
        viewEmailMember
        viewMobilePublic
        viewMobileMember
        viewAffiliationsPublic
        viewAffiliationsMember
        viewAwardsPublic
        viewAwardsMember
      }
    }
  }
`
