import React from 'react'
import { cond, T } from 'ramda'
import { withAuthProvider } from '../../../lib/auth'
import QuickLinks from '../QuickLinks'
import { Side } from '../Panel'
import MSRCreditsBrief, { CompactPDRBrief } from '../PDRCredits'
import { isMember, isAssociate, isStudent, hasReciprocityAttribute, isB25Class, hasProspectDashboard, isProspectPath } from '../../utils'
import { Loading } from '../CourseCurriculum/CourseCurriculum'
import CourseCurriculum from '../CourseCurriculum'
import PathBox from '../Prospect/PathBox'
import { withProspectDateQuery } from '../../../utils/prospect/withProspectDateQuery'

export const chooseAppropriateViewForUser = cond(
  [
    [({ loading }) => loading, () => <Loading />],
    [({ me }) => isMember(me), ({ collapsed }) => collapsed ? <CompactPDRBrief /> : <MSRCreditsBrief />],
    [({ me }) => isB25Class(me), ({ collapsed }) => collapsed ? <CompactPDRBrief /> : <MSRCreditsBrief />],
    [({ me }) => isAssociate(me), ({ collapsed }) => collapsed ? <div /> : <CourseCurriculum />],
    [({ me }) => isStudent(me), ({ collapsed }) => collapsed ? <div /> : <CourseCurriculum />],
    [({ me }) => hasReciprocityAttribute(me), ({ collapsed }) => collapsed ? <div /> : <CourseCurriculum />],
    [({ me, currentMember }) => hasProspectDashboard(me) || isProspectPath(currentMember), ({ collapsed }) => collapsed ? <div /> : <PathBox />],
    [T, ({ collapsed }) => <QuickLinks collapsed={collapsed} />]
  ]
)

export const MembershipSailingView = ({ me, authReady, collapsed, currentMember }) => {
  return (
    <Side basis={!collapsed && 55}>
      {chooseAppropriateViewForUser({ me, loading: !authReady, collapsed, currentMember })}
    </Side>
  )
}

export default withProspectDateQuery(withAuthProvider(MembershipSailingView))
