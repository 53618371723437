import React from 'react'
import { Query } from '@apollo/client/react/components'
import AnnouncementContainer, { ANNOUNCEMENT_COUNT } from './AnnouncementContainer'
import WrapWithFetch from '../WrapWithFetch'
import LoadingPlaceholder from './LoadingPlaceholder'
import ErrorLoading from './ErrorLoading'
import { getAnnouncementQuery } from '../../queries/announcement'
import { withAuthProvider } from '../../../lib/auth'

export const AnnouncementWrapper = ({ data, loading, error, refetch, card, collapsed, isFetching, reload }) => {
  if (loading || isFetching) {
    return <LoadingPlaceholder card={card} />
  }
  if (error) return <ErrorLoading {...{ refetch, reload, collapsed }} />

  const { announcements } = data
  return <AnnouncementContainer {...{ announcements, collapsed, card }} />
}

export const AnnouncementFetch = ({
  isFetching,
  reload,
  collapsed,
  card,
  authReady,
  me
}) => {
  if (collapsed) return null
  if (!authReady) return <LoadingPlaceholder card={card} />

  const variables = {
    group: me.type,
    section: me.section?.name,
    limit: ANNOUNCEMENT_COUNT
  }

  return (
    <Query query={getAnnouncementQuery} variables={variables}>
      {props => AnnouncementWrapper({ ...props, card, collapsed, isFetching, reload })}
    </Query>
  )
}

export const Announcements = WrapWithFetch(AnnouncementFetch)

export default withAuthProvider(Announcements)
