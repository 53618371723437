import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Table as RSTable } from 'reactstrap'
import { map } from 'ramda'
import { primaryWithOpacity, msrDarkGray } from '../../assets/colors'
import { Montserrat } from '../../assets/fonts'
import { getColsLabel, transformCredit } from './co-helper'
import { WrappedAnchor, ExpireMessage, isExpired, formatDate } from './shared'

const Table = styled(RSTable)`
  border: 0px!important;
  border-top:0px;
`

const Cell = styled.td`
  color: ${primaryWithOpacity};
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 2;
  font-weight: 500;
  border: 0px!important;
  &:hover {
    color: ${msrDarkGray};
  }
`

const RowFrame = styled.tr`
  &:hover {
    td {
      color: ${msrDarkGray};
    }
  }
`

const Caption = styled.th`
  font-family: ${Montserrat};
  border: 0px!important;
  border-top: 0px;
`

const ColHeader = ({ label }) => <Caption>{label.toUpperCase()}</Caption>

const TableHeader = ({ labels }) => (
  <thead>
    <tr>{
      map(label => <ColHeader key={label} label={label} />, labels)
    }
    </tr>
  </thead>
)

export default ({ opportunities }) => (
  <Table borderless responsive hover>
    <TableHeader labels={getColsLabel(opportunities[0])} />
    <tbody>{
      map(({
        credits,
        id,
        expireMessage,
        expireDate,
        link,
        name,
        format,
        duration,
        category
      }) => (
        <>
          {!isExpired(expireDate)
            ? (
              <RowFrame key={id}>
                <Cell nowrap>{credits}</Cell>
                <Cell>
                  <WrappedAnchor href={link} target='_blank'>{name}</WrappedAnchor>
                  {expireDate && (<ExpireMessage>Expires {formatDate(expireDate)}</ExpireMessage>)}
                </Cell>
                <Cell>{format}</Cell>
                <Cell>{category}</Cell>
                <Cell>{duration}</Cell>
              </RowFrame>
              )
            : null}
        </>
      ), transformCredit(opportunities))
    }
    </tbody>
  </Table>
)
