import React, { useContext, useEffect } from 'react'
import { withAuthProvider } from '../lib/auth'
import { useIntercom } from '@pga/react-intercom'
import { UserContext } from '../lib/UserProvider/'

const Intercom = ({ isLoggedIn }) => {
  const { boot, update, isBooted } = useIntercom(process.env.REACT_APP_INTERCOM_APP_ID)
  const { user } = useContext(UserContext)
  const intercomHmac = user?.intercomHmac?.web
  const externalId = user?.externalId

  useEffect(() => {
    if (!isBooted) {
      boot()
    }
    if (isBooted && isLoggedIn && externalId && intercomHmac) {
      update({ user_id: externalId, user_hash: intercomHmac, name: user?.displayName || `${user?.firstName} ${user?.lastName}` })
    }
  }, [isLoggedIn, isBooted, externalId, intercomHmac])

  return <></>
}

const IntercomWithAuth = withAuthProvider(Intercom)

IntercomWithAuth.displayName = 'withAuthProvider(Intercom)'
export default IntercomWithAuth
