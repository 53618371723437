import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import { Modal, ModalHeader, ModalBody as RSModalBody, Title, TitleBar } from '../../Modal'
import { LinkIcon } from '../../Icons'
import AnnouncementsListTable from './AnnouncementsListTable'
import { Separator } from '../../utils'

const Toggle = styled.div`
  color: ${Theme.colors.lightBlue};
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 5px;
`
const CompactToggle = styled.div`
  color: ${Theme.colors.lightBlue};
  font-weight: 400;
  cursor: pointer;
  height: 45px;
  padding: 15px;
  margin-left: 10px;
  text-transform: none;
`
const TitleBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  & span {
    margin-left: 20px;
  }
  @media(max-width: 667px){
    justify-content: center;
    & span {
      margin-left: 0;
    }
  }
`
const ModalBody = styled(RSModalBody)`
  padding: 20px;
  padding-left: 0;
  padding-right: 0;
`

export const ModalWrapper = ({ open, onToggleModal, announcements }) => (
  <Modal
    isOpen={open}
    toggle={() => onToggleModal(!open)}
    size='lg'
    centered
  >
    <ModalHeader toggle={() => onToggleModal(!open)} />
    <TitleBar>
      <TitleBox>
        <Title>Announcements</Title>
      </TitleBox>
    </TitleBar>
    <ModalBody>
      <AnnouncementsListTable items={announcements} />
    </ModalBody>
  </Modal>
)

const AnnouncementsModal = ({ announcements, compact }) => {
  const [open, onToggleModal] = useState(false)
  return (
    <>
      {compact
        ? <><CompactToggle onClick={() => onToggleModal(!open)}>View Announcements</CompactToggle><Separator vertical /></>
        : <Toggle onClick={() => onToggleModal(!open)}>View more announcements <LinkIcon /></Toggle>}
      <ModalWrapper {...{ open, onToggleModal, announcements }} />
    </>
  )
}

export default AnnouncementsModal
