import React from 'react'
import styled from 'styled-components'
import { compose } from 'ramda'
import { graphql } from '@apollo/client/react/hoc'
import Header from '../Header'
import PgaFooter from '../Footer'
import IsLoading from './IsLoading'
import SaveSuccess from '../utils/SaveSuccess'
import { GET_LOADING } from '../queries/loading'

const PageContainner = styled.section`
  flex-grow: 1;
`

const getIsLoading = graphql(GET_LOADING, {
  props: ({ data }) => {
    return ({
      data,
      loading: data.loading
    })
  }
})

export const Layout = ({ children, loading }) => (
  <div>
    <SaveSuccess />
    <Header />
    <IsLoading isLoading={loading} />
    <PageContainner>
      {children}
    </PageContainner>
    <PgaFooter />
  </div>
)

export default compose(
  getIsLoading
)(Layout)
