import { useContext, useEffect } from 'react'
import { withAuthProvider } from '../auth'
import { UserContext } from '../UserProvider/'
import { gtmEvent } from './'

const Analytics = ({ isLoggedIn }) => {
  const { user } = useContext(UserContext)
  const externalId = user?.externalId

  useEffect(() => {
    if (isLoggedIn && externalId) {
      gtmEvent({
        event: 'identify',
        user: {
          externalId
        }
      })
    }
  }, [isLoggedIn, externalId])

  return null
}

export default withAuthProvider(Analytics)
