import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'

export const SectionWrapper = styled.div`
  padding: 1rem 1.30rem 0 0;
  margin: ${props => props.margin || 0};
  @media(min-width: 1000px) {
    min-height: 265px;
  }
  
  @media(min-width: 992px) {
    border-right: 1px solid ${Theme.colors.bgGrey};
  }
  @media(max-width: 993px) {
    padding-bottom: 20px;
    border-bottom: 1px solid ${Theme.colors.bgGrey};
  }
`

export const SectionWrapperBg = styled.div`
  padding: 0.75rem;
  margin: 1rem 0 0 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.bg};
  @media(min-width: 992px) {
    border-right: 1px solid ${Theme.colors.bgGrey};
  }
`

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const Title = styled.div`
  color: ${Theme.colors.primary};
  font-weight: 600;
  font-size: 0.95rem;
  text-align: ${props => props.center && 'center'};
`

export const SubTitle = styled.div`
  font-family: ${Theme.fonts.Monterrat};
  font-weight: ${props => props.weight || 600};
  font-size: ${props => props.size || '11px'};
  text-transform: uppercase;
  margin-top: 0.5rem;
`

export const Status = styled.span`
  font-family: ${Theme.fonts.Monterrat};
  color: ${props => props.color || Theme.colors.darkGrey};
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0.5rem;
`

export const Description = styled.div`
  color: ${Theme.colors.primary};
  white-space: break-spaces;
  font-size: 13px;
`

export const StatusLink = styled.a`
  font-size: 13px;
  font-family: ${Theme.fonts.Montserrat};
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`

export const LevelTrackBox = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  height: 100%;
  position: relative;
  p {
    margin-bottom: 4px;
  }
  @media(max-width: 991.98px) {
    flex-direction: column;
  }
`

export const StatusWrapper = styled.div`
  padding: .5rem 1.3rem 1rem 1rem;
  margin: .5rem 0;
  margin-top: 20px;
  background-color: ${Theme.colors.lightBgGrey};
  @media(min-width: 1200px) {
    bottom: 0px;
    position: absolute;
    width: 78%;
  }
`

export const IntercomText = styled.div`
  color: ${Theme.colors.primary};
  font-weight: 500;
  font-size: 14px;
  @media(max-width: 770px) {
    text-align: center;
  }
`

export const IntercomLink = styled.span`
  color: ${Theme.colors.lightBlue};
  font-weight: 600;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const Span = styled.span`
  white-space: nowrap;
`
