import React from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import { CardText } from 'reactstrap'

import { ResourceFrame } from '.'
import { pgaComTld, pgaTld } from '../../utils'

const Header = styled.h4`
  font-size: 14px;
  font-weight: 700;
  font-family: ${Theme.fonts.PlayfairDisplay};
  color: ${Theme.colors.black};
  margin-bottom: 16px;
`

const Title = styled(CardText)`
  font-size: 12px;
  font-weight: 700;
  font-family: ${Theme.fonts.Montserrat};
  color: ${Theme.colors.primary};
  margin-bottom: 12px;
`

const GreyTitle = styled(Title)`
  color: ${Theme.colors.blueGrey};
  font-weight: 600;
  margin-bottom: 8px;
`

const Text = styled(CardText)`
  font-size: 12px;
  font-weight: 500;
  font-family: ${Theme.fonts.Montserrat};
  margin-bottom: 20px;
  color: ${Theme.colors.primary};
`

const Link = styled.a`
  font-size: 12px;
  font-weight: 500;
  font-family: ${Theme.fonts.Montserrat};
  color: ${Theme.colors.lightBlue};
`

const LinksWrapper = styled.div`
  @media (min-width: 992px) {
    border-left: 1px solid ${Theme.colors.darkgrey};
  }
  > * {
    margin-top: 16px;
  }
`

const Separator = styled.div`
  margin-top: 24px;
  border-top: 1px solid ${Theme.colors.darkgrey};
  width: 95%;
`

export default () => (
  <ResourceFrame color='white'>
    <div className='p-4'>
      <div className='d-flex'>
        <Header>Tools for Retirement</Header>
      </div>
      <div className='row'>
        <div className='col-lg-8'>
          <div className='row'>
            <div className='col-sm-6 col-xs-12 d-flex flex-column'>
              <Title>Deferred Compensation Plan</Title>
              <Text>
                Earn points and potential contributions simply by growing the
                game.
              </Text>

              <div className='d-flex flex-column h-100 justify-content-end'>
                <Link
                  href={`https://${pgaComTld}/member/deferred-comp/`}
                  target='_blank' rel='noreferrer'
                >
                  Enroll
                </Link>
                <div className='mt-1' />
                <Link
                  href={`https://resources.${pgaTld}/member-benefits/deferred-compensation/`}
                  target='_blank' rel='noreferrer'
                >
                  Learn More
                </Link>
              </div>
            </div>
            <div className='col-sm-6 col-xs-12 d-flex flex-column mt-3 mt-sm-0'>
              <Title>Golf Retirement Plus</Title>
              <Text>An excellent way to save for retirement.</Text>
              <div className='d-flex flex-column h-100 justify-content-end'>
                <Link
                  href='https://www.corebridgefinancial.com/rs/pga'
                  target='_blank' rel='noreferrer'
                >
                  Enroll
                </Link>
                <div className='mt-1' />
                <Link
                  href={`https://resources.${pgaTld}/member-benefits/golf-retirement-plus/`}
                  target='_blank' rel='noreferrer'
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>

          <Separator />

          <Header className='mt-4'>Here When You Need Us</Header>
          <div className='row'>
            <div className='col-sm-6 col-xs-12'>
              <div className='mt-3x mt-xs-0x'>
                <Text className='mb-1'>Membership Services</Text>
                <Link href='tel:8004742776'>1-800-474-2776</Link>
                <div>
                  <Link href='mailto:membership@pgahq.com'>membership@pgahq.com</Link>
                </div>
              </div>
            </div>

            <div className='col-sm-6 col-xs-12'>
              <div className='mt-3 mt-sm-0'>
                <Text className='mb-1'>Education Mentor Line</Text>
                <Link href='tel:8668663382'>1-866-866-3382, Option 6</Link>
              </div>
            </div>
          </div>
        </div>

        <LinksWrapper className='col-lg-4 row'>
          <Separator className='ml-3 d-lg-none' />
          <div className='col-lg-12 col-md-4 col-sm-6 mt-lg-0'>
            <GreyTitle>Tools for Your Business</GreyTitle>
            <div className='d-flex flex-column'>
              <Link href='https://pga.coach/' target='_blank' rel='noreferrer'>
                PGA Coach
              </Link>
              <Link
                href={`https://resources.${pgaTld}/lifelong-learning/`}
                target='_blank' rel='noreferrer'
              >
                Lifelong Learning
              </Link>
            </div>
          </div>
          <div className='col-lg-12 col-md-4 col-sm-6'>
            <GreyTitle>Tools for Everyday Living</GreyTitle>
            <div className='d-flex flex-column'>
              <Link
                href={`https://resources.${pgaTld}/member-benefits/perks-discounts/`}
                target='_blank' rel='noreferrer'
              >
                Perkspot/Partner Benefits
              </Link>
              <Link
                href={`https://resources.${pgaTld}/form-library/`}
                target='_blank' rel='noreferrer'
              >
                Forms Library
              </Link>
            </div>
          </div>

          <div className='col-lg-12 col-md-4 col-sm-6'>
            <GreyTitle>Help When You Need it Most</GreyTitle>
            <div className='d-flex flex-column'>
              <Link
                href={`https://resources.${pgaTld}/member-benefits/member-assistance/pga-disaster-relief-fund/`}
                target='_blank' rel='noreferrer'
              >
                PGA Medical and Disaster Relief
              </Link>
              <Link
                href={`https://resources.${pgaTld}/member-benefits/member-assistance/member-assistance-program/`}
                target='_blank' rel='noreferrer'
              >
                Member Assistance Program
              </Link>
            </div>
          </div>
        </LinksWrapper>
      </div>
    </div>
  </ResourceFrame>
)
