import React from 'react'
import { initIndex } from '../../../lib/algolia'
import NewsList from './NewsList'

const newsIndex = initIndex(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
  process.env.REACT_APP_ALGOLIA_INDEX_NAME
)

export default (props) => <NewsList index={newsIndex} {...props} />
