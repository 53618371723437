import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { cond, always, T, equals } from 'ramda'
import { Theme, Button } from '@pga/pga-component-library'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { OptOutCheckbox } from './OptOutCheckbox'

export const StyledModal = styled(Modal)`
  max-width: 700px;
  @media screen and (max-width: 767.98px) {
    max-width: unset;
  }
  .modal-body {
    padding: 0px;
  }
  .modal-header {
    border: 0px;
  }
`

export const Body = styled(ModalBody)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

export const TextContainer = styled.div`
  padding: 30px;
`

export const getColor = cond([
  [equals('green'), always(Theme.colors.successGreen)],
  [equals('blue'), always(Theme.colors.lightBlue)],
  [T, always(Theme.colors.primary)]
])

export const Headline = styled.h4`
  font-family: ${Theme.fonts.Montserrat};
  color: ${props => getColor(props.color)};
  font-size: ${props => props.sub ? '22' : '24'}px;
  font-weight: bold;
`

export const Link = styled.p`
  font-family: ${Theme.colors.Montserrat};
  font-size: 14px;
  color: ${Theme.colors.lightBlue};
  text-decoration: underline;
  cursor: pointer;
`

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const FullBorder = styled.span`
  border-bottom: 1px solid ${Theme.colors.pearlGrey};
`

export const Btn = styled(Button)`
  margin: 20px 0 15px 0;
  width: 300px;
`

export const DecisionModalComp = ({
  id,
  modalConfig: {
    cookieName,
    titleText,
    subHeadlineText,
    textColor,
    TextComp,
    buttonText,
    buttonHandler,
    ButtonComp
  },
  isOpen,
  toggle,
  cookies,
  history
}) => (
  <StyledModal isOpen={isOpen} toggle={toggle} size='lg'>
    <ModalHeader toggle={() => toggle(!isOpen)} />
    <Body>
      <TextContainer>
        <Headline>{titleText}</Headline>
        <Headline color={textColor} sub>{subHeadlineText}</Headline>
        <TextComp />
      </TextContainer>
      <FullBorder />
      <TextContainer>
        <Flex>
          {ButtonComp ? <ButtonComp wide /> : <Btn onClick={() => buttonHandler(history)}>{buttonText}</Btn>}
          <Link onClick={() => toggle(!isOpen)}>maybe later</Link>
          <OptOutCheckbox {...{ id, cookies, cookieName }} />
        </Flex>
      </TextContainer>
    </Body>
  </StyledModal>
)

export default withRouter(DecisionModalComp)
