import React from 'react'
import Loadable from 'react-loadable'
import {
  LoadingComponent,
  withPageMetadata,
  metadata
} from '@pga/pga-component-library'
import Layout from '../components/Layout'
import Dashboard from '../components/Dashboard'

export const loading = LoadingComponent

const AsyncLoadable = loader =>
  Loadable({
    loader,
    loading,
    delay: 300
  })

export const withLayout = Component => props => (
  <Layout>
    <Component {...props} />
  </Layout>
)

export const defaultRoutes = [
  {
    path: '/',
    component: withLayout(withPageMetadata(metadata('Dashboard'))(Dashboard)),
    exact: true,
    secured: true
  },
  {
    path: '/profile/edit',
    component: withLayout(
      AsyncLoadable(() => import('../components/EditProfile'))
    ),
    secured: true
  },
  {
    path: '/profile/pga-record',
    component: withLayout(
      AsyncLoadable(() => import('../components/PgaRecord'))
    ),
    secured: true
  },
  {
    path: '/job-preferences',
    component: withLayout(
      AsyncLoadable(() => import('../components/JobPreferences'))
    ),
    secured: true
  },
  {
    path: '/compensation-profile',
    component: withLayout(
      AsyncLoadable(() => import('../components/CompensationProfile'))
    ),
    secured: true
  },
  {
    path: '/background-check',
    component: withLayout(
      AsyncLoadable(() => import('../components/BackgroundCheck'))
    ),
    secured: true
  },
  {
    path: '/login',
    component: AsyncLoadable(() => import('../components/Login'))
  },
  {
    path: '/logout',
    component: AsyncLoadable(() => import('../components/Logout'))
  },
  {
    path: '/coming-soon',
    component: withLayout(
      AsyncLoadable(() => import('../components/ComingSoon'))
    ),
    secured: true
  },
  {
    path: '/signup',
    component: AsyncLoadable(() =>
      import('../components/SignUp/CreateAccount')
    ),
    exact: true
  },
  {
    path: '/signup/verify',
    component: AsyncLoadable(() =>
      import('../components/SignUp/EmailCodeVerification')
    ),
    exact: true
  },
  {
    path: '/email-verification',
    component: withLayout(
      AsyncLoadable(() =>
        import('../components/SignUp/LandingPage')
      )
    ),
    exact: true,
    secured: true
  },
  {
    path: '/mail-redirect',
    component: withLayout(
      AsyncLoadable(() =>
        import('../components/MailRedirect')
      )
    ),
    exact: true,
    secured: true
  },
  {
    path: '/applications/affiliate',
    component: withLayout(
      AsyncLoadable(() =>
        import('../components/Applications/Affiliate')
      )
    ),
    exact: true,
    secured: true
  },
  {
    path: '/applications/affiliate/edit',
    component: withLayout(
      AsyncLoadable(() =>
        import('../components/Applications/Affiliate/EditApplication')
      )
    ),
    exact: true,
    secured: true
  },
  {
    path: '/applications/associate',
    component: withLayout(
      AsyncLoadable(() => import('../components/Applications/Associate'))
    ),
    exact: true,
    secured: true
  },
  {
    path: '/applications/associate/edit',
    component: withLayout(
      AsyncLoadable(() => import('../components/Applications/Associate/EditApplication'))
    ),
    exact: true,
    secured: true
  },
  {
    path: '/applications/membership',
    component: withLayout(
      AsyncLoadable(() => import('../components/Applications/Member'))
    ),
    exact: true,
    secured: true
  },
  {
    path: '/applications/membership/edit',
    component: withLayout(
      AsyncLoadable(() => import('../components/Applications/Member/EditApplication'))
    ),
    exact: true,
    secured: true
  },
  {
    path: '/applications/success',
    component: withLayout(
      AsyncLoadable(() =>
        import('../components/Applications/shared/Confirmation')
      )
    ),
    exact: true
  },
  {
    path: '/applications/error',
    component: withLayout(
      AsyncLoadable(() =>
        import('../components/Applications/shared/Error')
      )
    ),
    exact: true
  },
  {
    path: '/get-started',
    component: AsyncLoadable(() =>
      import('../pages/ProspectRegistration')
    ),
    exact: true,
    secured: true
  },
  {
    path: '/change-email',
    component: AsyncLoadable(() =>
      import('../pages/ChangeEmail')
    ),
    exact: true,
    secured: true
  },
  {
    path: '/assume-user',
    component: AsyncLoadable(() =>
      import('../components/AssumeUser')
    ),
    exact: true,
    secured: true
  }
]

export const educationRoutes = [
  {
    path: '/education/programs',
    component: withLayout(
      AsyncLoadable(() => import('../components/Education'))
    ),
    exact: true
  },
  {
    path: '/education/programs/cpp',
    component: withLayout(
      AsyncLoadable(() => import('../components/Education/CPPDetail'))
    ),
    exact: true
  },
  {
    path: '/education/programs/mpp',
    component: withLayout(
      AsyncLoadable(() => import('../components/Education/MPPDetail'))
    ),
    exact: true
  },
  {
    path: '/education/programs/spp',
    component: withLayout(
      AsyncLoadable(() => import('../components/Education/SPPDetail'))
    ),
    exact: true
  },
  {
    path: '/education/programs/cpp/progress',
    component: withLayout(
      AsyncLoadable(() => import('../components/Education/Progress/Cpp3/CppProgress'))
    ),
    checkEducationStatus: [
      { programCode: 'CPP3' }
    ]
  },
  {
    path: '/education/programs/mpp/progress',
    component: withLayout(
      AsyncLoadable(() => import('../components/Education/Progress/Mpp3/MppProgress'))
    ),
    checkEducationStatus: [
      { programCode: 'MPP3' }
    ]
  },
  {
    path: '/education/programs/cpp/manage',
    component: withLayout(
      AsyncLoadable(() => import('../components/Education/ManageHours'))
    ),
    exact: true,
    checkEducationStatus: [
      { programCode: 'CPP3' }
    ]
  },
  {
    path: '/education/programs/cpp/manage/all-hours',
    component: withLayout(
      AsyncLoadable(() =>
        import('../components/Education/ManageHours/AllHours/')
      )
    ),
    exact: true,
    checkEducationStatus: [
      { programCode: 'CPP3' }
    ]
  },
  {
    path: '/education/programs/cpp/submit-hours',
    component: withLayout(
      AsyncLoadable(() => import('../components/Education/SubmitHours'))
    ),
    exact: true,
    checkEducationStatus: [
      { programCode: 'CPP3' }
    ]
  },
  {
    path: '/education/programs/cpp/apply',
    component: withLayout(
      AsyncLoadable(() => import('../components/Education/Enrollment/CppEnrollment'))
    ),
    exact: true,
    checkEducationStatus: [
      { programCode: 'CPP3' }
    ]
  },
  {
    path: '/education/programs/mpp/apply',
    component: withLayout(
      AsyncLoadable(() => import('../components/Education/Enrollment/MppEnrollment'))
    ),
    exact: true,
    checkEducationStatus: [
      { programCode: 'MPP3' }
    ]
  },
  {
    path: '/education/programs/cpp/thank-you',
    component: withLayout(
      AsyncLoadable(() =>
        import('../components/Education/EnrollmentConfirmation/CppEnrollmentConfirmation')
      )
    ),
    exact: true
  },
  {
    path: '/education/programs/mpp/thank-you',
    component: withLayout(
      AsyncLoadable(() =>
        import('../components/Education/EnrollmentConfirmation/MppEnrollmentConfirmation')
      )
    ),
    exact: true
  },
  {
    path: '/education/submission/thank-you',
    component: withLayout(
      AsyncLoadable(() =>
        import('../components/Education/ThankYou')
      )
    ),
    exact: true
  }
]
