import apolloClient from '../../apolloClient'
import { gql } from '@apollo/client'

export const UpdateViewingPermissions = (input = null) => {
  return apolloClient.mutate({
    mutation: UPDATE_MEMBER_PROFILE,
    variables: input,
    optimisticResponse: {
      updateMemberProfile: {
        ...input.input,
        __typename: 'MemberViewingPermissions'
      }
    }
  })
}

export const parseSocial = social => social.reduce((acc, { link, name }) => ({
  ...acc,
  [name]: link
}), {})

export const makeInput = social => {
  return { ...parseSocial(social), __typename: 'MemberSocialMedia' }
}

export const makeListItemArr = (listItemKey, typename) => listItemKey.map(({ description, effectiveYear }) => ({
  description,
  effectiveYear,
  __typename: typename
}))

export const makeExpertise = expertise => expertise.map(({ id, name }) => ({ id, name, __typename: 'MemberExpertise' }))

export const makeViewing = input => ({ ...input.viewingPermissions, __typename: 'MemberViewingPermissions' })

export const makeResponse = ({ id, input }) => ({
  id,
  ...input,
  ...(input.personalAffiliations && { personalAffiliations: makeListItemArr(input.personalAffiliations, 'PersonalAffiliations') }),
  ...(input.personalCertifications && { personalCertifications: makeListItemArr(input.personalCertifications, 'PersonalCertifications') }),
  ...(input.personalAwards && { personalAwards: makeListItemArr(input.personalAwards, 'PersonalAwards') }),
  ...(input.social && { social: makeInput(input.social) }),
  ...(input.viewingPermissions && { viewingPermissions: makeViewing(input) }),
  ...(input.expertise && { expertise: makeExpertise(input.expertise) })
})

export const replaceEmptyStrWithNull = (values, fields) => (
  fields.reduce((acc, field) => {
    if (values[field] === '') {
      return {
        ...values,
        ...acc,
        [field]: null
      }
    } else {
      return {
        ...values,
        ...acc
      }
    }
  }, {})
)

export const UpdateMemberProfileMutation = (input = null) => {
  const fields = ['publicPhone', 'publicMobile']
  const processedInput = {
    ...input,
    input: replaceEmptyStrWithNull(input.input, fields)
  }
  return apolloClient.mutate({
    mutation: UPDATE_MEMBER_PROFILE,
    variables: processedInput,
    optimisticResponse: {
      updateMemberProfile: {
        ...makeResponse(input),
        __typename: 'Member'
      }
    }
  })
}

export const UpdateViewingPermissionsMutation = (input = null) => {
  return apolloClient.mutate({
    mutation: UPDATE_MEMBER_VIEWING_PERMISSIONS,
    variables: input
  })
}

const UPDATE_MEMBER_VIEWING_PERMISSIONS = gql`
  mutation UpdateMemberProfileVisibility ($id: ID!, $uid: ID!, $input: UpdateMemberProfileInput!) {
    updateMemberProfile(id: $id, uid: $uid, input: $input) {
      viewingPermissions {
        viewAddressMember
      }
    }
  }`

const UPDATE_MEMBER_PROFILE = gql`
  mutation UpdateMemberProfileInput ($id: ID!, $uid: ID!, $input: UpdateMemberProfileInput!) {
    updateMemberProfile(id: $id, uid: $uid, input: $input) {
      id
      displayName
      informalName
      photo
      publicEmail
      publicMobile
      publicPhone
      overview
      address {
        address1
        address2
        address3
        address4
        city
        country
        state
        zip
      }
      social {
        facebook
        linkedin
        twitter
        youtube
        instagram
        website
      },
      personalAffiliations {
        effectiveYear
        description
      },
      personalAwards {
        effectiveYear
        description
      }
      personalCertifications {
        effectiveYear
        description
      }
      expertise {
        id
        name
      }
      viewingPermissions {
        viewCertificationsMember
        viewCertificationsPublic
        viewPhonePublic
        viewPhoneMember
        viewAddressPublic
        viewAddressMember
        viewExpertisePublic
        viewExpertiseMember
        viewEmailPublic
        viewEmailMember
        viewMobilePublic
        viewMobileMember
        viewAffiliationsPublic
        viewAffiliationsMember
        viewAwardsPublic
        viewAwardsMember
      }
    }
  }
`

export const SET_EDITING = gql`
  mutation setEditingComponent($component: String) {
    setEditingComponent(component: $component) @client
  }
`

export const setEditingComponent = (component = '') => apolloClient.mutate({
  mutation: SET_EDITING,
  variables: { component }
})

export const GET_EDITING_COMPS = gql`
  query getEditingComps {
    isEditing @client {
      component
    }
  }
`

export const removeEditing = (component = null) => apolloClient.mutate({
  mutation: REMOVE_EDITING,
  variables: { component }
})

const REMOVE_EDITING = gql`
  mutation RemoveEditing($component: String!) {
    removeEditing(component: $component) @client
  }
`

export const SET_DIRTY = gql`
mutation setDirtyComponent($component: String) {
  setDirtyComponent(component: $component) @client
}
`

export const setDirtyComponent = (component = '') => apolloClient.mutate({
  mutation: SET_DIRTY,
  variables: { component }
})

export const GET_DIRTY_COMPS = gql`
  query getDirtyComps {
    isDirty @client {
      component
    }
  }
`

export const removeDirty = (component = null) => apolloClient.mutate({
  mutation: REMOVE_DIRTY,
  variables: { component }
})

const REMOVE_DIRTY = gql`
  mutation RemoveDirty($component: String!) {
    removeDirtyComponent(component: $component) @client
  }
`
