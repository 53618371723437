import React from 'react'
import styled from 'styled-components'
import { curry, find, anyPass, path, pathOr, compose, isNil, isEmpty, includes, allPass, map, prop, any } from 'ramda'
import { graphql } from '@apollo/client/react/hoc'
import { lightgrey } from '../../assets/colors'

export const pgaTld = window.location.origin.includes('.sandboxpga.org') || window.location.origin.includes('.pga.local') ? 'sandboxpga.org' : 'pga.org'
export const appserverTld = window.location.origin.includes('.sandboxpga.org') || window.location.origin.includes('.pga.local') ? 'apps.dev.pga.org' : 'apps.pga.org'
export const pgaComTld = window.location.origin.includes('.sandboxpga.org') ? 'my.dev.pga.com' : 'my.pga.com'

export const Separator = styled.div`
  ${props => (props.vertical && 'border-right') || (props.horizontal && 'border-bottom')}: 2px solid ${lightgrey};
`

export const findObject = curry((arr, label) => find(val => val.label === label)(arr))

export const getMeData = (query) => compose(
  graphql(query, {
    options: {
      fetchPolicy: 'no-cache'
    },
    props: ({ data }) => ({
      data,
      loading: data.loading,
      me: data.me,
      error: data.error,
      refetch: data.refetch
    })
  })
)
const MEMBER_TYPE = 'MB'
const PUBLIC_EMPLOYER = 'PE'
const STUDENT_TYPE = 'ST'
const ASSOCIATE_TYPE = 'AP'
const TERMINATED_APPRENTICE = 'TA'
const QUALIFIER_ELIGIBLE = 'QE'
const PRE_QUALIFIER = 'PQ'
const B25_CLASS = 'B25'
const AFFILIATE_CLASS = 'AF'
const SECTION_STAFF = 'SECTION_STAFF'

export const isMember = user => user.type === MEMBER_TYPE
export const isPublicEmployer = user => user.type === PUBLIC_EMPLOYER
export const isStudent = user => user.type === STUDENT_TYPE
export const isAffiliate = user => user.class === AFFILIATE_CLASS
export const isAssociate = user => user.type === ASSOCIATE_TYPE
export const isTerminatedApprentice = user => user.type === TERMINATED_APPRENTICE
export const isPreQualifier = user => user.type === PRE_QUALIFIER
export const isQualifierEligible = user => user.type === QUALIFIER_ELIGIBLE
export const isTerminated = user => path(['termination', 'status'])(user) === 'Terminated'
export const isSuspended = user => path(['termination', 'status'])(user) === 'Suspended'
export const isGptpSuspended = user => user.gptpSuspension
export const hasReciprocityAttribute = (me) => prop('reciprocityEligible', me) === true
export const isStaff = ({ primaryEmail }) => (primaryEmail || '').endsWith('@pgahq.com')
export const isSectionStaff = user => user.role === SECTION_STAFF
export const isB25Class = user => user.class === B25_CLASS
export const hasStaffAccess = anyPass([isStaff, isSectionStaff])
export const isTerminatedStaff = allPass([hasStaffAccess, isTerminated])
export const isProspectPath = currentMember => !!currentMember?.prospect?.startedAt
export const shouldSeeProspectDashboard = (me, currentMember) => hasProspectDashboard(me) || (isProspectPath(currentMember) && !hasFinishedQualifying(me))

export const hasMembershipType = anyPass([
  isMember,
  isStudent,
  isAssociate,
  isQualifierEligible
])

export const shouldSeeAnnouncements = anyPass([
  isMember,
  isStudent,
  isAssociate,
  isAffiliate
])

export const hasFinishedQualifying = anyPass([
  isMember,
  isStudent,
  isAssociate,
  isAffiliate
])

export const hasProspectDashboard = anyPass([
  isPreQualifier,
  isQualifierEligible
])

export const shouldSeeJobRecommendations = anyPass([
  hasMembershipType,
  isPreQualifier
])

export const hasLevelCd = levelCd => compose(
  any(includes(levelCd)),
  map(prop('levelCd')),
  pathOr([], ['education', 'levels'])
)

export const shouldNotSeeGeneric = anyPass([
  isMember,
  isStudent,
  isAssociate,
  isPreQualifier,
  allPass([isQualifierEligible, hasLevelCd('Z')]),
  allPass([isQualifierEligible, hasLevelCd('A')]),
  hasReciprocityAttribute
])

export const isNilOrEmpty = (el) => isNil(el) || isEmpty(el)

export const arrayNotEmpty = (arr) => Array.isArray(arr) && arr.length > 0

// HoC that will allow you to wrap a component in some configuration
// Allows you to set the prop name to something other than 'config', so you can compose this multiple times
export const withConfig = (config, propName = 'config') => (WrappedComponent) => (props) => (
  <WrappedComponent {...props} {...{ [propName]: config }} />
)
