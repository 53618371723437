import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import { Button } from '../../../Button'
import { Link } from 'react-router-dom'
import { BsCheck, BsQuestionCircle } from 'react-icons/bs'
import { Modal } from 'reactstrap'

export const Separator = styled.div`
  ${props => (props.vertical && 'border-right') || (props.horizontal && 'border-bottom')} : 2px solid ${Theme.colors.lightgrey};
`

export const ChartsBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px auto;
  & > div:not(${Separator}) {
    flex-basis: 45%;
  }
`

export const MSRBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 25px 10px 25px;
  min-width: 290px;
  width: 100%;
  min-height: 342px;
  border-right: 2px solid ${Theme.colors.darkgrey};

  @media(max-width: 1199px){
    border-right: 0;
  }

  @media(max-width: 767px){
    border-right: 0;
    padding-top: 35px;
  }
`

export const ButtonsBox = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -5px;

  & .msr-btn, ${Button} {
    width: 45%;
  }

  @media(max-width: 991.99px){
    justify-content: center;
    flex-direction: column;
    flex-flow: column-reverse;
    & .msr-btn, ${Button} {
      width: 100%;
    }
  }
`

export const MSRTrackingBox = styled.div`
`

export const CountMessage = styled.div`
  margin-top: -20px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: right;
`

export const MSRLabel = styled.div`
  color: ${Theme.colors.primaryWithOpacity};
  font-family: ${Theme.colors.Montserrat};
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-transform: uppercase;
  margin-top: 8px;
`

export const Wrapper = styled.div`
  margin: 10px auto;
  & > div:not(${Separator}) {
    flex-basis: 45%;
  }
`

export const Completed = styled.span`
  font-size: 24px;
  font-weight: 500;
  line-height: 29.26px;
  font-family: ${Theme.fonts.Montserrat};
  text-align: center;
`

export const TotalDeficitWrapper = styled.div`
  margin-top: 5px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`

export const TotalDeficitCounter = styled.div`
  padding: 3px 6px;
  border: solid 1px ${props => props.color};
  color: ${props => props.color};
  line-height: 14px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  margin-right: 5px;
`

export const PDRText = styled.span`
  color: ${Theme.colors.black};
`

export const MSRCountBox = styled.div`
  color: ${props => props.color};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  display: inline-block;
`

export const QuestionIcon = styled(BsQuestionCircle)`
  color: ${Theme.colors.black};
  cursor: pointer;
  font-size: 16px;
  margin-left: 8px;
`

export const TotalWrapper = styled.div`
  color: ${Theme.colors.primary};
  font-family: ${Theme.fonts.PlayfairDisplay};
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  text-shadow: 0 2px 5px 0 rgba(255,255,255,0.64);
  
  @media(max-width: 767px){
    font-size: 17px;
    line-height: 22px;
  }
`

export const StyledLink = styled(Link)`
  white-space: nowrap;
  margin-right: 5px;
`

export const TitleBox = styled.div`
  font-family: ${Theme.colors.Montserrat};
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 5px;
`

export const LoadingMSRBox = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Condition = styled.div`
  color: ${Theme.colors.blueGrey};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 13px;
  margin-top: 20px;
`

export const Total = styled.span`
  font-family: ${Theme.fonts.PlayfairDisplay};
  font-size: 32px;
  font-weight: bold;
  line-height: 1.09;
  letter-spacing: -0.3px;
  margin-right: 10px;
`

export const ContentFrame = styled.div`
  display: flex;
  flex-direction: column;
`

export const BarContainer = styled.div`
  display: flex;
  align-items: center;
`

export const MSRTrackingBar = styled.div`
  background: ${props => `linear-gradient(to right, ${props.color} 0, ${props.color} ${props.completed}%, ${Theme.colors.darkgrey} ${props.completed}%, ${Theme.colors.darkgrey} 100%)`};
  height: 11px;
  min-width: 92%;
  border-radius: 5px;
`

export const CompletedText = styled.span`
  color: ${props => props.color};
  font-family: ${Theme.fonts.Montserrat};
  margin-left: 10px;
  font-weight: 500;
  font-size: 16px;
`

export const CheckIcon = styled(BsCheck)`
  color: ${props => props.color};
  margin-left: 5px;
`

export const LinkFrame = styled.a`
  color: ${Theme.colors.lightBlue};
  font-family: ${Theme.fonts.Montserrat};
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  line-height: 1.3;
  padding-bottom: 8px;
`

export const ModalTitle = styled.h5`
  color: ${Theme.colors.black};
  font-family: ${Theme.fonts.PlayfairDisplay};
  margin-top: 5px;
`

export const ModalContent = styled.p`
  color: ${Theme.colors.black};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 14px;
`

export const ModalContainer = styled(Modal)`
  .modal-content {
    border-radius: 5px;
    border: none;
  }
`
