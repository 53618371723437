import React, { Fragment } from 'react'
import { cond, always, T } from 'ramda'

import { getInfoFor, getInfoForPGM3, getInfoForPGM31, getStatusFor, levels } from '../../../utils/curriculum-helper'
import { getCurrentLevel, getMostRecentCompletedLevel } from './utils'
import { isStudent, isAssociate, hasReciprocityAttribute } from '../../../utils'

import { TitleLarge } from './styled'
import HeaderDisplay from './Header'
import MessageDisplay from './Message'
import ReciprocityInfoBox from './ReciprocityInfoBox'

const getInfoBoxOptions = (me) => {
  const currentLevel = getCurrentLevel(me)
  const activeLevel = currentLevel || getMostRecentCompletedLevel(me)
  const currentLevelOrder = currentLevel ? currentLevel.slice(-1) : null
  const nextLevel = activeLevel ? Number(activeLevel.slice(-1)) + 1 : null
  const pgmProgram = getStatusFor(me.type, activeLevel)

  return {
    currentLevel,
    currentLevelOrder,
    activeLevel,
    nextLevel,
    pgmProgram
  }
}

export const AssociateInfoBox = ({ me }) => {
  const { activeLevel, nextLevel, pgmProgram } = getInfoBoxOptions(me)

  const { title, purchaseLink } = pgmProgram === '2'
    ? getInfoFor(activeLevel, levels, me)
    : pgmProgram === '31'
      ? getInfoForPGM31(activeLevel, me)
      : getInfoForPGM3(activeLevel, me)

  return (
    <>
      <HeaderDisplay {...{ nextLevel, me, purchaseLink }} />
      <MessageDisplay {...{ me, title, nextLevel, purchaseLink }} />
    </>
  )
}

export const StudentInfoBox = ({ me }) => {
  const { currentLevelOrder } = getInfoBoxOptions(me)

  return <TitleLarge>Curriculum Level {currentLevelOrder}</TitleLarge>
}

export default me => cond([
  [isStudent, always(<StudentInfoBox {...{ me }} />)],
  [isAssociate, always(<AssociateInfoBox {...{ me }} />)],
  [hasReciprocityAttribute, always(<ReciprocityInfoBox me={me} />)],
  [T, always(<AssociateInfoBox {...{ me }} />)]
])(me)
