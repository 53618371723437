import React from 'react'
import { ListGroup as RSListGroup, ListGroupItem as RSListGroupItem } from 'reactstrap'
import styled from 'styled-components'
import { times } from 'ramda'
import { buildAnnouncement } from './AnnouncementsList'
import { primary, lightgrey } from '../../../assets/colors'
import { formatDate } from '../../utils/formatter'
import { Montserrat } from '../../../assets/fonts'
import LoadingPlaceholder from '../../LoadingPlaceholder'

export const Date = styled.div`
  color: ${primary};
  font-family: ${Montserrat};
  font-weight: 500;
  line-height: 2;
  padding: 5px 15px;
`
export const ListTableContainer = styled.div`
  overflow: auto;
  height: 500px;
  @media (max-width: 1199px) {
    height: 300px;
  }
`

export const ListGroupItem = styled(RSListGroupItem)`
  border: none;
  display: flex;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;

  :hover {
    background-color: ${lightgrey};
    cursor: pointer;
  }
`

export const ListGroup = styled(RSListGroup)`
  border: none;
  width: 100%;
`

const ContentBox = styled.div`
  width: 100%;
`

export const LoadingListTable = () => (
  <ListGroup>
    {
      times(() => (
        <ListGroupItem>
          <Date>
            <LoadingPlaceholder h={10} w={100} />
          </Date>
          <ContentBox>
            <LoadingPlaceholder h={70} />
          </ContentBox>
        </ListGroupItem>
      ), 5)
    }
  </ListGroup>
)

export default ({ items }) => (
  <ListTableContainer>
    <ListGroup>
      {
        items.map((item) => (
          <ListGroupItem key={item.title}>
            <Date>
              {formatDate(item.date)}
            </Date>
            <div>
              {buildAnnouncement(item)}
            </div>
          </ListGroupItem>
        ))
      }
    </ListGroup>
  </ListTableContainer>
)
