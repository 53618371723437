import React from 'react'
import moment from 'moment'
import { pathOr } from 'ramda'
import {
  Title,
  Description,
  Detail,
  DetailsBox,
  Block,
  Footer
} from './styled'
import { JOB_DETAILS_URL } from './'

const detailsConfig = [
  {
    getValue: pathOr('', ['facilities', 0, 'facility_name'])
  },
  {
    getValue: (job) => `${getJobCity(job)}, ${getJobState(job)}`
  }
]

const getJobCity = pathOr(null, ['facilities', 0, 'city'])
const getJobState = pathOr(null, ['facilities', 0, 'state'])

export const Details = ({ job }) => (
  <DetailsBox>
    {
      detailsConfig.map(({ getValue, Icon }, idx) => <Detail key={idx}>{getValue(job)}</Detail>)
    }
  </DetailsBox>
)

const JobBlock = ({ job }) => (
  <Block id={job.jobId}>
    <Description>
      <Title size='sm'>{job.jobTitle}</Title>
      <Details job={job} />
      <Footer>
        <span>{moment.unix(`${job.created}`).fromNow()}</span>
        <a href={`${JOB_DETAILS_URL}${job.jobId}`}>Details <i className='fa fa-angle-right' /></a>
      </Footer>
    </Description>
  </Block>
)

export default JobBlock
