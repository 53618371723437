import React from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import { Button } from '../../../Button'

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`

export const LeftHalf = styled.div``

export const RightHalf = styled.div`
  padding-left: 25px;
`

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${({ align }) => align || 'flex-start'};

  ${LeftHalf}, ${RightHalf} {
    flex: 0 0 50%;
  }

  @media screen and (max-width: 1199.98px) {
    ${LeftHalf} {
      flex: 0 0 58%;
    }
    ${RightHalf} {
      padding-left: 10px;
      flex: 0 0 42%;
    }
    ${Button} {
      min-width: auto;
      width: 100%;
      padding: 12px 0; 
    }
  }

  @media screen and (max-width: 991.98px) {
    ${LeftHalf} {
      flex: 0 0 55%;
    }
    ${RightHalf} {
      flex: 0 0 45%;
    }
  }

  @media screen and (max-width: 767.98px) {
    ${LeftHalf}, ${RightHalf} {
      flex: 0 0 50%;
    }
    ${RightHalf} {
      padding-left: 15px;
    }
    ${Button} {
      min-width: 120px;
      width: auto;
      padding: 15px 20px; 
    }
  }
`

export const ActionLink = styled.p`
  color: ${Theme.colors.lightBlue};
  font-weight: 500;
  margin-bottom: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const LinkIcon = () => <i className='fa fa-angle-right pl-1' />
