import React, { Component, Fragment } from 'react'
import MediaQuery from 'react-responsive'
import { Panel } from '@pga/pga-component-library'
import { withAuthProvider } from '../../../lib/auth'

import CollapsedBriefing from './CollapsedBriefing'
import ExpandedBriefing from './ExpandedBriefing'
import { isMember } from '../../utils'

export const MembersBriefing = ({ open, me, toggle }) => (
  <Panel noPadding>
    {
      open
        ? <ExpandedBriefing titleAction={toggle} collapsible={isMember(me)} />
        : (
          <>
            <MediaQuery minWidth={768}>
              <CollapsedBriefing titleAction={toggle} />
            </MediaQuery>
            <MediaQuery maxWidth={767.98}>
              <ExpandedBriefing titleAction={toggle} collapsible={isMember(me)} />
            </MediaQuery>
          </>
          )
    }
  </Panel>
)

export class Briefing extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: true
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle () {
    this.setState({
      open: !this.state.open
    })
  }

  render () {
    const { open } = this.state
    const { me, authReady } = this.props
    return (
      authReady
        ? <MembersBriefing toggle={this.toggle} me={me} open={open} />
        : null
    )
  }
}

export default withAuthProvider(Briefing)
