import moment from 'moment'
import { checkStatus } from './getStatus'
import { hasPositiveOutcome } from './calculateRequirements'

export const formatDate = (date) => {
  if (date) {
    return moment.utc(date).format('MM/DD/YYYY')
  }

  return null
}

export const showExpiresOrNull = (status, date) => {
  if ((hasPositiveOutcome(status) || checkStatus('IN_PROGRESS')(status)) && date) {
    return `EXPIRES ${formatDate(date)}`
  }

  return null
}

export const showExpiredOrDate = (status, expirationDate, date) => {
  if (checkStatus('EXPIRED')(status)) {
    return formatDate(expirationDate)
  }
  return formatDate(date)
}
