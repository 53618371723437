import React, { Fragment } from 'react'
import { Query } from '@apollo/client/react/components'
import styled from 'styled-components'
import { path, pathOr } from 'ramda'
import ResourceCard, { LoadingResources } from './ResourceCard'
import { white, darkgrey } from '../../../assets/colors'
import resourcesFor from './hardCodedResources'
import { getMeEducation } from '../../queries/member'

export const ResourceFrame = styled.div`
  height: 400px;
  display: flex;
  width: 100%;
  flex-basis: ${(props) => (props.flexBasis ? `${props.flexBasis}%` : '66%')};
  background-color: ${(props) => props.color};
  box-shadow: 0 5px 10px 0 rgba(0, 35, 75, 0.15);
  margin-bottom: 20px;

  @media (max-width: 1199px) {
    flex-basis: 100%;
    min-height: 320px;
    height: auto;
  }
  @media (max-width: 768px) {
    height: 100%;
  }
`

export const FlexCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0;
  width: 100%;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    & div.resource-card + div {
      border-left: 2px solid ${darkgrey};
    }
  }
`

const ErrorFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`

export const ResourcesWidget = ({ loading, error, me, committeeCodes }) => {
  if (loading) {
    return (
      <FlexCard>
        <LoadingResources />
      </FlexCard>
    )
  }
  if (error) {
    return (
      <ErrorFrame>
        <p className='text-center'>We're sorry, something went wrong.</p>
      </ErrorFrame>
    )
  }
  return (
    <FlexCard>
      {resourcesFor(path(['type'], me), committeeCodes).map((resource) => {
        return (
          <Fragment key={resource.id}>
            <ResourceCard {...resource} user={me} />
          </Fragment>
        )
      })}
    </FlexCard>
  )
}

export const Resources = ({ flexBasis }) => {
  return (
    <Query query={getMeEducation}>
      {({ loading, error, data }) => (
        <ResourceFrame color={white} flexBasis={flexBasis}>
          <ResourcesWidget
            loading={loading}
            error={error}
            me={pathOr({}, ['me'], data)}
            committeeCodes={pathOr([], ['me', 'committeeCodes'], data)}
          />
        </ResourceFrame>
      )}
    </Query>
  )
}

export default Resources
