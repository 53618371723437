import React, { useState, useEffect, useRef, createElement, Fragment } from 'react'
import algoliasearch from 'algoliasearch'
import { render } from 'react-dom'
import { InstantSearch, usePagination, useSearchBox } from 'react-instantsearch'
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'
import '@algolia/autocomplete-theme-classic'
import './algolia-autocomplete-override.css'

export const ALGOLIA_INDEX = 'MemberFacilityDirectory'

export const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY)

export const Autocomplete = ({ className, setValue, field, ...autocompleteProps }) => {
  const autoCompleteContainer = useRef(null)
  const { query, refine: setQuery } = useSearchBox()
  const { refine: setPage } = usePagination()

  const [instantSearchUiState, setInstantSearchUiState] = useState({ query })

  useEffect(() => {
    setQuery(instantSearchUiState.query)
    setPage(0)
  }, [instantSearchUiState])

  useEffect(() => {
    if (!autoCompleteContainer.current) {
      return
    }

    const autocompleteInstance = autocomplete({
      ...autocompleteProps,
      container: autoCompleteContainer.current,
      initialState: { query },
      onReset () {
        setInstantSearchUiState({ query: '' })
      },
      onSubmit ({ state }) {
        setInstantSearchUiState({ query: state.query })
      },
      onStateChange ({ prevState, state }) {
        if (prevState.query !== state.query) {
          setInstantSearchUiState({ query: state.query })
          setValue('facilityName', state.query)
        }
      },
      renderer: { createElement, Fragment, render }
    })
    return () => autocompleteInstance.destroy()
  }, [])

  return (
    <div {...field} style={{ fontFamily: 'Montserrat', fontSize: '12px', color: 'rgba(0, 35, 75, 0.5)' }} ref={autoCompleteContainer} />
  )
}

export function FacilityListing ({ hit, components }) {
  return (
    <div className='aa-ItemContent'>
      <div className='aa-ItemTitle' name={hit.name} facilityId={hit.facility_id} style={{ fontFamily: 'Montserrat', fontSize: '12px' }}>
        <components.Highlight hit={hit} attribute='name' />
      </div>
    </div>
  )
}

export const getFacilityItems = async (query) => {
  return getAlgoliaResults({
    searchClient: algoliaClient,
    queries: [
      {
        indexName: ALGOLIA_INDEX,
        query,
        filters: 'object_type:facility'
      }
    ]
  })
}

const handleSelect = (item, setQuery, setValue) => {
  setValue('facilityName', item.name)
  setValue('facilityId', item.facility_id)
  setQuery(item.name)
}

const FacilityAlgoliaSearch = ({ setValue, field }) => {
  return (
    <>
      <InstantSearch searchClient={algoliaClient} indexName={ALGOLIA_INDEX}>
        <Autocomplete
          field={field}
          searchClient={algoliaClient}
          placeholder='Search for your facility'
          detachedMediaQuery='none'
          openOnFocus
          setValue={setValue}
          className='aa-Autocomplete'
          getSources={({ query }) => [
            {
              sourceId: 'facilities',
              getItems () {
                return getFacilityItems(query)
              },
              onSelect ({ item, setQuery }) {
                handleSelect(item, setQuery, setValue)
              },
              templates: {
                item ({ item, components }) {
                  return <FacilityListing hit={item} components={components} setValue={setValue} />
                }
              }
            }
          ]}
        />
      </InstantSearch>
    </>
  )
}

export default FacilityAlgoliaSearch
