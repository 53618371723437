import styled from 'styled-components'
import { Panel, Theme, BriefcaseGolfIcon, PreferencesIcon, SearchJobIcon, Button } from '@pga/pga-component-library'

export const Col = styled.div`
  cursor: pointer;
  flex-basis: 32%;
  margin: 0px 10px;
  min-height: 105px;
  &:first-child {
    margin-left: 0px;
  }
  &:last-child {
    margin-right: 0px;
  }
  @media screen and (max-width: 1200px) {
    flex-basis: 100%;
    margin: 0;
  }
`

export const Btn = styled(Button)`
  padding: 0 10px;
  height: 40px;
  width: 200px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 990px) {
    width: 100%;
  }
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 10px;
`

export const Description = styled.div`
  font-size: 14px;
  justify-content: space-between;
  font-family: ${Theme.fonts.Montserrat};
  margin-top: 8px;
`

export const Title = styled.div`
  font-size: ${props => props.fs || '16px'};
  font-weight: ${props => props.fw || '500'}};
  margin-top: 8px;
  color: ${Theme.colors.primary};
  font-family: ${Theme.fonts.Montserrat};
`

export const H1 = styled.p`
  margin: 0;
  font-family: ${Theme.fonts.PlayfairDisplay};
  font-size: 20px;
  font-weight: 500;
  color: ${Theme.colors.primary};
`

export const Sub = styled.p`
  margin: 0;
  font-family: ${Theme.fonts.Montserrat};
  font-size: ${props => props.fs || '12px'};
`

export const IdLabel = styled.p`
  margin: 0;
  font-family: ${Theme.fonts.Montserrat};
  font-size: 16px;
  color: ${Theme.colors.primary};
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const Section = styled(Panel)`
  min-height: 100px;
  padding: 10px 20px;
`

export const IconPreferences = styled(PreferencesIcon)`
  svg {
    height: 40px;
    width: 40px;
  } g {
    stroke: ${Theme.colors.gold};
  }
`
export const SearchIcon = styled(SearchJobIcon)`
  svg {
    height: 40px;
    width: 40px;
  }
`

export const PostJobsIcon = styled(BriefcaseGolfIcon)`
  svg {
    height: 40px;
    width: 40px;
  } g {
    stroke: ${Theme.colors.gold};
  }
`

export const Link = styled.a`
  font-size: 13px;
  margin-left: 20px;
  margin-top: 20px;
  @media screen and (max-width: 990px) {
    align-self: center;
    margin-top: 10px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
`
