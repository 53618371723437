import React from 'react'
import styled from 'styled-components'
import { BriefcaseGolfIcon, BriefcaseCheckIcon, PreferencesIcon, Button } from '@pga/pga-component-library'
import { Link, withRouter } from 'react-router-dom'

const MessagePanel = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 198px;
  flex-grow: 1;
  padding: 20px;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
    margin-right: 20px;
  }

  @media (max-width: 576px) {
    margin-bottom: 20px;
  }
`

const MessageContainer = styled.div`
  max-width: 510px;
  text-align: center;
  h5 {
    font-size: 1rem;
  }
`

const Frame = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 2px dashed #dee2e3;
  height: 100%;
  width: 100%;
  padding: 5px 10px;

  @media (max-width: 576px) {
    flex-direction: column;
    padding: 30px 20px;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0;
  width: 100%;
`

export const NO_ADDITIONAL_JOBS = 'NO_ADDITIONAL_JOBS'
export const NO_JOBS = 'NO_JOBS'
export const ERROR = 'ERROR'
export const NO_PREFERENCES = 'NO_PREFERENCES'
export const JobPreferencesLink = () => <Link to='/job-preferences'>job preferences</Link>

const messages = {
  [NO_JOBS]: {
    title: 'No Recommendations.',
    Content: () => <div>Jobs are updated frequently, please check back soon. You can also try modifying your <JobPreferencesLink /> to widen your career interests.</div>,
    Icon: BriefcaseGolfIcon,
    buttonText: 'VIEW ALL JOBS',
    handler: () => { window.location.href = 'https://jobs.pga.org' }
  },
  [NO_ADDITIONAL_JOBS]: {
    title: 'No additional recommendations.',
    Content: () => <div>Check back soon or try modifying your <JobPreferencesLink /> to widen your career interests.</div>,
    Icon: BriefcaseCheckIcon,
    buttonText: 'VIEW ALL JOBS',
    handler: () => { window.location.href = 'https://jobs.pga.org' }
  },
  [ERROR]: {
    title: 'Error',
    Content: () => <div>Something went wrong trying to load recommended jobs.</div>
  },
  [NO_PREFERENCES]: {
    title: 'Job Recommendations Turned Off',
    Content: () => <div>Set your <JobPreferencesLink /> to view jobs that match your career interests, locations, pay, and more.</div>,
    Icon: PreferencesIcon,
    buttonText: 'SET JOB PREFERENCES',
    handler: history => history.push('/job-preferences')
  }
}

const Btn = styled(Button)`
  padding: 0 10px;
  height: 40px;
`

export const Msg = ({ title, Content, Icon, size, buttonText, history, handler }) => (
  <MessageBox>
    {size > 1 && Icon ? <Icon /> : null}
    <MessageContainer>
      <h5>{title}</h5>
      <div><Content /></div>
    </MessageContainer>
    {buttonText &&
      <ButtonWrapper>
        <Btn onClick={() => handler(history)}>{buttonText}</Btn>
      </ButtonWrapper>}
  </MessageBox>
)

const Message = withRouter(Msg)

export default ({ topic, size }) => (
  <MessagePanel>
    <Frame>
      <Message {...messages[topic]} size={size} />
    </Frame>
  </MessagePanel>
)
