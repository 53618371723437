import React from 'react'
import MediaQuery from 'react-responsive'
import { Side, TitleBar, TitleAction, TitleContent } from '../Panel'
import ProfileBrief from '../ProfileBrief'
import { CollapseLabel, CollapseIcon, SideCollapse, ProfileSide } from './shared'
import MembershipSailing from './MembershipSailing'
import SecondaryContent from './SecondaryContent'
import { withAuthProvider } from '../../../lib/auth'
import { shouldSeeAnnouncements } from '../../utils'
import { withProspectDateQuery } from '../../../utils/prospect/withProspectDateQuery'

const Announcements = ({ collapsible }) => (
  <SideCollapse collapsible={collapsible}>
    <MembershipSailing />
    <MediaQuery minWidth={1200}>
      <SecondaryContent />
    </MediaQuery>
  </SideCollapse>
)

const ExpandedBriefing = ({ titleAction, collapsible, me }) => {
  return (
    <>
      <ProfileSide>
        <ProfileBrief vertical />
      </ProfileSide>
      <Side basis={77} vertical>
        <MediaQuery minWidth={768}>
          {
            collapsible
              ? (
                <TitleBar>
                  <TitleContent />
                  <TitleAction onClick={titleAction}>
                    <CollapseLabel>COLLAPSE</CollapseLabel>
                    <CollapseIcon open />
                  </TitleAction>
                </TitleBar>
                )
              : null
          }
        </MediaQuery>
        {shouldSeeAnnouncements(me) ? <Announcements collapsible={collapsible} /> : <MembershipSailing />}
      </Side>
    </>
  )
}

export default withProspectDateQuery(withAuthProvider(ExpandedBriefing))
