/* eslint-disable react/jsx-handler-names */

import React, { Fragment, useState } from 'react'
import MediaQuery from 'react-responsive'
import { Modal, ModalHeader, ModalBody, Title, TitleBar } from '../Modal'
import CreditOpportunitiesTable from './CreditOpportunitiesTable'
import COCompactList from './CreditOpportunitiesList'
import msrOpportunities from './MSROpportunityData'
import { Button } from '../Button'
import { flags, useFeatureFlags } from '../../lib/feature-flags'
import { pgaComTld } from '../utils'

const CreditOpportunitiesModal = () => {
  const [open, setOpen] = useState(false)
  const [isEarnCreditsRedirectEnabled] = useFeatureFlags([flags.FLAG_FEAT_EARN_CREDITS_REDIRECT])
  const PGA_COM_MEMBER_VIDEOS_URL = `https://${pgaComTld}/member/videos`
  const onToggleModal = () => setOpen(!open)

  return (
    <>
      <Button
        {...(isEarnCreditsRedirectEnabled
          ? { href: PGA_COM_MEMBER_VIDEOS_URL }
          : { onClick: onToggleModal })}
      >
        Earn Credits
      </Button>
      <Modal
        isOpen={open}
        toggle={onToggleModal}
        size='lg'
        centered
      >
        <ModalHeader toggle={onToggleModal} />
        <TitleBar>
          <Title>PDR Credit Opportunities</Title>
        </TitleBar>
        <ModalBody>
          <MediaQuery minWidth={992}>
            <CreditOpportunitiesTable opportunities={msrOpportunities} />
          </MediaQuery>
          <MediaQuery maxWidth={991}>
            <COCompactList opportunities={msrOpportunities} />
          </MediaQuery>
        </ModalBody>
      </Modal>
    </>
  )
}

export default CreditOpportunitiesModal
