import React from 'react'
import styled from 'styled-components'
import { IntercomAPI } from '@pga/react-intercom'
import { Theme } from '@pga/pga-component-library'

export const Link = styled.span`
  color: ${Theme.colors.lightBlue};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export default () => {
  return (
    <p>Please try reloading the page, if the problem persists please <Link onClick={() => IntercomAPI('show')}>click here</Link> to submit a support request.</p>
  )
}
