import React, { Fragment } from 'react'
import { times } from 'ramda'
import LoadingPlaceholder from '../../LoadingPlaceholder'
import { CardBox, Title, Padding } from './shared'
import { ANNOUNCEMENT_COUNT } from './AnnouncementContainer'

export const LoadingAnnouncement = () => (
  <div>
    <br />
    <LoadingPlaceholder h={10} w={50} />
    <br />
    <LoadingPlaceholder h={10} />
    <LoadingPlaceholder h={10} />
    <LoadingPlaceholder h={10} />
    <LoadingPlaceholder h={10} w={50} />
  </div>
)

export default ({ card }) => {
  const Wrapper = card ? CardBox : Fragment

  return (
    <Wrapper>
      <Padding>
        <Title text='ANNOUNCEMENTS' />
        {
        times(time => <LoadingAnnouncement key={time} />, ANNOUNCEMENT_COUNT)
      }
      </Padding>
    </Wrapper>
  )
}
