import React from 'react'
import LoadingPlaceholder from '../../LoadingPlaceholder'
import { FullName, CompactProfileContainer } from './shared'
import { TypeBadge } from '../shared'

export const LoadingCompactProfile = () => (
  <CompactProfileContainer>
    <LoadingPlaceholder h={20} w={210} />
  </CompactProfileContainer>
)

export default ({
  user: {
    firstName,
    lastName,
    type,
    id
  }
}) => (
  <CompactProfileContainer>
    <FullName small>{`${firstName} ${lastName}`}</FullName>
    {id && <TypeBadge type={type} />}
  </CompactProfileContainer>
)
