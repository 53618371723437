import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Theme, Button, Panel } from '@pga/pga-component-library'
import { UpdateViewingPermissionsMutation } from '../queries/editProfile'
import { setIsSaved } from '../queries/loading'

export const Btn = styled.a.attrs({
  href: props => props.to,
  target: 'blank'
})`
  text-align: center;
  padding: 10px;
  font-family: ${Theme.fonts.Montserrat};
  color: ${Theme.colors.lightBlue};
  font-size: 12px;
  height: 42px;
  min-width: ${props => props.large ? '200' : '150'}px;
  border: 1px solid ${Theme.colors.lightBlue};
  ${props => props.noBottomBorder && 'border-bottom: 0px;'}
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: white;
    background-color: ${Theme.colors.grey};
    border: 1px solid ${Theme.colors.grey};
  }
  @media screen and (max-width: 575.98px) {
    width: 90%;
    align-self: center;
    margin: ${props => props.noMargin ? '0' : '10'}px;
  }
`

export const PanelStyled = styled(Panel)`
  @media screen and (max-width: 575.98px) {
    padding: ${props => props.mobilePadded ? '10px' : 0};
  }
`

export const TextLink = styled.a.attrs(props => ({
  href: props.to
}))`
  color: ${Theme.colors.lightBlue};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 12px;
  align-items: center;
  align-self: center;
  @media screen and (max-width: 575.98px) {
    padding: 15px 0;
    text-decoration: none;
  }
`

const CallWrapper = styled.div`
  p {
    margin-bottom: 0.5rem;
  }
  @media screen and (max-width: 575.98px) {
    border-top: 1px solid ${Theme.colors.pearlGrey};
    padding-top: 10px;
    padding-left: 15px;
  }
`

export const CallMemberServices = () => (
  <CallWrapper>
    <TextLink to='tel:+18004742776'>
      TO CHANGE, PLEASE
      <br />
      CALL MEMBER SERVICES
    </TextLink>
  </CallWrapper>
)

export const ButtonWrapper = styled.div`
  align-content: center;
  @media screen and (max-width: 575.98px) {
    width: 100%;
    margin-bottom: 10px;
  }
`

export const AngleDown = styled.i.attrs({
  className: 'fa fa-angle-down fa-lg'
})`
  padding: 5px;
`

const fromTop = keyframes`
  0% {
    top: -42px;
  }
  100% {
    top: 0px;
  }
`

export const SliderAnimationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%
  width: 100%;
  position: relative;
  animation-name: ${fromTop};
  animation-duration: .3s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
`

export const Flex = styled.div`
  display: flex;
  justify-content: ${props => props.flexStart ? 'flex-start' : 'space-between'};
  width: 100%;
  flex-direction: ${props => props.col ? 'column' : 'row'};
  align-items: ${props => props.bottom ? 'flex-end' : 'flex-start'};
  padding: 10px 0;
  @media screen and (max-width: 575.98px) {
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: 10px 0;
  }
`

export const Wrapper = styled.div`
  flex-grow: ${props => props.grow ? '1' : '0'};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${props => props.minHeight && 'min-height: 200px;'}
  ${props => props.minHeightMd && 'min-height: 125px;'}
  height: 100%;
  padding: 15px 0 5px 15px;
  @media screen and (max-width: 575.98px) {
    padding: ${props => props.noPadding ? '0' : '15px 0 5px 15px'};
    width: 100%;
  }
`

export const Title = styled.p`
  color: ${Theme.colors.blueGrey};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 12px;
  margin: 0;
  min-width: 200px;
`

export const Text = styled.p`
  color: ${Theme.colors.primary};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 14px;
  margin: ${props => props.margin ? '10px 0 20px 0' : '0'};
`

export const ButtonWrapped = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
`
const SmallButton = styled(Button)`
  min-height: 42px;
  min-width: 75px;
  margin: 0 5px;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid;
  font-family: ${Theme.fonts.Montserrat};
`

export const SaveCancelButtons = ({ togglePerms, submitForm }) => (
  <ButtonWrapped>
    <SmallButton onClick={togglePerms} type='button' outline>CANCEL</SmallButton>
    <SmallButton onClick={submitForm}>SAVE</SmallButton>
  </ButtonWrapped>
)

export const handleSubmit = (values, setSubmitting, togglePerms, toggleErrorModal, { id, uid }) => {
  UpdateViewingPermissionsMutation({ id, uid, input: { viewingPermissions: { viewAddressMember: values.viewAddressMember } } })
    .catch(e => e && toggleErrorModal())
  setIsSaved(true)
  togglePerms()
  setSubmitting(false)
  setTimeout(() => {
    setIsSaved(false)
  }, 3000)
}
