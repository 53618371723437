import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from, gql, split } from '@apollo/client'
import { getToken, getImpersonationHeader } from '../lib/auth'
import { resolvers, defaults } from './Resolvers'

export const COACH_TOOLS_API = 'coachTools'
const cache = new InMemoryCache()

const DEFAULT_QUERY = gql`
  {
    isDirty @client {
      component,
      __typename
    },
    isLoading @client {
      component,
      __typename
    },
    isEditing @client {
      component,
      __typename
    },
    savedSuccess {
      isSaved,
      __typename
    }
  }
`
cache.writeQuery({
  query: DEFAULT_QUERY,
  data: defaults
})

const typeDefs = `
  type AuthCustomFields
  type AuthProfile {
    customFields: AuthCustomFields
  }
`

export const splitLink = split(
  (operation) => {
    const { api } = operation.getContext()
    return api === COACH_TOOLS_API
  },
  new HttpLink({ uri: `${process.env.REACT_APP_COACH_TOOLS_GRAPHQL_ENDPOINT}/graphql` }),
  new HttpLink({ uri: `${process.env.REACT_APP_GRAPHQL_ENDPOINT}/graphql` })
)

export const addAuthToken = (token) => token
  ? { Authorization: `Bearer ${token}` }
  : {}

export const authContext = tokenLoader => async (operation, { headers }) => {
  let token
  try {
    token = await tokenLoader()
  } catch (e) {
    throw new Error(e)
  }

  return {
    headers: {
      ...headers,
      ...addAuthToken(token),
      ...getImpersonationHeader()
    }
  }
}

const authLink = new ApolloLink(async (operation, forward) => {
  const headers = operation.getContext().headers || {}
  const authHeader = await authContext(getToken)(operation, { headers })
  operation.setContext({ headers: authHeader.headers })
  return forward(operation)
})

const apolloClient = new ApolloClient({
  link: from([authLink, splitLink]),
  cache,
  typeDefs,
  resolvers,
  connectToDevTools: process.env.NODE_ENV !== 'production'
})

export default apolloClient
