import { pathOr, curry, path, isNil, prop, head, sort, filter, defaultTo, last, pipe, init, propOr } from 'ramda'
import { getStatusFor } from '../../../utils/curriculum-helper'
import moment from 'moment'

const urls = {
  portal: {
    2: 'https://pgaofamerica.instructure.com',
    3: 'https://pgaofamerica.instructure.com',
    31: 'https://pgaofamerica.instructure.com'
  },
  transcript: {
    2: 'http://apps.pga.org/professionals/apps/education/knowledgecenter/index.cfm?page=transcriptListing&',
    3: null,
    31: null
  }
}

export const getMostRecentLevelActivity = pipe(
  pathOr([], ['education', 'levels']),
  sort((levelOne, levelTwo) => moment(levelTwo.startDate).diff(moment(levelOne.startDate))),
  head
)

export const getMostRecentLevelCode = pipe(
  getMostRecentLevelActivity,
  propOr(null, 'levelCd')
)

export const getCurrentLevel = path(['education', 'currentLevel'])
export const hasNullCurrentLevel = (me) => !(getCurrentLevel(me)) // if current level is null, then current level has been completed
export const hasLinkAndLevel = curry((link, level) => () => !!(link && level <= 3))
export const getMostRecentCompletedLevel = path(['education', 'mostRecentCompletedLevel'])
export const urlFor = (resource, me) => (
  me.type
    ? urls[resource][getStatusFor(me.type, getMostRecentLevelCode(me))]
    : null
)

// A regex for matching reciprocity level codes (Z[EOT]?[0123])
export const reciprocityLevelCodeRegex = /Z.*\d/i
// If a level is not complete (therefor in progress), it's completeCd will be null
export const levelIsActive = ({ completeCd }) => isNil(completeCd)
// Returns true if a level object has a reciprocity (Z) levelCd
export const levelIsReciprocity = ({ levelCd = '' }) => reciprocityLevelCodeRegex.test(levelCd)
// Sort levels by the number of the levelCd (ZT3 > ZO2 > Z1 > Z0), highest first
export const sortLevelsByNumberDesc = (levelA, levelB) => (
  last(prop('levelCd', levelB)) - last(prop('levelCd', levelA))
)

// As level-codes typically end in a number (ZT3), extract the last digit
// and cast it to a number primitive, else null
export const parseNumberFromLevelCode = pipe(
  (levelCode) => (typeof levelCode === 'string') ? levelCode.slice(-1) : '',
  (oneChar) => isNaN(parseInt(oneChar)) ? null : parseInt(oneChar)
)

// Given a level that is already determined to be a Z-level, extract
// useful metadata about it, such as the next level in the sequence
export const parseReciprocityLevel = (level) => {
  if (isNil(level)) return null

  const { levelCd } = level
  const currentLevelNumber = parseNumberFromLevelCode(levelCd)
  const nextLevelNumber = (isNil(currentLevelNumber) || currentLevelNumber > 2) ? null : currentLevelNumber + 1
  const nextLevelCd = isNil(nextLevelNumber) ? null : `${init(levelCd)}${nextLevelNumber}`

  return {
    levelCd,
    isActive: levelIsActive(level),
    currentLevelNumber,
    nextLevelNumber,
    nextLevelCd
  }
}

// Searches some levels[] to find the most advanced reciprocity level
// levels[] should have levelCd and completeCd
export const getCurrentReciprocityLevel = pipe(
  defaultTo([]),
  filter(levelIsReciprocity),
  sort(sortLevelsByNumberDesc),
  head,
  parseReciprocityLevel
)

// If the member has completed ANY of student || associate || reciprocity
// programs, then the dashboard must show a completed state, regardless
// of past program activities. Otherwise, use latest levelCode
export const getCurrentProgressLevelCode = (me) => {
  const recentLevelCd = getMostRecentCompletedLevel(me)

  return (parseNumberFromLevelCode(recentLevelCd) === 3)
    ? recentLevelCd
    : getMostRecentLevelCode(me)
}
