import styled from 'styled-components'

export const PanelContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${props => (props.vertical && 'column') || 'row'};
  position: relative;
  word-wrap: break-word;
  flex-wrap: wrap;
  background-color: #fff;
  min-width: 375px;
`

export const Side = styled.div`
  position: relative;
  display: flex;
  ${props => (props.basis && `flex-basis: ${props.basis}%`)};
  flex-direction: ${props => (props.vertical && 'column') || 'row'};
  word-wrap: break-word;
  flex-wrap: wrap;
  justify-content: center;

  @media(max-width: 1199px) {
    flex: 1;
  }
`

export const TitleBar = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  justify-content: space-between;
`

export const TitleAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

export default styled.div`
  display: flex;
  ${props => (props.basis && `flex-basis: ${props.basis}%`)};
  flex-direction: ${props => (props.vertical && 'column') || 'row'};
  position: relative;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  width: 100%;
  height: ${props => (props.h && `${props.h}px`) || 'auto'};
  box-shadow: 0 5px 10px 0 rgba(0, 35, 75, 0.15);
  flex-wrap: wrap;
  margin-bottom: 20px;
`
