import { gql } from '@apollo/client'

export const CREATE_CONSENT_RECEIPT = gql`
  mutation ($input: CreateConsentReceiptInput!) {
    createConsentReceipt(input: $input) {
      receipt
      status
    }
  }
`
