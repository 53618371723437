import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'

export const Completed = styled.span`
  text-align: center;
`

export const MSRCountBoxCompact = styled.div`
  color: ${props => props.color};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
  height: 20px;
  width: 120px;
  text-align: center;
  align-items: center;
  justify-content: center;
`
