import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { ProfileCard, DataLoading } from '@pga/pga-component-library'

import { FullName, Section, Facility, MemberId, Certified, getCertifiedText } from './shared'
import { hasMembershipType, isStudent } from '../../utils'
import { badgeOption } from '../shared'
import profileImg from '../../../assets/images/profileImg.svg'

export const LoadingProfile = ({ vertical }) => (
  <ProfileCard vertical={vertical} fluid>
    <DataLoading h={30} />
    <br />
    <DataLoading />
    <DataLoading h={10} w={150} />
    <DataLoading h={10} w={170} />
    <br />
    <DataLoading h={10} w={150} />
  </ProfileCard>
)

export const ProfileComponent = ({ history, user, vertical }) => {
  const memberDesignationTxt = getCertifiedText(user.masterProfessional, user.certified, user.specialized)
  const badge = badgeOption(user)

  return (
    <ProfileCard
      vertical={vertical}
      {...(hasMembershipType(user) ? { onPhotoClick: () => history.push('/profile/edit') } : {})}
      fluid
      square={user.photo}
      src={user.photo || profileImg}
      badge={badge}
    >
      <div>
        <FullName>{user.displayName || `${user.firstName} ${user.lastName}`}</FullName>
      </div>
      <br />
      {memberDesignationTxt && <Certified text={memberDesignationTxt} />}
      {
        !isStudent(user)
          ? (
            <div>
              {user.section && <Section section={user.section} />}
            </div>
            )
          : null
      }
      <div>
        {user.primaryFacility && <Facility facility={user.primaryFacility} />}
      </div>
      <div>
        {hasMembershipType(user) && <MemberId id={user.id} type={user.type} />}
      </div>
      <br />
      {hasMembershipType(user) && <Link to='profile/edit'>Edit My Profile <i className='fa fa-angle-right' /></Link>}
    </ProfileCard>
  )
}

export default withRouter(ProfileComponent)
