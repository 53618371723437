import * as Yup from 'yup'

import { useForm } from 'react-hook-form'
import React, { useState } from 'react'

import { gql, useMutation } from '@apollo/client'

import { COACH_TOOLS_API } from '../../apolloClient'
import { yupResolver } from '@hookform/resolvers/yup'
import DemographicQuestions from './DemographicQuestionStep'
import QualifyingQuestions from './QualifyingQuestions'
import { countryOptions, howTheyHeardOptions } from './conditions'
import moment from 'moment'

export const PROSPECT_REGISTRATION_MUTATION = gql`
  mutation createProspect ($input: MembershipCreateProspectInput!) {
    createProspect(input: $input) {
      message
      success
    }
  }
`

const defaultValues = {
  mobileNumber: '',
  dob: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  gender: '',
  militaryStatus: '',
  workInGolf: null,
  facilityId: '',
  facilityName: '',
  jobTitle: '',
  reasonForBecomingMember: '',
  howTheyHeard: ''
}

export const validationSchema = Yup.object({
  mobileNumber: Yup.string().required('This field is required'),
  dob: Yup.string().required('This field is required'),
  address1: Yup.string().required('This field is required'),
  city: Yup.string().required('This field is required'),
  state: Yup.string()
    .required('This field is required')
    .matches(/^[a-zA-Z]+$/, 'Alphabetic characters only')
    .length(2, 'This field must be two characters'),
  zip: Yup.string().required('This field is required'),
  country: Yup.string()
    .required('This field is required')
    .oneOf(countryOptions.map((x) => x.value), 'Please select a country'),
  reasonForBecomingMember: Yup.string().required('This field is required'),
  workInGolf: Yup.bool().required('This field is required').nullable(),
  facilityId: Yup.string().when('workInGolf', {
    is: true,
    then: Yup.string().required('This field is required'),
    otherwise: Yup.string().notRequired()
  }),
  facilityName: Yup.string().when('workInGolf', {
    is: true,
    then: Yup.string().required('This field is required'),
    otherwise: Yup.string().notRequired()
  }),
  jobTitle: Yup.string().when('workInGolf', {
    is: true,
    then: Yup.string().required('This field is required'),
    otherwise: Yup.string().notRequired()
  }),
  howTheyHeard: Yup.string()
    .required('This field is required')
    .oneOf(howTheyHeardOptions.map((x) => x.value))
})

const isModal = (history) => history.location.pathname !== '/get-started'

const ProspectForm = ({ history }) => {
  const [step, setStep] = useState(1)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [createProspect] = useMutation(PROSPECT_REGISTRATION_MUTATION, {
    context: {
      api: COACH_TOOLS_API
    }
  })

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid, errors, dirtyFields }
  } = useForm({ defaultValues, resolver: yupResolver(validationSchema), mode: 'onBlur' })

  const handleSetSecondPage = (event) => {
    event.preventDefault()
    setStep(2)
    history.replace({ search: '?step=2' })
  }

  const handleProspectCreation = async (data) => {
    try {
      setIsSubmitting(true)
      delete data.workInGolf
      data.dob = moment(data.dob).format('YYYY-MM-DD')
      await createProspect({
        variables: {
          input: data
        }
      })
      if (isModal(history)) {
        history.go(0)
      }
      history.replace('/')
    } catch (error) {
      setHasError(true)
      window.rg4js('send', {
        error,
        tags: ['prospect-registration']
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <form name='prospect-registration' data-testid='prospect-form' onSubmit={handleSubmit(handleProspectCreation)}>
      {step === 1 ? <DemographicQuestions {...{ control, errors, setValue, isValid, handleSetSecondPage, history, dirtyFields, watch }} /> : <QualifyingQuestions {...{ control, hasError, handleSubmit, isValid, isSubmitting, setStep, setValue, watch, errors }} />}
    </form>
  )
}

export default ProspectForm
