export default [
  {
    label: 'Education',
    links: [
      {
        title: 'PGA Knowledge Center Admin',
        url: 'http://apps.pga.org/admin/education/knowledgeCenter/index.cfm',
        external: true,
        target: '_blank'
      }
    ]
  },
  {
    label: 'Resources',
    links: [
      {
        title: 'Executive Director Resource Admin',
        url: 'http://apps.pga.org/admin/sectioned/index.cfm',
        external: true,
        target: '_blank'
      },
      {
        title: 'Executive Director Resource',
        url: 'http://apps.pga.org/professionals/apps/sectioned/index.cfm',
        external: true,
        target: '_blank'
      }
    ]
  },
  {
    label: 'Tools',
    links: [
      {
        title: 'PGA Staff Intranet',
        url: 'https://hub.pgahq.com/',
        external: true,
        target: '_blank'
      },
      {
        title: 'PGA Staff Directory',
        url: 'http://apps.pga.org/professionals/apps/staff_directory.cfm',
        external: true,
        target: '_blank'
      },
      {
        title: 'ADP Portal',
        url: 'http://workforcenow.adp.com/',
        external: true,
        target: '_blank'
      },
      {
        title: 'PGA Support System',
        url: 'http://pgasupport.pgalinks.com/',
        external: true,
        target: '_blank'
      },
      {
        title: 'Creative Services Request',
        url: 'https://pgacreativeservices.zendesk.com',
        external: true,
        target: '_blank'
      },
      {
        title: 'Research Request',
        url: 'https://pga.qualtrics.com/SE/?SID=SV_bPInBTNYvaGnaV7',
        external: true,
        target: '_blank'
      },
      {
        title: 'Video Services Request',
        url: 'https://pgacreativeservices.zendesk.com/',
        external: true,
        target: '_blank'
      },
      {
        title: 'Section Roster',
        url: 'http://apps.pga.org/professionals/apps/memberinfo/section_roster/section_map.cfm',
        external: true,
        target: '_blank'
      },
      {
        title: 'PGA Golf Management Admin Tool',
        url: 'http://apps.pga.org/helpwanted/pgmpostings/university/index.cfm',
        external: true,
        target: '_blank'
      },
      {
        title: 'Board of Control Admin',
        url: 'http://apps.pga.org/admin/boc/index.cfm',
        external: true,
        target: '_blank'
      },
      {
        title: 'Board of Control Documents',
        url: 'https://sites.google.com/pgahq.com/board-of-control',
        external: true,
        target: '_blank'
      },
      {
        title: 'Committee Site Admin',
        url: 'http://apps.pga.org/admin/committees/index.cfm',
        external: true,
        target: '_blank'
      },
      {
        title: 'Committee Site',
        url: 'https://sites.google.com/pgahq.com/membership-committee/home',
        external: true,
        target: '_blank'
      },
      {
        title: 'Fairway Tales Podcast',
        url: 'https://beta.pga.org/podcast/',
        external: true,
        target: '_blank'
      }
    ]
  }
]
