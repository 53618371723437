import React from 'react'
import styled from 'styled-components'
import { compose, map, filter, allPass, times } from 'ramda'
import { Theme, Badge } from '@pga/pga-component-library'
import eduIcon from '../../../assets/icons/icon-edu.png'
import membershipIcon from '../../../assets/icons/icon-membership.png'
import toolsIcon from '../../../assets/icons/icon-tool.png'
import CareerConsultantContact from '../CareerConsultantContact'
import MentorContact from '../MentorContact'
import { visibilityFilter, currentProgram } from './helpers'
import { LinkFrame } from '../shared'
import LoadingPlaceholder from '../../LoadingPlaceholder'

const icons = {
  1: eduIcon,
  2: toolsIcon,
  3: eduIcon,
  4: membershipIcon,
  5: toolsIcon,
  6: eduIcon,
  7: toolsIcon,
  8: membershipIcon,
  9: eduIcon
}

const CircleFrame = styled.img`
  width: 38px;
  height: 38px;
`

const TitleFrame = styled.h4`
  padding-top: 6px;
  padding-bottom: 6px;
  color: ${Theme.colors.primary};
  font-weight: bold;
  font-family: ${Theme.fonts.PlayfairDisplay};
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: -0.2px;
`

const ResourceCardBox = styled.div.attrs({
  className: 'resource-card'
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 0 20px;
  @media(max-width: 767px){
    width: 100%;
    flex: none;
  }
`
export const ResourceCardContent = styled.div`
  margin: 0 auto;

  @media(max-width: 767px){
    width: 100%;
    padding: 15px 30px;
    flex: none;
  }
`
const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledBadge = styled(Badge)`
  padding: 4px 6px;
  margin-left: 0px;
  margin-right: 4px;
  font-size: 8px;
`

export const specialResources = {
  CareerConsultantContact: (props) => <CareerConsultantContact {...props} />,
  MentorContact: (props) => <MentorContact {...props} />
}

export const LoadingResources = () => times((idx) => (
  <ResourceCardBox key={idx}>
    <ResourceCardContent>
      <span><CircleFrame src={icons[5]} /></span>
      <TitleFrame><LoadingPlaceholder /></TitleFrame>
      {
        times(idx => {
          <div key={idx}>
            <LoadingPlaceholder w={150} />
          </div>
        }, 5)
      }
    </ResourceCardContent>
  </ResourceCardBox>
), 3)

export default ({ id, label, links, user }) => (
  <ResourceCardBox>
    <ResourceCardContent>
      <span><CircleFrame src={icons[id]} /></span>
      <TitleFrame>{label}</TitleFrame>
      {
        compose(
          map(link => (
            link.type
              ? specialResources[link.type](user)
              : (
                <LinkWrapper key={link.label}>
                  {link.badgeText && <StyledBadge color={Theme.colors.gold}>{link.badgeText}</StyledBadge>}
                  <LinkFrame href={link.url} target={link.target} rel='noopener noreferrer'>{link.label}</LinkFrame>
                </LinkWrapper>
                )
          )),
          filter(allPass([visibilityFilter, currentProgram(user)]))
        )(links)
      }
    </ResourceCardContent>
  </ResourceCardBox>
)
