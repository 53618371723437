import React from 'react'
import styled from 'styled-components'
import { DataLoading, Theme } from '@pga/pga-component-library'

import CurriculumTracker from '../CurriculumTracker'
import { ButtonFw } from '../../Button'
import { urlFor, getCurrentProgressLevelCode } from './CurriculumMessage/utils'
import CurriculumMessage from './CurriculumMessage'

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 20px 0 20px;
  width: 100%;
  height: 100%;
  border-right: 2px solid ${Theme.colors.darkgrey};

  @media(max-width: 767px) {
    padding-top: 35px;
  }
`

export const ButtonsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  
  @media(max-width: 767px){
    flex-direction: column;
  }
`

const LevelTrackBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`

export const TextWrapper = styled.div`
  padding: 0 15px;
`

export const Loading = () => (
  <Box>
    <br />
    <DataLoading h={25} />
    <br />
    <DataLoading h={60} />
    <ButtonsBox>
      <DataLoading h={40} w={150} />
      <DataLoading h={40} w={150} />
    </ButtonsBox>
  </Box>
)

export default ({ me }) => {
  const current = getCurrentProgressLevelCode(me)
  const transcriptUrl = urlFor('transcript', me)
  const portalUrl = urlFor('portal', me)

  return (
    <Box>
      <TextWrapper>
        {CurriculumMessage(me)}
      </TextWrapper>
      <LevelTrackBox>
        <CurriculumTracker current={current} me={me} />
      </LevelTrackBox>
      <ButtonsBox>
        {transcriptUrl && <a className='w-100 p-3' href={transcriptUrl} rel='noopener noreferrer'><ButtonFw outline>VIEW TRANSCRIPT</ButtonFw></a>}
        {portalUrl && <a className='w-100 p-3' href={portalUrl} target='_blank' rel='noopener noreferrer'><ButtonFw>EDUCATION PORTAL</ButtonFw></a>}
      </ButtonsBox>
    </Box>
  )
}
