import styled from 'styled-components'
import { cond, always, T, equals, prop } from 'ramda'
import { Link } from 'react-router-dom'
import { Theme } from '@pga/pga-component-library'

export const Title = styled.div`
  margin-top: ${props => props.noMargin ? '0' : '30px'};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 21px;
  font-weight: bold;
  color: ${Theme.colors.primary};
  @media screen and (max-width: 767.98px) {
    margin: ${props => props.noMargin ? '0' : '30px 0'};
  }
`

export const TitleHeader = styled(Title)`
  margin-top: 50px;
  @media (max-width: 767.98px) {
    margin-top: 50px;
    margin-bottom: 0;
  }
`

const getColor = cond([
  [equals('blueGrey'), always(Theme.colors.blueGrey)],
  [equals('successGreen'), always(Theme.colors.successGreen)],
  [equals('primaryOpacity'), always(Theme.colors.primaryOpacity)],
  [T, always(Theme.colors.primary)]
])

const getFontSize = cond([
  [prop('small'), always('14px')],
  [prop('medium'), always('16px')],
  [prop('large'), always('20px')],
  [T, always('16px')]
])

export const Text = styled.p`
  margin: 0;
  font-family: ${Theme.fonts.Montserrat};
  font-size: ${getFontSize};
  line-height: ${props => props.short ? '1.4' : '25px'};
  color: ${props => getColor(props.color)};
  ${props => props.basis && 'flex-basis: 50%;'}
  ${props => props.alignLeft && 'text-align: end;'}
  @media screen and (max-width: 767.98px) {
    font-size: 14px;
  }
`

export const SubTitle = styled.p`
  color: ${Theme.colors.primary};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 18px;
  font-weight: bold;
`

export const Wrapper = styled.div`
  padding: ${props => props.sidePadding ? '30px 15px' : '15px 0'};
  @media screen and (max-width: 767.98px) {
    padding: 15px 0;
  }
`

export const Separator = styled.div`
  border-bottom: 1px solid ${Theme.colors.greyBlue};
  margin: 60px 0;
  width: 100%;
  @media screen and (max-width: 767.98px) {
    margin: 20px 0%;
  }
`

export const RRLink = styled(Link)`
  font-family: ${Theme.fonts.Montserrat};
  text-decoration: ${props => props.underline ? 'underline' : 'none'};
`

export const TitleBar = styled.div`
  height: ${props => props.mdHeight ? '50px' : '75px'};
  background-color: ${props => props.gray ? Theme.colors.greyBlue20 : Theme.colors.lightBlue};
  color: white;
  display: flex;
  align-items: center;
  padding: 30px;
`

export const ProgressTitle = styled.h3`
  font-family: ${Theme.colors.Montserrat};
  color: ${props => props.fontPrimary ? Theme.colors.primary : 'white'};
  font-size: ${props => props.fontSmall ? '18px' : '22px'};
  font-weight: ${props => props.bold ? 'bold' : '400'};
  margin: 0;
  @media screen and (max-width: 767.98px) {
    font-size: 18px;
  }
`

export const Content = styled.div`
  margin: ${props => props.noMargin ? '0' : '30'}px;
  ${props => props.padding && 'padding: 20px 0;'}
  display: flex;
  font-family: ${Theme.fonts.Montserrat};
  color: ${Theme.colors.blueGrey};
  font-size: 14px;
  line-height: 25px;
`
