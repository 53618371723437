import { Theme } from '@pga/pga-component-library'

export const columns = [{
  Header: 'Added Date',
  accessor: 'addedDate'
}, {
  Header: 'Credits',
  accessor: 'creditsAccepted',
  maxWidth: 100
}, {
  Header: 'Description',
  accessor: 'description1',
  minWidth: 300
}]

export const CellStyle = {
  border: 'none',
  boxShadow: 'none'
}

export const TableStyle = {
  border: 'none'
}

export const NoDataStyle = {
  fontSize: '32px',
  fontFamily: `${Theme.fonts.PlayfairDisplay}`,
  textAlign: 'center',
  color: Theme.colors.primary
}
