import React from 'react'
import MediaQuery from 'react-responsive'
import { TitleBar, TitleAction, TitleContent } from '../Panel'
import { CompactProfileBrief } from '../ProfileBrief'
import MembershipSailing from './MembershipSailing'
import { CollapseLabel, CollapseIcon } from './shared'
import SecondaryContent from './SecondaryContent'

const CollapsedBriefing = ({ titleAction }) =>
  <TitleBar>
    <TitleContent>
      <CompactProfileBrief />
      <MembershipSailing collapsed />
      <MediaQuery minWidth={1200}>
        <SecondaryContent collapsed />
      </MediaQuery>
    </TitleContent>
    <TitleAction onClick={titleAction}>
      <CollapseLabel>EXPAND</CollapseLabel>
      <CollapseIcon />
    </TitleAction>
  </TitleBar>

export default CollapsedBriefing
