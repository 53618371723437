import { useMutation, useQuery } from '@apollo/client'
import { ExternalLinkIcon } from '@pga/pga-component-library'
import { useState } from 'react'
import styled from 'styled-components'
import { COACH_TOOLS_API } from '../../../../../apolloClient'
import {
  INITIATE_BACKGROUND_CHECK_MUTATION,
  VERIFY_BACKGROUND_CHECK_PAYMENT
} from '../../../../../pages/ProspectRegistration/query'
import { Btn } from '../../../../Modal'
import { Loading, Text } from './Shared'

export const LINK_TEST_ID = 'background-check-provider-link'

const ButtonWithIcon = styled(Btn)`
  padding-left: 15px;
  svg {
    margin-bottom: 5px;
  }
  g {
    stroke: white;
  }
`

export default function Step3 ({ order, onClose }) {
  const [providerUrl, setProviderUrl] = useState()
  const [hasError, setHasError] = useState(false)

  const handleContinue = () => {
    providerUrl && window.open(providerUrl) && onClose()
  }

  const [initiateBackgroundCheck, { loading }] = useMutation(
    INITIATE_BACKGROUND_CHECK_MUTATION,
    {
      context: {
        api: COACH_TOOLS_API
      }
    }
  )

  const { loading: queryLoading } = useQuery(VERIFY_BACKGROUND_CHECK_PAYMENT, {
    context: { api: COACH_TOOLS_API },
    onCompleted: async (data) => {
      if (data?.currentMember?.prospect?.verifyBackgroundCheckPayment?.paidAt) {
        try {
          const { data: responseData } = await initiateBackgroundCheck()
          const url = responseData?.initiateMembershipBackgroundCheck.url
          setProviderUrl(url)
        } catch (err) {}
      }
    },
    onError: () => {
      setHasError(true)
    }
  })

  return (
    <>
      <Text>
        The PGA of America works with HireRight, an industry leader in
        background screenings, to deliver a secure, trustworthy, and overall
        high quality experience for you. By clicking the button below, you will
        be directed to HireRight to begin your background check.
      </Text>
      {queryLoading || loading
        ? (
          <Loading />
          )
        : hasError
          ? (
            <Text>
              <b>
                There was an error generating your background check link. Please try
                again later or contact Member Services.
              </b>
            </Text>
            )
          : (
            <ButtonWithIcon onClick={handleContinue} disabled={loading} data-testid={LINK_TEST_ID}>
              Continue to HireRight <ExternalLinkIcon />
            </ButtonWithIcon>
            )}
    </>
  )
}
