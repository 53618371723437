import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Theme } from '@pga/pga-component-library'

export const DisclaimerModal = ({ isDisclaimerOpen, handleDisclaimerOpen }) => {
  return (
    <>
      <Modal isOpen={isDisclaimerOpen} centered toggle={handleDisclaimerOpen} data-testid='disclaimer-modal'>
        <ModalHeader toggle={handleDisclaimerOpen} style={{ color: Theme.colors.primary }}>Why We Collect This Information</ModalHeader>
        <ModalBody>
          <p style={{ color: 'black', fontSize: '13px' }}>
            <b>PAT Disclaimer</b>: For entry into the PGA Playing Ability Test, it is required that an athlete selects a specific gender (male or female). The athlete must identify themself during the entry application process as a person of a particular gender.
          </p>
          <p style={{ color: 'black', fontSize: '13px' }}>
            <b>Gender Identity Disclaimer</b>: Indication of gender identity is strictly voluntary aside from the aforementioned PGA Playing Ability Test gender disclosure requirement. All responses will be kept confidential.
          </p>
        </ModalBody>
      </Modal>
    </>
  )
}
