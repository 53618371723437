import React, { useState } from 'react'
import { getMeData } from '../../utils/index'
import { provisionMppAndRedirect } from './utils'
import { withRouter } from 'react-router-dom'
import { withProvisionMPPMutation } from './mutation'
import { getMeIdQuery } from '../../queries/member'
import { compose } from 'ramda'

export const MppProvisionBaseComp = ({
  history,
  me,
  wide,
  text,
  provisionMpp,
  redirectUrl,
  className,
  ButtonComp
}) => {
  const [isDisabled, setDisabled] = useState(false)
  const [buttonText, setButtonText] = useState(text)
  const onClick = (e) => {
    e.preventDefault()
    if (!isDisabled) {
      setDisabled(true)
      provisionMppAndRedirect({ setButtonText, history, provisionMpp, redirectUrl, me })
    }
  }

  return (me && ButtonComp) ? <ButtonComp {...{ wide, onClick, className, buttonText }} /> : null
}

MppProvisionBaseComp.defaultProps = {
  text: 'Get Started',
  redirectUrl: '/education/programs/mpp/thank-you'
}

export default compose(
  withRouter,
  withProvisionMPPMutation,
  getMeData(getMeIdQuery)
)(MppProvisionBaseComp)
