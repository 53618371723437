import React from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import { primaryShadow, primaryWithOpacity } from '../../assets/colors'

import Badge from '../Badge'
import LoadingPlaceholder from '../LoadingPlaceholder'
import { hasMembershipType, isAffiliate } from '../utils'

export const LoadingPlaceholderResponsive = styled(LoadingPlaceholder)`
  width: 160px;
  @media(min-width: 768px) and (max-width: 1199.98px) {
    width: 80px;
  }
`

export const FloatingBadge = styled.span`
  position: absolute;
  margin-top: -70px;
  left: 30px;
`
export const typeLabel = {
  MB: 'PGA MEMBER',
  ST: 'STUDENT',
  AP: 'ASSOCIATE',
  PE: 'PUBLIC EMPLOYER',
  PQ: 'PRE-QUALIFIER',
  QE: 'QUALIFIER ELIGIBLE',
  TA: 'TERMINATED APPRENTICE',
  AF: 'AFFILIATE'
}

export const typeBadgeColor = {
  MB: `${Theme.colors.gold}`,
  ST: `${Theme.colors.primary}`,
  AP: `${Theme.colors.blueGrey}`,
  PE: `${Theme.colors.blueGrey}`,
  PQ: `${Theme.colors.blueGrey}`,
  QE: `${Theme.colors.blueGrey}`,
  TA: `${Theme.colors.blueGrey}`
}

export const badgeOption = (me) => isAffiliate(me)
  ? { text: typeLabel.AF, color: typeBadgeColor[me.type] }
  : hasMembershipType(me) ? { text: typeLabel[me.type], color: typeBadgeColor[me.type] } : null

export const TypeBadge = ({ type }) => <Badge color={typeBadgeColor[type]}>{typeLabel[type]}</Badge>

export const LinkFrame = styled.a`
  color: ${Theme.colors.lightBlue};
  font-family: ${Theme.fonts.Montserrat};
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  line-height: 1.3;
  padding-bottom: 8px;
`

export const CardBox = styled.div`
  ${props => props.height && `height: ${props.height}`};
  ${props => props.minHeight && `min-height: ${props.minHeight}`};
  background-color: ${props => props.color};
  box-shadow: 0 5px 10px 0 ${primaryShadow};
  margin-bottom: 20px;
  flex-basis: 33%;
  @media (min-width: 768px) {
    flex-basis: 48%;
  }

  @media (min-width: 992px) {
    flex-basis: 33%;
  }

  @media(max-width: 767.98px) {
    flex: 1;
  }
`

export const ImgFrame = styled.img`
  width: ${props => props.width || '100%'};
  height: 156px;
  margin-bottom: 16px;
  @media (max-width: 1199.98px) {
    height: 127px;
  }
  @media (max-width: 991.98px) {
    height: 142px;
  }
  @media (max-width: 767.98px) {
    height: auto;
  }
`

export const InfoFrame = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent || 'space-around'};
  align-items: center;
  background-color: ${props => props.color};
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.justifyContent || 'space-around'};
  min-height: ${props => props.minHeight || '171px'};
  padding: 15px 0;
`

export const InfoLabel = styled.p`
  font-family: ${Theme.fonts.Montserrat};
  font-weight: 500;
  font-size: ${props => props.fs || '10px'};
  color: ${primaryWithOpacity};
  margin: 0;
`

export const DuesButtons = styled.button`
  width: ${props => props.width};
  height: 40px;
  border: ${props => props.border};
  color: ${props => props.color};
  background-color: ${props => props.bgColor};
  cursor: pointer;
`

export const DynamicInfoFrame = styled.h4`
  padding: ${({ noPadX }) => noPadX ? '.25rem 0' : '.25rem'};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 20px;
  font-weight: 500;
  flex-basis: 32%;
  color: ${Theme.colors.primary};
`

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
`

export const AngleRight = () => <i className='fa fa-angle-right pl-1' />

export const Headline = styled.div`
  padding-left: 10px;
  font-size: 20px;
  font-family: 'Playfair Display', serif;
  line-height: 1.09;
  text-transform: uppercase;
  color: ${props => props.color || Theme.colors.primary};
`
