import React, { useEffect, useState } from 'react'
import { pathOr } from 'ramda'
import styled from 'styled-components'
import NewsItem from './NewsItem'

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
`

export const buildQuery = ({ type }) => ({
  hitsPerPage: pathOr(userTypes.default.count, [type, 'count'], userTypes),
  page: 0,
  attributesToRetrieve: '*',
  responseFields: '*',
  facetFilters: ['type:post']
})

export const userTypes = {
  MB: {
    label: 'Members',
    count: 6
  },
  ST: {
    label: 'Students',
    count: 6
  },
  AP: {
    label: 'Associates',
    count: 6
  },
  default: {
    label: 'all',
    count: 9
  }
}

export default ({ me, index }) => {
  const [hits, setHits] = useState([])

  useEffect(() => {
    const getHits = async () => {
      const query = buildQuery({ type: me.type })
      const { hits: newHits } = await index.search('', query)
      setHits(newHits)
    }
    getHits()
  }, [])

  return (
    <FlexContainer>
      {hits.map(article => (
        <NewsItem article={article} key={article.objectID} />
      ))}
    </FlexContainer>
  )
}
