import React, { Fragment } from 'react'
import ProgressBar from './ProgressBar'
import { Condition, TotalWrapper } from './Styles/Shared.styles'
import getBrokendownMSR, { substractOrZero } from './utils'
import { T, always, cond } from 'ramda'

export default ({ me: { firstName, msrStatus } }) => {
  const {
    totalPdr,
    eduPdr,
    meetPdr,
    totalEarned,
    pgaRequired
  } = getBrokendownMSR(msrStatus.credits)

  const getNeededCredits = (category) => substractOrZero(category.required, category.earned)
  const eduNeeded = getNeededCredits(eduPdr)
  const pdrNeeded = getNeededCredits(totalPdr)
  const meetNeeded = getNeededCredits(meetPdr)

  const completed = eduNeeded === 0 && pdrNeeded === 0 && meetNeeded === 0
  const notStarted = eduPdr.earned === 0 && meetPdr.earned === 0 && totalPdr.earned === 0

  const greeting = cond([
    [() => notStarted, always(`Hello ${firstName}, start earning credits today!`)],
    [() => completed, always(`Congrats, ${firstName}! You've met your PDR requirements for this cycle.`)],
    [T, always('Earn more credits by clicking the "Earn Credits" button below.')]
  ])

  return (
    <>
      <TotalWrapper>{greeting()}</TotalWrapper>
      <div>
        <ProgressBar
          total={totalPdr.required}
          completed={totalEarned}
          eduPdr={eduPdr}
          meetPdr={meetPdr}
          totalPdr={totalPdr}
          pgaRequired={pgaRequired}
        />
        <Condition>Requirements in all categories must be met to maintain membership.</Condition>
      </div>
    </>
  )
}
