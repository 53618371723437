import React from 'react'
import { Panel } from '@pga/pga-component-library'
import { Title, LevelTrackBox, SectionWrapperBg, SectionWrapper, SubTitle, Description, StatusLink, StatusWrapper, Status, ColumnWrapper, IntercomLink, IntercomText, Span } from './styles'
import getStatus from '../../../../utils/prospect/getStatus'
import { withProspectQuery } from '../../../../utils/prospect/withProspectQuery'
import { StatusTrackerError, StatusTrackerLoader } from '../WidgetStates'
import { IntercomAPI } from '@pga/react-intercom'
import sections from './sections'
import { withRouter } from 'react-router-dom'
import { useAllFeatureFlags } from '../../../../lib/feature-flags'

export const StatusBox = ({ statusText, date, disclaimer }) => {
  const subInfo = date ? `| ${date}` : disclaimer ? (<>{disclaimer}</>) : null
  return (
    <StatusWrapper>
      <SubTitle weight='700' size='13px'>Status:</SubTitle>
      <Span><Status color={statusText.color}>{statusText.text}</Status> {subInfo}</Span>
    </StatusWrapper>
  )
}

export const Section = ({ title, expiresText, description, link, action, bg, status, date, disclaimer, linkComponent }) => {
  const WrapperComponent = bg ? SectionWrapperBg : SectionWrapper
  const statusText = getStatus(status)
  return (
    <div className='col-sm-12 col-lg-3 d-flex flex-column'>
      <ColumnWrapper>
        <WrapperComponent bg={bg}>
          {!bg && (<SubTitle>{expiresText || '\u00A0'}</SubTitle>)}
          <Title>{title}</Title>
          <Description>{description}</Description>
          {action ? <StatusLink href={link}>{action}</StatusLink> : linkComponent}
          {!bg ? <StatusBox {...{ statusText, date, disclaimer }} /> : null}
        </WrapperComponent>
      </ColumnWrapper>
    </div>
  )
}

export const ContactLink = () => (
  <div className='row mt-3'>
    <div className='col'>
      <IntercomText>Have questions or need some help? <IntercomLink onClick={() => IntercomAPI('show')}>Contact Membership Services</IntercomLink></IntercomText>
    </div>
  </div>
)

const StatusTracker = ({ loading, error, currentMember, refetch, networkStatus, history }) => {
  const featureFlags = useAllFeatureFlags()

  if (loading) {
    return <StatusTrackerLoader />
  }
  if (error) {
    return <StatusTrackerError {...{ refetch, networkStatus }} />
  }
  return (
    <Panel>
      <LevelTrackBox>
        <div className='container'>
          <div className='row'>
            {sections(currentMember, history, featureFlags).map((section, i) => (
              <Section key={i} {...section} data={currentMember} />
            ))}
          </div>
          <ContactLink />
        </div>
      </LevelTrackBox>
    </Panel>
  )
}

export default withRouter(withProspectQuery(StatusTracker))
