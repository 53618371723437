import './polyfills' // For IE 11 support
import React from 'react'
import ReactDOM from 'react-dom'
import 'font-awesome/css/font-awesome.css'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ApolloProvider } from '@apollo/client'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'
import App from './App'
import { unregister } from './registerServiceWorker'
import apolloClient from './apolloClient'
import ErrorBoundary from './App/ErrorBoundary'
import { BlockAppAccess, BLOCK_APP_ACCESS } from './App/BlockAppAccess'
import { UserProvider } from './lib/UserProvider'
import { AuthProvider } from './lib/auth'

const AppWrapper = () => (
  <ApolloProvider client={apolloClient}>
    <Router
      history={createBrowserHistory({
        basename: process.env.PUBLIC_URL
      })} basename={process.env.PUBLIC_URL}
    >
      <AuthProvider>
        <UserProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </UserProvider>
      </AuthProvider>
    </Router>
  </ApolloProvider>
)

const RenderView = () => BLOCK_APP_ACCESS ? <BlockAppAccess /> : <AppWrapper />

ReactDOM.render(RenderView(), document.getElementById('root')
)

unregister()
