import React, { useState } from 'react'
import { Theme } from '@pga/pga-component-library'
import { H1, FlexCol, Title, Description, Btn, ButtonContainer, Link } from './styles'
import { Modal, ModalBody } from 'reactstrap'
import { SmallText } from '../../SignUp/SignUpCard/styles'
import ProspectForm from '../../../pages/ProspectRegistration/Form'
import { withRouter } from 'react-router-dom'

const GetStartedButton = ({ history }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => setIsOpen(!isOpen)
  return (
    <>
      <Btn onClick={handleOpen} data-testid='get-started'>Get Started</Btn>
      <Modal isOpen={isOpen} centered toggle={handleOpen} data-testid='get-started-modal'>
        <ModalBody style={{ padding: '30px' }}>
          <Title fs='20px' fw='600'>Path To Membership</Title>
          <SmallText className='mt-2 mb-3'>Hi! Get started on your path to membership by filling out the required fields below.</SmallText>
          <ProspectForm history={history} />
        </ModalBody>
      </Modal>
    </>
  )
}

export const PathToMembership = ({ history }) => {
  return (
    <FlexCol>
      <H1 color={Theme.colors.primary}>PATH TO MEMBERSHIP</H1>
      <Title>Expand your career and grow the game you love</Title>
      <Description>By becoming a PGA of America Member, you can fully immerse yourself in the world of golf, forge a fulfilling career, and actively contribute to the sport's growth and development. Join a network of over 30,000 recognized golf industry leaders worldwide and embrace a multitude of benefits that come with PGA of America Membership today.</Description>
      <ButtonContainer>
        <GetStartedButton history={history} />
        <Link href='https://pga.org/membership' name='learn-more' target='_blank' rel='noreferrer'>Learn More &rarr;</Link>
      </ButtonContainer>
    </FlexCol>
  )
}

export default withRouter(PathToMembership)
