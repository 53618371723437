import React, { Fragment, memo } from 'react'
import { Button } from '../../../Button'
import { DownloadInvoice } from './Invoices'
import { Layout, LeftHalf, RightHalf, ActionsContainer } from './styled'
import { pgaTld } from '../../../utils'
import { getDefaultInvoiceNumber } from './utils'

import { flags, useFeatureFlags } from '../../../../lib/feature-flags'

const extranetTld = window.location.origin.includes('.sandboxpga.org') || window.location.origin.includes('.pga.local') ? 'ccms-pgastage.dev.pga.org' : 'extranet.pgalinks.com'

const makeDuesUrl = (memberId) => `https://${extranetTld}/memberonly2/017/pgamemssadues.wizstep1?p_cust_id=${memberId}`
const orgDuesUrl = (invoiceNum) => `https://payments.${pgaTld}?invoiceNumbers=${invoiceNum}`

export const ProxyReceiptBase = ({ assignProxyUrl }) => (
  <>
    <a href={assignProxyUrl} target='blank' className='mb-1' rel='noopener noreferrer'>
      Assign Proxy<i className='fa fa-angle-right pl-1' />
    </a>
    <DownloadInvoice />
  </>
)

ProxyReceiptBase.defaultProps = {
  assignProxyUrl: `https://payments.${pgaTld}/proxy-request`
}

export const ProxyReceipt = memo(ProxyReceiptBase)

export const ProxyReceiptWithPayButton = ({ payDuesUrl }) => (
  <Layout align='flex-end' className='mt-3 mt-md-2'>
    <LeftHalf>
      <ActionsContainer>
        <ProxyReceipt />
      </ActionsContainer>
    </LeftHalf>
    <RightHalf>
      <a href={payDuesUrl} target='_blank' rel='noopener noreferrer'>
        <Button margin='0px'>PAY DUES</Button>
      </a>
    </RightHalf>
  </Layout>
)

const CardFooter = ({ showPayDuesButton, me }) => {
  const [isMergeEnabled] = useFeatureFlags([flags.FLAG_FEAT_MERGE_CCMS_M360_INVOICE_REQS])

  const payDuesUrl = () => {
    if (isMergeEnabled) {
      const invoiceNum = getDefaultInvoiceNumber(me)
      return orgDuesUrl(invoiceNum)
    }
    return makeDuesUrl(me.id)
  }

  return showPayDuesButton
    ? <ProxyReceiptWithPayButton payDuesUrl={payDuesUrl()} />
    : (
      <div className='d-flex mt-3 mt-sm-2 flex-column'>
        <ProxyReceipt />
      </div>
      )
}

export default CardFooter
