import React from 'react'
import styled from 'styled-components'
import { CaptionIcon, Theme } from '@pga/pga-component-library'

const NoABox = styled.div`
  display: flex;
  height: 90%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const MessageTitle = styled.p`
  color: ${Theme.colors.primary};
  font-weight: 500;
  line-height: 15px;
  text-transform: uppercase;
`

export const MessageLine = styled.p`
  color: ${Theme.colors.blueGrey};
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
`

export const CaptionIconStyled = styled(CaptionIcon)`
  margin: 20px;
`

export default () => (
  <NoABox>
    <CaptionIconStyled />
    <MessageTitle>no other announcements</MessageTitle>
    <MessageLine>
      You are up to date. <br />
      Check back soon for new <br />
      updates and announcements.
    </MessageLine>
  </NoABox>
)
