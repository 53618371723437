import React, { Fragment } from 'react'
import { Container } from 'reactstrap'
import { Dashboard } from '../Dashboard/Dashboard'
import SecondaryNav from '../SubNavigation'
import Layout from '../Layout'
import Modal from './Modal'

const TerminatedWrapper = ({ me }) => {
  return (
    <div style={{ height: '80vh' }}>
      <Layout>
        <>
          <SecondaryNav />
          <Container>
            <Modal me={me} />
            <section>
              <Dashboard me={me} authReady />
            </section>
          </Container>
        </>
      </Layout>
    </div>
  )
}
export default TerminatedWrapper
