import React from 'react'
import styled, { css } from 'styled-components'
import { Theme, Badge, Button } from '@pga/pga-component-library'
import { Text } from '../shared'
import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'ramda'
import { RegisterForCPPAndRedirect } from '../sharedUtils'
import { getMeData } from '../../utils'
import { getCurrentProgram } from '../../queries/member'

const IconSyles = css`
  color: white;
  padding-right: 8px;
`

export const CheckIcon = styled.i.attrs({
  className: 'fa fa-check fa-lg'
})`
  ${IconSyles};
`

export const CrossIcon = styled.i.attrs({
  className: 'fa fa-times fa-lg'
})`
  ${IconSyles};
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const Btn = styled(Button)`
  width: ${props => props.wide ? '300px' : '100px'};
  margin: 10px 0;
  padding: 12px 0;
  text-align: center;
`

export const BadgeStyled = styled(Badge)`
  font-size: 11px;
`

export const CompletedBadge = () => <BadgeStyled color={Theme.colors.successGreen}><CheckIcon />Completed</BadgeStyled>
export const NotCompletedBadge = () => <BadgeStyled color={Theme.colors.warning}><CrossIcon />Not Started</BadgeStyled>
export const TableText = (text, color) => () => <Text color={color} basis alignLeft>{text}</Text>
export const CpeHourText = () => <p className='mb-0'>Hours earned in your selected career path, or those universally applied, will count towards this requirement.</p>

export const ByLine = ({ text }) => <p className='mt-1 mb-0'>{text}</p>
export const makeByline = (text) => () => <ByLine text={text} />

export const LearnMoreLink = (text, url) => () => <a href={url}>{text}</a>
export const ButtonComp = (text, url) => () => <Btn href={url}>{text}</Btn>
export const TextWithComp = (text, Component) => () => (
  <Col>
    <Text basis short>{text}</Text>
    {Component && <Component />}
  </Col>
)

export const CppEnrollNowButtonComponent = ({ client, me, wide }) => (
  <Btn wide={wide} onClick={() => RegisterForCPPAndRedirect({ client, me })}>Enroll Now</Btn>
)

export const CppEnrollNowButton = compose(
  withApollo,
  getMeData(getCurrentProgram)
)(CppEnrollNowButtonComponent)
