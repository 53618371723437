/* eslint-disable react/jsx-handler-names */

import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Button, Theme } from '@pga/pga-component-library'
import {
  ButtonWrapper,
  Flex,
  Btn,
  SliderAnimationWrapper,
  AngleDown
} from './shared'

export const ActionBtn = styled(Button)`
  font-family: ${Theme.fonts.Montserrat};
  ${props => props.disabled ? `border: 1px solid ${Theme.colors.grey}` : `border: 1px solid ${Theme.colors.lightBlue}`};
  ${props => props.noBottomBorder && 'border-bottom: 0px;'}
  font-size: 12px;
  height: 42px;
  width: ${props => props.large ? '200' : '150'}px;
  font-weight: 500;
  padding: 0;
  &:focus {
    border: 2px solid ${Theme.colors.lightBlue};
    box-shadow: none;
  }
  @media screen and (max-width: 575.98px) {
    width: 90%;
    align-self: center;
  }
`

export default class ActionButton extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      visible: false
    }
    this.toggleVisibility = this.toggleVisibility.bind(this)
  }

  toggleVisibility () {
    this.setState({
      visible: !this.state.visible
    })
  }

  render () {
    const { visible } = this.state
    const { options, disabled } = this.props
    return (
      <ButtonWrapper>
        <Flex col bottom>
          <ActionBtn disabled={disabled} onClick={this.toggleVisibility} outline>ACTIONS<AngleDown /></ActionBtn>
          {visible && (
            <SliderAnimationWrapper>
              {options.map(({ link, label, permClick, noBottomBorder, onClick, ModalComp }, i) => (
                !permClick
                  ? (<Fragment key={i}><Btn key={link} onClick={onClick} noBottomBorder={noBottomBorder} large to={link} noMargin>{label}</Btn>{ModalComp && <ModalComp />}</Fragment>)
                  : (<ActionBtn data-testid='action-btn' noBottomBorder={noBottomBorder} key={link} large outline onClick={permClick}>{label}</ActionBtn>)
              ))}
            </SliderAnimationWrapper>
          )}
        </Flex>
      </ButtonWrapper>
    )
  }
}
