import { findMostRecentPAT } from './getStatus'

export const hasPositiveOutcome = (status) => ['PASSED', 'APPROVED', 'COMPLETED', 'QUALIFIED'].includes(status)

export const affiliateRequirementsPassed = (data) => {
  const { prospect } = data || {}
  return {
    'Background Check': hasPositiveOutcome(prospect?.backgroundCheck?.status),
    'Qualifying Level': hasPositiveOutcome(prospect?.qualifyingLevel?.status)
  }
}

export const associateRequirementsPassed = (data) => {
  const { prospect } = data || {}
  return {
    ...affiliateRequirementsPassed(data),
    'Player Ability Test (PAT)': hasPositiveOutcome(findMostRecentPAT(prospect?.playingAbilityTest)?.status)
  }
}

export default (requirements) => {
  const passedRequirements = Object.values(requirements).filter((requirement) => requirement).length
  const totalRequirements = Object.values(requirements).length
  return `${passedRequirements} of ${totalRequirements}`
}

export const isAssociateRequirementsMet = (data) => Object.values(associateRequirementsPassed(data)).every(requirement => requirement)
export const isAffiliateRequirementsMet = (data) => Object.values(affiliateRequirementsPassed(data)).every(requirement => requirement)
