import qs from 'qs'

export const makeFilterString = (objectIds) => {
  const defaultFilters = 'current_step:Complete AND tos:true'

  const orObjectIds = objectIds
    .map(id => `objectID:${id}`)
    .join(' OR ')

  return `${defaultFilters} AND (${orObjectIds})`
}

export const createURL = state => `?${qs.stringify(state)}`

export const makeUrlParams = jobIds => {
  const nextFilters = makeFilterString(jobIds)
  const nextState = {
    configure: {
      filters: nextFilters
    }
  }
  const urlParams = createURL(nextState)
  return urlParams
}
