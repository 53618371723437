import { gql, useQuery } from '@apollo/client'
import { COACH_TOOLS_API } from '../../apolloClient'

export const PROSPECT_DATE_QUERY = gql`
  query Prospect {
    currentMember {
      prospect {
        startedAt
      }
    }
  }
`

export const withProspectDateQuery = (Component) => (props) => {
  const { data } = useQuery(PROSPECT_DATE_QUERY, {
    context: {
      api: COACH_TOOLS_API
    }
  })
  const { currentMember } = data || {}
  return <Component {...{ currentMember, ...props }} />
}
