import { map, filter, find, propEq } from 'ramda'

const unixNow = (Date.now() / 1000) | 0

export const resources = [
  {
    id: 1,
    label: 'Education',
    links: [{
      id: 1,
      url: 'http://apps.pga.org/admin/education/knowledgeCenter/index.cfm',
      label: 'PGA Knowledge Center',
      target: '_blank'
    }, {
      id: 2,
      url: 'http://apps.pga.org/professionals/apps/education/lms/index.cfm?action=lbr',
      label: 'Course Library',
      target: '_blank'
    }, {
      id: 3,
      url: 'http://apps.pga.org/patinfo/patsearch.cfm',
      label: 'Playing Ability Test (PAT)',
      target: '_blank'
    }, {
      id: 4,
      url: '#',
      label: 'Continuing Education',
      target: '_blank'
    }, {
      id: 5,
      label: 'Transcript',
      url: 'http://apps.pga.org/professionals/education/knowledgeCenter/index.cfm?page=transcriptListing&entity_id=',
      target: '_blank'
    }, {
      id: 6,
      label: 'Seminar Schedule',
      url: 'http://pdf.pgalinks.com/professionals/education/pgm/wekits/RegisterSummerFY18SeminarSchedule.pdf',
      target: '_blank'
    }, {
      id: 7,
      label: 'PGM Resources',
      url: 'http://apps.pga.org/professionals/education/knowledgeCenter/index.cfm?page=resources&entity_id=',
      target: '_blank'
    }, {
      id: 8,
      label: 'Progress Tracker',
      url: 'http://apps.pgalinks.com/professionals/apps/directories/appsearch/index.cfm',
      target: '_blank'
    }, {
      id: 9,
      label: 'Progress Policy',
      url: 'http://resources.pga.org/articles/pga-associate-acceptable-progress-policy/'
    }]
  },
  {
    id: 2,
    label: 'Resources',
    links: [{
      id: 1,
      label: 'Forms Library',
      url: 'https://resources.pga.org/form-library/'
    }, {
      id: 2,
      label: 'Marketing Resources',
      url: 'https://marcomcentral.app.pti.com/printone/catalog.aspx?uigroup_id=18249',
      target: '_blank'
    }, {
      id: 3,
      label: 'PAT Schedule',
      url: 'http://apps.pga.org/patinfo/patsearch.cfm',
      target: '_blank'
    }, {
      id: 4,
      label: 'Application for PGA Membership',
      url: 'http://pdf.pgalinks.com/professionals/education/pgm/wekits/Membership%20Application.pdf',
      target: '_blank'
    }, {
      id: 5,
      label: 'Background Check',
      url: 'https://www.emplscreen.com/pga.asp',
      target: '_blank'
    }, {
      id: 6,
      label: 'PGM Seminar Schedule',
      url: 'http://pdf.pgalinks.com/professionals/education/pgm/wekits/2.0-3.0_2018_19_Seminar_Schedule.pdf',
      target: '_blank'
    }, {
      id: 8,
      label: 'About PAT',
      url: 'https://resources.pga.org/maintain-my-membership/membership-overview/pat-policies-and-procedures/'
    }, {
      id: 9,
      label: 'Non-Standard Testing Accommodations',
      url: 'http://pdf.pgalinks.com/professionals/education/pgm/wekits/Nonstandard%20Testing%20Accommodation%20Form%202-18.pdf',
      target: '_blank'
    }, {
      id: 10,
      label: 'Member Assistance Program',
      url: 'https://resources.pga.org/member-benefits/member-assistance/member-assistance-program/'
    }, {
      id: 11,
      label: 'Section Events Registrations',
      url: 'https://extranet.pgalinks.com/evtssa/evtssashop.locator',
      target: '_blank'
    }, {
      id: 12,
      label: 'Disaster Relief Fund',
      url: 'https://resources.pga.org/member-benefits/member-assistance/pga-disaster-relief-fund/'
    }, {
      id: 13,
      label: 'PGA.coach',
      url: 'https://resources.pga.org/increase-revenue/pga-coach/'
    }, {
      id: 15,
      label: 'Member Benefits',
      url: 'https://resources.pga.org/member-benefits/'
    }]
  }, {
    id: 3,
    label: 'Education',
    links: [
      {
        id: 1,
        url: '/education/programs/mpp',
        label: 'Master Professional Program'
      }, {
        id: 2,
        url: 'https://app.postbeyond.com/element/',
        label: 'Social Hub',
        target: '_blank'
      }, {
        id: 3,
        label: 'Certified Professional Program',
        url: '/education/programs/cpp'
      }, {
        id: 4,
        label: 'Course Library',
        url: 'http://apps.pga.org/professionals/apps/education/lms/index.cfm?action=lbr',
        target: '_blank'
      }, {
        id: 5,
        label: 'Specialized Program',
        url: '/education/programs/spp'
      }, {
        id: 6,
        label: 'Continuing Education',
        url: 'https://resources.pga.org/my-membership/earn-and-report-msrs/continuing-education-offers-msr-opportunities/'
      }
    ]
  }, {
    id: 4,
    label: 'Careers',
    links: [
      {
        id: 1,
        label: 'Find a Job',
        url: 'https://jobs.pga.org/board'
      }, {
        id: 2,
        label: 'Post a Job',
        url: 'https://jobs.pga.org/post'
      }, {
        id: 3,
        label: 'Career Growth',
        url: ' https://resources.pga.org/grow-my-career/build-my-career/'
      }, {
        id: 4,
        label: 'Career Consultants',
        url: 'https://resources.pga.org/career-advancement/career-services/pga-career-consultants/'
      }, {
        id: 5,
        label: 'Career Consultant FAQ',
        url: 'https://resources.pga.org/career-advancement/career-services/career-consultant-faq/'
      }, {
        id: 6,
        label: 'ExecuSearch',
        url: 'https://resources.pga.org/career-advancement/career-services/career-links/'
      },
      {
        id: 7,
        label: 'Career Toolkit',
        url: 'https://resources.pga.org/career-advancement/career-toolkit/'
      }
    ]
  }, {
    id: 5,
    label: 'General Links',
    links: [
      {
        id: 1,
        label: 'Forms Library',
        url: 'https://www.pga.org/articles/all-your-pga-forms-one-library',
        target: '_blank'
      }, {
        id: 3,
        label: 'Section Events',
        url: 'https://extranet.pgalinks.com/evtssa/evtssashop.locator',
        target: '_blank'
      }, {
        id: 4,
        label: 'Compensation Survey Report',
        url: 'http://apps.pga.org/professionals/apps/memberinfo/compsurvey/',
        target: '_blank'
      }, {
        id: 5,
        label: 'All Events',
        url: 'https://extranet.pgalinks.com/evtssa/evtssashop.locator',
        target: '_blank'
      }, {
        id: 6,
        label: 'Progress Tracker',
        url: 'http://apps.pgalinks.com/professionals/apps/directories/appsearch/index.cfm',
        target: '_blank'
      }
    ]
  }, {
    id: 6,
    label: 'Education',
    links: [
      {
        id: 1,
        label: 'Transcript',
        url: '',
        target: '_blank'
      }, {
        id: 2,
        type: 'CareerConsultantContact'
      }
    ]
  }, {
    id: 7,
    label: 'Resources',
    links: [
      {
        id: 1,
        label: 'Location Map',
        url: 'http://pdf.pgalinks.com/professionals/education/pgm/wekits/PGM%20black%20and%20white%20map%20combination%20map%20accredited%202015.pdf',
        target: '_blank'
      }, {
        id: 2,
        label: 'Steps to Membership',
        program: '2.0',
        url: 'https://resources.pga.org/Document-Library/flow-chart-freshman2010-PGA-PGM-2.0-with-signature-line-8-18.pdf',
        target: '_blank'
      }, {
        id: 3,
        label: 'Steps to Membership',
        program: '3.0',
        url: 'https://resources.pga.org/Document-Library/flow-chart-freshman2018-PGA-PGM-3.0-with-signature-line-8-18.pdf',
        target: '_blank'
      }, {
        id: 4,
        label: 'At-A-Glance',
        url: 'http://pdf.pgalinks.com/professionals/education/pgm/wekits/PGA%20PGM%20Program%20at%20a%20Glance%205-11%20University%20Version.pdf',
        program: '2.0',
        target: '_blank'
      }, {
        id: 5,
        label: 'At-A-Glance',
        url: 'https://resources.pga.org/Document-Library/pga-pgm-at-a-glance.pdf',
        program: '3.0',
        target: '_blank'
      }, {
        id: 7,
        label: 'At-A-Glance',
        url: 'https://resources.pga.org/grow-my-career/my-career-path/golf-operations/',
        program: '3.0',
        target: '_blank'
      }, {
        id: 8,
        label: 'At-A-Glance',
        url: 'http://pdf.pgalinks.com/professionals/education/pgm/wekits/At%20a%20Glance%20June%202018.pdf',
        program: '2.0',
        target: '_blank'
      }, {
        id: 9,
        label: 'How to Prepare for Seminars',
        url: 'http://pdf.pgalinks.com/professionals/education/pgm/wekits/how_to_prepare_for_pgm_2.0_seminars.pdf',
        program: '2.0',
        target: '_blank'
      }, {
        id: 10,
        label: 'Associated Costs',
        url: 'https://resources.pga.org/Document-Library/pgm-associated-costs-form-252.pdf',
        program: '2.0',
        target: '_blank'
      }, {
        id: 11,
        label: 'PGA Credit Union',
        url: 'https://www.ibmsecu.org/',
        target: '_blank'
      }, {
        id: 12,
        label: 'How to Prepare for Seminars',
        url: 'https://pdf.pgalinks.com/professionals/education/pgm/wekits/how-to-prepare-pgm-3.0-seminars.pdf',
        program: '3.0',
        target: '_blank'
      }, {
        id: 13,
        label: 'Steps to Become a PGA Member',
        url: 'https://resources.pga.org/my-membership/associate-program/'
      }, {
        id: 14,
        label: 'Supervising Professional Guide',
        url: 'https://resources.pga.org/Document-Library/supervising-professionals-guide-pgm-3.0.pdf',
        program: '3.0',
        target: '_blank'
      },
      {
        id: 15,
        label: 'Fairway Tales Podcast',
        url: 'https://beta.pga.org/podcast/'
      },
      {
        id: 16,
        label: 'PGM Seminar Schedule',
        url: `https://events.pga.org/search?numericFilters%5B0%5D=start_datetime_unix%3E%3D${unixNow}&hitsPerPage=10&page=1&refinementList%5Bevent_categories%5D%5B0%5D=Education&configure%5BnumericFilters%5D%5B0%5D=start_datetime_unix%3E%3D${unixNow}&configure%5BaroundRadius%5D=40225&query=`
      },
      {
        id: 17,
        label: 'Non-Standard Testing Accommodations',
        url: 'http://pdf.pgalinks.com/professionals/education/pgm/wekits/Nonstandard%20Testing%20Accommodation%20Form%202-18.pdf',
        target: '_blank'
      },
      {
        id: 18,
        label: 'About PAT / PAT Schedule',
        url: 'https://resources.pga.org/my-membership/associate-program/pat-policies-and-procedures/'
      },
      {
        id: 19,
        label: 'Section Events Registrations',
        url: 'https://extranet.pgalinks.com/evtssa/evtssashop.locator',
        target: '_blank'
      }, {
        id: 20,
        label: 'Associated Costs',
        url: 'https://resources.pga.org/Document-Library/associated-costs-3.0.pdf',
        program: '3.0',
        target: '_blank'
      }
    ]
  }, {
    id: 8,
    label: 'Careers',
    links: [
      {
        id: 1,
        type: 'CareerConsultantContact'
      }
    ]
  }, {
    id: 9,
    label: 'Education',
    links: [
      {
        id: 1,
        type: 'MentorContact'
      }
    ]
  }
]

const userTypeResources = {
  MB: [{
    categoryId: 4,
    linksId: [4, 5, 6, 7]
  }, {
    categoryId: 2,
    linksId: [13, 1, 2, 12, 10, 15]
  }, {
    categoryId: 3,
    linksId: [5, 3, 1, 6]
  }],
  ST: [{
    categoryId: 6,
    linksId: [2]
  }, {
    categoryId: 2,
    linksId: [3, 8, 4, 5, 9]
  }, {
    categoryId: 7,
    linksId: [1, 2, 3, 4, 5, 15]
  }],
  AP: [{
    categoryId: 8,
    linksId: [1]
  }, {
    categoryId: 9,
    linksId: [1]
  }, {
    categoryId: 7,
    linksId: [5, 12, 16, 17, 18, 8, 9, 10, 20, 13, 19]
  }]
}

const defaultResources = [{
  categoryId: 1,
  linksId: [1, 3, 5, 6, 7, 8, 9]
}, {
  categoryId: 4,
  linksId: [1, 2, 3, 4]
}, {
  categoryId: 5,
  linksId: [1, 3, 4, 5, 6]
}]

const bocLinks = (categoryId, committeeCodes = []) => {
  const bocLinks = [
    {
      id: 2,
      code: 'BOCUSER',
      link: {
        id: 'BOCUSER',
        label: 'Board of Control Documents',
        url: 'https://sites.google.com/pgahq.com/board-of-control',
        target: '_blank'
      }
    },
    {
      id: 2,
      code: 'MEMBSHIP',
      link: {
        id: 'MEMBSHIP',
        label: 'Membership Committee Documents',
        url: 'https://sites.google.com/pgahq.com/membership-committee/home',
        target: '_blank'
      }
    }
  ]
  return bocLinks.filter(({ id, code }) => categoryId === id && committeeCodes.includes(code)).map(({ link }) => link)
}

export default (type, committeeCodes) => {
  const typeResources = userTypeResources[type] || defaultResources
  return map(
    ({ categoryId, linksId }) => {
      const { id, label, links } = filter(({ id }) => id === categoryId, resources)[0]
      return {
        id,
        label,
        links: [...map(id => find(propEq('id', id), links), linksId), ...bocLinks(id, committeeCodes)]
      }
    },
    typeResources
  )
}
