import React from 'react'
import { Container } from 'reactstrap'
import SecondaryNav from '../SubNavigation'
import Dashboard from './Dashboard'
import { ContentWrapper } from '../utils/styles'

import styled from 'styled-components'

const Section = styled.section`
  display: flex;
  flex-direction: column;
`

export default () => (
  <ContentWrapper>
    <SecondaryNav />
    <Container>
      <Section>
        <Dashboard />
      </Section>
    </Container>
  </ContentWrapper>
)
