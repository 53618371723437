import React from 'react'
import styled from 'styled-components'
import { white } from '../../../../assets/colors'
import { CardBox } from '../../shared'
import DuesView from './Views'

export const CARD_HEIGHT = '190px'

const CardBoxPadding = styled(CardBox)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 1rem 1.5rem;
`

const MembershipDues = (props) => {
  return (
    <CardBoxPadding color={white} minHeight={CARD_HEIGHT}>
      <DuesView {...props} />
    </CardBoxPadding>
  )
}

export default MembershipDues
