import React from 'react'
import CompactProgressBar from './CompactProgressBar'
import getBrokendownMSR from '../utils'

import { CompressedMSRLoader } from '../PDRCredits'

export const CompactPDRWrapper = ({ me }) => {
  const {
    totalPdr,
    eduPdr,
    meetPdr
  } = getBrokendownMSR(me.msrStatus.credits)
  return (
    <div>
      <CompactProgressBar
        me={{
          eduPdr,
          meetPdr,
          totalPdr
        }}
      />
    </div>
  )
}

export default ({ loading, me }) => {
  return loading ? <CompressedMSRLoader /> : <CompactPDRWrapper me={me} />
}
