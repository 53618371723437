import { reduce, pick, compose, not, values } from 'ramda'
import { findObject } from '../../utils/index'

export const substractOrZero = (op1, op2) => op1 > op2 ? op1 - op2 : 0

export const isNinetyPlus = compose(
  not,
  reduce((acc, field) => acc + field, 0),
  msr => [...values(msr)]
)

export const getMsrFields = pick(['required', 'new', 'earned', 'recovery', 'totalDeficit', 'label'])

export const isValidMSR = (msr) => {
  const eduPdr = findObject(msr, 'Education / Player Engagement')
  const meetPdr = findObject(msr, 'Meetings / National Surveys')
  const totalPdr = findObject(msr, 'Total PDRs')
  return eduPdr && totalPdr && meetPdr && eduPdr.required >= 0
}

export default (msr) => {
  const eduPdr = getMsrFields(findObject(msr, 'Education / Player Engagement'))
  const meetPdr = getMsrFields(findObject(msr, 'Meetings / National Surveys'))
  const totalPdr = getMsrFields(findObject(msr, 'Total PDRs')) || { earned: 0, required: eduPdr.required }

  return {
    totalPdr,
    eduPdr,
    meetPdr
  }
}

export const percentOf = (completed, total) => (completed * 100) / (total > 0 ? total : 100)

export const filledPercent = total => completed =>
  (completed > 0 && completed >= total)
    ? 100
    : percentOf(completed, total)
