import { path, cond, always, T, pipe, pathOr, equals, prop, propOr, isNil, curry } from 'ramda'
import { registerEducationProgramMutation } from '../queries/education'
import moment from 'moment'
import { pgaTld } from '../utils'

export const CPP_PROGRESS_URL = '/education/programs/cpp/progress'
export const CPP_MANAGE_URL = '/education/programs/cpp/manage'
export const CPP_APPLY_URL = '/education/programs/cpp/apply'

export const MPP_PROGRESS_URL = '/education/programs/mpp/progress'
export const MPP_APPLY_URL = '/education/programs/mpp/apply'

export const programCdToLabel = {
  CPP1: 'Certified Professional Program',
  CPP2: 'Certified Professional Program',
  CPP3: 'Certified Professional Program',
  MPP: 'Master Professional Program',
  MPP1: 'Master Professional Program',
  MPP2: 'Master Professional Program',
  MPP3: 'Master Professional Program',
  SPP: 'Specialized Professional Program'
}

export const FIVE_YEARS = 365 * 5
export const TEN_YEARS = 365 * 10

export const isDatePast = (date) => new Date(date) < new Date()
export const isAtLeastFiveYears = activeDays => activeDays >= FIVE_YEARS
export const isAtLeastTenYears = activeDays => activeDays >= TEN_YEARS
export const dateEligible = curry((deadline, activeDays) => moment().add(deadline - activeDays, 'days').format('MM/DD/YYYY'))
export const dateCppEligible = dateEligible(FIVE_YEARS)
export const dateMppEligible = dateEligible(TEN_YEARS)

const appApplied = programStatus => programStatus === 'APPL'
const appApproved = programStatus => programStatus === 'APPR'
const appInProgress = programStatus => programStatus === 'PROG'

const APPLICATION_APPLIED_MESSAGE = 'Your application is under review. You will be notified via email and your PGA.org dashboard when a decision has been made.'
const CPP_APPLICATION_APPROVED_MESSAGE = 'You\'re approved to participate in the Certified Professional program. You can apply for admittance to another Career Path once you\'ve completed your current program. If you have any questions, please call PGA Education at (866) 866-3382, option 6.'
const MPP_APPLICATION_APPROVED_MESSAGE = 'You\'re approved to participate in the Master Professional program. You can apply for admittance to another Career Path once you\'ve completed your current program. If you have any questions, please call PGA Education at (866) 866-3382, option 6.'
const APPLICATION_IN_PROGRESS_MESSAGE = 'An education program is already in progress. Please complete your current education program before applying to another one.'

const cppProgramStatus = path(['education', 'currentCppProgramStatus'])
const mppProgramStatus = path(['education', 'currentMppProgramStatus'])

export const getCppAppStatusMessage = pipe(
  cppProgramStatus,
  cond([
    [appApplied, always(APPLICATION_APPLIED_MESSAGE)],
    [appApproved, always(CPP_APPLICATION_APPROVED_MESSAGE)],
    [appInProgress, always(APPLICATION_IN_PROGRESS_MESSAGE)],
    [T, always('')]
  ])
)

export const getMppAppStatusMessage = pipe(
  mppProgramStatus,
  cond([
    [appApplied, always(APPLICATION_APPLIED_MESSAGE)],
    [appApproved, always(MPP_APPLICATION_APPROVED_MESSAGE)],
    [appInProgress, always(APPLICATION_IN_PROGRESS_MESSAGE)],
    [T, always('')]
  ])
)

export const hasCppOptInStatus = me => {
  const possibleOptedInValues = ['INT', 'PROG', 'APPR', 'APPL', 'DEN', 'EXP']
  return possibleOptedInValues.includes(cppProgramStatus(me))
}

export const getEdPortalLinkByCode = code => {
  const educationPortalLinks = {
    CPP3: 'https://pgaofamerica.instructure.com',
    CPP2: 'http://apps.pga.org/professionals/apps/education/lms/index.cfm?action=cp_req',
    MPP: 'https://apps.pga.org/professionals/apps/education/lms/index.cfm?action=mp_progress',
    MPP3: 'https://pgaofamerica.instructure.com',
    SPP: 'https://pgaofamerica.instructure.com'
  }
  return educationPortalLinks[code]
}

// Given an education.currentProgram object, returns react-router Link props
export const getEducationPortalLink = pipe(
  propOr(null, 'programCode'),
  getEdPortalLinkByCode,
  (link) => isNil(link) ? null : { text: 'Education Portal', link, external: true }
)

export const RegisterForCPPAndRedirect = async ({ client: apolloClient, me }) => {
  const getCareerPath = cond([
    [equals('EXECMGMT'), always('ME3')],
    [equals('GOLFOP'), always('MO3')],
    [equals('INSTRUCT'), always('MT3')],
    [T, always(null)]
  ])
  await redirectToExtranetForRegistration({
    apolloClient,
    id: prop('id', me),
    productCode: pipe(
      path(['education', 'currentCppApplicationInfo', 'careerPathCode']),
      getCareerPath
    )(me)
  })
}

export const careerPathToMppCourseLevelCode = cond([
  [equals('EXECMGMT'), always('ME5')],
  [equals('GOLFOP'), always('MO5')],
  [equals('INSTRUCT'), always('MT5')],
  [T, always(null)]
])

export const redirectToExtranetForRegistration = async ({ apolloClient, id, productCode }) => {
  try {
    const response = await apolloClient.mutate({
      mutation: registerEducationProgramMutation,
      variables: { id, productCode }
    })
    const registerId = pathOr(false, ['data', 'register', 'id'], response)
    if (registerId) {
      window.location = `https://payments.${pgaTld}/?orderSerno=${registerId}`
    }
  } catch (ex) {
    console.log(ex.message)
  }
}

export const renewCpp3AndRedirect = async ({ apolloClient, me }) => {
  await redirectToExtranetForRegistration({
    apolloClient,
    id: prop('id', me),
    productCode: path(['education', 'cppProgramProgress', 'levelCd'], me)
  })
}

export const careerPathLabels = {
  EXECMGMT: 'Executive Management',
  'U-EXECMGMT': 'Executive Management',
  GOLFOP: 'Golf Operations',
  'U-GOLFOP': 'Golf Operations',
  INSTRUCT: 'Teaching & Coaching',
  'U-INSTRUCT': 'Teaching & Coaching'
}

export const isBeforeProgress = (programStatus) => (
  ['INT', 'APPR', 'APPL', 'DEN'].includes(programStatus)
)

export const getCourseLevelCode = ({ programCode, careerPath }) => {
  const careerPathToCourseLevelCode = {
    CPP3: {
      EXECMGMT: 'ME3',
      GOLFOP: 'MO3',
      INSTRUCT: 'MT3'
    },
    MPP3: {
      EXECMGMT: 'ME5',
      GOLFOP: 'MO5',
      INSTRUCT: 'MT5'
    }
  }

  return pathOr(null, [programCode, careerPath], careerPathToCourseLevelCode)
}

export const getCapstoneLevelCode = ({ programCode, careerPath }) => {
  const careerPathToCapstoneLevelCode = {
    CPP3: {
      EXECMGMT: 'ME4',
      GOLFOP: 'MO4',
      INSTRUCT: 'MT4'
    },
    MPP3: {
      EXECMGMT: 'ME6',
      GOLFOP: 'MO6',
      INSTRUCT: 'MT6'
    }
  }

  return pathOr(null, [programCode, careerPath], careerPathToCapstoneLevelCode)
}
