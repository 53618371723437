import { path } from 'ramda'
import { getStatusFor } from '../../utils/curriculum-helper'
import { isAssociate, isStudent } from '../../utils/index'

export const visibilityFilter = link => !link.hidden

export const currentProgram = user => link => {
  const status = (isStudent(user) || isAssociate(user))
    ? getStatusFor(user.type, path(['education', 'currentLevel'], user))
    : ''
  return link.program ? status === link.program[0] : true
}
