import React, { Fragment } from 'react'
import { Paragraph, Tel } from '@pga/pga-component-library'
import { InfoLabel } from '../../../shared'

export default ({ config }) => {
  const { header } = config
  return (
    <>
      <div className='d-flex mb-3'>
        <InfoLabel fs='12px'>DUES | {header}</InfoLabel>
      </div>
      <div className='d-flex flex-column mt-4'>
        <Paragraph fw='normal' size='sm' className='mb-0'>
          Your membership is not current.
        </Paragraph>
        <Paragraph fw='normal' size='sm' className='mb-0'>
          Please contact PGA Membership Services at <Tel phoneNumber='8004742776' />
        </Paragraph>
      </div>
    </>
  )
}
