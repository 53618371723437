import React from 'react'
import { DataLoading } from '@pga/pga-component-library'
import { times } from 'ramda'
import { Description, Footer, Blocks, Block } from './styled'

export default () => (
  <Blocks>
    {
      times(i => (
        <Block key={i}>
          <Description>
            <DataLoading />
            <DataLoading w={150} />
            <DataLoading w={150} />
            <Footer>
              <DataLoading w={50} />
              <DataLoading w={50} />
            </Footer>
          </Description>
        </Block>
      ), 4)
    }
  </Blocks>
)
