import { gql, useQuery } from '@apollo/client'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { COACH_TOOLS_API } from '../../../../apolloClient'
import { BACKGROUND_CHECK_ORDER_FRAGMENT } from '../../../../utils/prospect/withProspectQuery'
import { ModalComp } from '../../../Modal'
import Step1 from './BackgroundCheckSteps/Step1'
import Step2 from './BackgroundCheckSteps/Step2'
import Step3 from './BackgroundCheckSteps/Step3'
import { StatusLink } from './styles'
import { Button } from '@pga/pga-component-library'

export const LINK_TEST_ID = 'background-check-departure-link'

export const BACKGROUND_CHECK_ORDER_QUERY = gql`
  query ProspectQuery {
    currentMember {
      prospect {
        backgroundCheckOrder {
          ${BACKGROUND_CHECK_ORDER_FRAGMENT}
        }
      }
    }
  }
`

export const BackgroundCheckDepartureLink = ({ order, presentation }) => {
  const { backgroundCheckConfirmation } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  })
  const [modalOpen, setModalOpen] = useState(false)
  const [step, setStep] = useState(1)

  useEffect(() => {
    if (backgroundCheckConfirmation) {
      setStep(3)
      setModalOpen(true)
    }
  }, [])

  const handleClose = () => {
    setModalOpen(false)
    if (step === 2) {
      setStep(1)
    }
  }

  const monitorOrderStatus = !!order?.paidAt && !order?.awaitingModeration

  useQuery(BACKGROUND_CHECK_ORDER_QUERY, {
    context: { api: COACH_TOOLS_API },
    skip: !monitorOrderStatus,
    pollInterval: 5000
  })

  const steps = [
    {
      primaryAction: {
        label: 'Purchase Background Check',
        handler: () => setStep(2)
      },
      title: 'Your PGA of America Background Check',
      component: Step1
    },
    {
      title: 'Payment',
      component: Step2
    },
    {
      title: 'Start Your Background Check',
      component: Step3,
      handler: handleClose
    }
  ]

  const {
    primaryAction,
    title,
    component: StepComponent,
    handler: stepHandler
  } = steps[step - 1]

  useEffect(() => {
    if (order?.paidAt) {
      setStep(3)
    }
  }, [order?.paidAt])

  const isPaid = !!order?.paidAt

  const CallToAction = presentation === 'button' ? Button : StatusLink

  if (order?.awaitingModeration) {
    return null
  } else {
    return (
      <>
        <CallToAction
          onClick={() => setModalOpen(true)}
          data-testid={LINK_TEST_ID}
        >
          {isPaid ? 'Continue Background Check' : 'Start Background Check'}
        </CallToAction>

        <ModalComp
          buttonOutline
          headerHandler={handleClose}
          isOpen={modalOpen}
          message={{
            titleLabel: title
          }}
          primaryAction={primaryAction}
          modalBody={
            <StepComponent
              order={order}
              onClose={stepHandler}
            />
          }
        />
      </>
    )
  }
}

export default BackgroundCheckDepartureLink
