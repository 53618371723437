import { Theme } from '@pga/pga-component-library'
import styled from 'styled-components'
import Spinner from '../../../../SignUp/EmailCodeVerification/Spinner'

export const LOADING_TEST_ID = 'background-check-loading-spinner'

export const Text = styled.p`
  color: ${Theme.colors.blueGrey};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 13px;
  text-align: left;
  white-space: break-spaces;
`

export const Loading = () => {
  return (
    <div id='background-check-loading' style={{ display: 'flex', justifyContent: 'center' }} data-test-id={LOADING_TEST_ID}>
      <Spinner />
    </div>
  )
}
