import { gql, useMutation } from '@apollo/client'
import { StatusLink } from './styles'
import { COACH_TOOLS_API } from '../../../../apolloClient'
import { pgaTld } from '../../../utils'

export const CREATE_QUALIFYING_LEVEL_ORDER = gql`
  mutation CreateQualifyingLevelOrder {
    createQualifyingLevelOrder {
      orderNumber
    }
  }
`

export const QualifyingLevel = ({ qualifyingLevel, history }) => {
  const [qualifyingLevelMutation] = useMutation(CREATE_QUALIFYING_LEVEL_ORDER, {
    context: {
      api: COACH_TOOLS_API
    }
  })
  const materialsPurchased = qualifyingLevel?.materialsPurchased
  const handleQualifyingLevel = async () => {
    try {
      const { data } = await qualifyingLevelMutation()
      history.push(`https://payments.${pgaTld}/?orderSerno=${data.createQualifyingLevelOrder.orderNumber}`)
    } catch (error) {
      window.rg4js('send', {
        error,
        tags: ['prospect-dashboard']
      })
    }
  }
  return (
    <>
      {materialsPurchased ? <StatusLink href='https://pgaofamerica.instructure.com/'>Access Education Portal</StatusLink> : <StatusLink onClick={handleQualifyingLevel} data-testid='purchase-qualifying-level'>Purchase Qualifying Level</StatusLink>}
    </>
  )
}

export default QualifyingLevel
