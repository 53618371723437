import React from 'react'
import { times } from 'ramda'
import { CardContainer, Container, FlexBox } from './shared'
import LoadingPlaceholder from '../../LoadingPlaceholder'

export default () => (
  <CardContainer>
    <Container>
      <FlexBox h='100%'>
        {
          times((idx) => (
            <FlexBox key={idx} h='100%' flexDirection='column' justifyContent='space-around'>
              <LoadingPlaceholder h={15} />
              <LoadingPlaceholder h={15} />
              <LoadingPlaceholder h={25} />
            </FlexBox>
          ), 2)
        }
      </FlexBox>
    </Container>
  </CardContainer>
)
