import React, { Fragment } from 'react'
import { isNil, pathOr, prop, cond, always, T } from 'ramda'
import { LinkIcon } from '@pga/pga-component-library'
import { reciprocityContent } from '../../../utils/curriculum-helper'
import { getCurrentReciprocityLevel } from './utils'
import { Title, Header, ExternalLink } from './styled'
import { HeaderDefault } from './Header'
import { isTerminated, isSuspended } from '../../../utils'
import { MessageTerminated, MessageSuspended } from './Message'

const RECIPROCITY_HEADER_TEXT = 'Reciprocity Curriculum'

// This is the view users will see before they've begun their reciprocity journey
export const ReciprocityPreQualifierBox = ({ headerText, memberId, makeNextLevelUrl }) => (
  <>
    <HeaderDefault text={headerText} />
    <Title>
      To get started in the PGA Reciprocity program, please <a href={makeNextLevelUrl({ memberId })} target='_blank' rel='noopener noreferrer'>purchase</a> the qualifying level materials.
    </Title>
  </>
)

ReciprocityPreQualifierBox.defaultProps = {
  headerText: RECIPROCITY_HEADER_TEXT,
  makeNextLevelUrl: ({ memberId }) => `https://apps.pga.org/professionals/apps/education/pgapgm30/checkOut.cfm?entity_id=${memberId}&level_cd=Z0`
}

export const Terminated = ({ headerText = RECIPROCITY_HEADER_TEXT }) => (
  <>
    <HeaderDefault text={headerText} />
    <MessageTerminated />
  </>
)

export const Suspended = ({ headerText = RECIPROCITY_HEADER_TEXT }) => (
  <>
    <HeaderDefault text={headerText} />
    <MessageSuspended />
  </>
)

// This is the view users will see when they have any progress at all in the reciprocity program, even if its complete
export const ReciprocityInProgressBox = ({ headerText, mainContent, nextLevelNumber, purchaseUrl }) => {
  const hasSomethingToPurchase = !isNil(nextLevelNumber) && !isNil(purchaseUrl)

  return (
    <>
      <div className='pb-3'>
        <Header display='inline'>{headerText} </Header>
        {
          hasSomethingToPurchase
            ? <ExternalLink url={purchaseUrl}>| Purchase Level {nextLevelNumber} <LinkIcon className='px-1' /></ExternalLink>
            : null
        }
      </div>
      <Title>
        {mainContent}
        {
          hasSomethingToPurchase
            ? (
              <>
                <ExternalLink url={purchaseUrl} className='pl-1'>Purchase Level {nextLevelNumber}</ExternalLink> now to continue.
              </>
              )
            : null
        }
      </Title>
    </>
  )
}

ReciprocityInProgressBox.defaultProps = {
  headerText: RECIPROCITY_HEADER_TEXT
}

export const ReciprocityInfoBoxComp = ({ me }) => {
  const levels = pathOr([], ['education', 'levels'], me)
  const currentLevel = getCurrentReciprocityLevel(levels)
  const hasNoReciprocityProgress = isNil(prop('currentLevelNumber', currentLevel))

  if (hasNoReciprocityProgress) {
    return <ReciprocityPreQualifierBox memberId={me.id} />
  }

  const { isActive, currentLevelNumber, nextLevelNumber, nextLevelCd } = currentLevel
  const { inProgress, completed, makePurchaseUrl } = reciprocityContent[currentLevelNumber]
  const mainContent = isActive ? inProgress : completed
  const purchaseUrl = (!isNil(nextLevelCd) && !isActive && makePurchaseUrl)
    ? makePurchaseUrl({ memberId: me.id, nextLevelCd })
    : null

  return <ReciprocityInProgressBox {...{ mainContent, nextLevelNumber, purchaseUrl }} />
}

export default (props) => cond([
  [isTerminated, always(<Terminated {...props} />)],
  [isSuspended, always(<Suspended {...props} />)],
  [T, always(<ReciprocityInfoBoxComp {...props} />)]
])(props.me)
