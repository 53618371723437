import React from 'react'
import { Error as DefaultError } from '@pga/pga-component-library'
import ContactMembership from './ContactMembership'

export const ExceptionMessage = () => (
  <div>
    <h3>Something went wrong.</h3>
    <ContactMembership />
  </div>
)

export default class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null
    }
  }

  componentDidCatch (error, info) {
    this.setState({
      error,
      info
    })
  }

  render () {
    const { Error } = this.props
    const { error, info } = this.state

    if (this.state.error) return Error ? <Error error={error} info={info} /> : <DefaultError Message={ExceptionMessage} />
    return this.props.children
  }
}
