import React, { useState } from 'react'
import styled from 'styled-components'
import { Badge, Theme } from '@pga/pga-component-library'
import { PanelStyled, Flex, Wrapper, Title, Text } from './shared'
import ExitModal from './ExitModal'

export default ({ panelInfo, member, panelInfo: { Component } }) => <Component panelInfo={panelInfo} id={member.id} uid={member.universalId} />

export const GenericPanel = ({ panelInfo: { title, panelData, link, Button } }) => (
  <PanelStyled mobilePadded>
    <Flex space>
      <Wrapper><Title>{title}</Title></Wrapper>
      <Wrapper grow><Text>{panelData}</Text></Wrapper>
      <Button to={link}>UPDATE</Button>
    </Flex>
  </PanelStyled>
)

export const GroupedDataPanel = ({ panelInfo: { infoArray, link, Button } }) => (
  <PanelStyled mobilePadded>
    <Flex space>
      <Flex col>
        {infoArray.map(({ title, panelData, link }) => (
          <Flex space key={title}>
            <Wrapper><Title>{title}</Title></Wrapper>
            <Wrapper grow><Text>{panelData}</Text></Wrapper>
          </Flex>
        ))}
      </Flex>
      <Button to={link}>UPDATE</Button>
    </Flex>
  </PanelStyled>
)

const StyledBadge = styled(Badge)`
  margin: 20px 10px 0 10px;
`

export const PrimaryFacility = ({ panelInfo: { mappedFields, panelData, Button } }) => {
  const [isOpen, setIsOpen] = useState({
    changeClassification: false,
    endEmployment: false
  })

  return (
    <PanelStyled>
      <StyledBadge color={Theme.colors.primary}>PRIMARY FACILITY</StyledBadge>
      <Flex>
        <Wrapper>
          {mappedFields.map((item, i) => (
            <Flex flexStart key={i}>
              <Title>{item.label}</Title>
              <Text>{panelData[item.field]}</Text>
            </Flex>
          ))}
        </Wrapper>
        <Wrapper noPadding>
          <Button
            disabled
            options={[{
              label: 'CHANGE CLASSIFICATION',
              onClick: () => null,
              ModalComp: () => <ExitModal isOpen={isOpen.changeClassification} setIsOpen={setIsOpen} link='https://apps.pga.org/professionals/apps/changeForm/index.cfm?f=display&mod_type=classChange' />,
              noBottomBorder: true
            }, {
              onClick: () => null,
              ModalComp: () => <ExitModal isOpen={isOpen.endEmployment} setIsOpen={setIsOpen} link='https://apps.pga.org/professionals/apps/changeForm/index.cfm?f=display&mod_type=formerEmployment' />,
              label: 'END EMPLOYMENT',
              noBottomBorder: false
            }]}
          />
        </Wrapper>
      </Flex>
    </PanelStyled>
  )
}
