import { Col, Row } from 'reactstrap'
import { InputLabel, Required, selectOverride } from './styles'
import { jobTitles, yesNo } from './conditions'
import { Controller } from 'react-hook-form'
import FacilityAlgoliaSearch from '../../components/FacilityAlgoliaSearch'
import { ErrorMessage, RowFormSelect } from './FormComponents'

export default ({ control, setValue, watch, errors }) => {
  const employmentStatus = watch('workInGolf')
  return (
    <>
      <RowFormSelect
        control={control}
        label='Are You Currently Working In Golf?'
        name='workInGolf'
        options={yesNo}
        required
        styles={selectOverride}
      />
      {employmentStatus && (
        <>
          <Row className='mt-4'>
            <Col>
              <InputLabel>Employer Name <Required>*</Required></InputLabel>
              <Controller
                name='facilityName'
                control={control}
                render={({ field }) => (
                  <FacilityAlgoliaSearch
                    field={field}
                    setValue={setValue}
                  />
                )}
              />
              <ErrorMessage errors={errors} name='facilityName' />
            </Col>
          </Row>
          <RowFormSelect
            control={control}
            errors={errors}
            label='Job Title'
            name='jobTitle'
            options={jobTitles}
            required
            styles={selectOverride}
          />
        </>
      )}
    </>
  )
}
