import { always, cond, T } from 'ramda'
import { Theme } from '@pga/pga-component-library'

export const checkStatus = (desiredStatus) => (status) => status === desiredStatus

export const findMostRecentPAT = (playingAbilityTest) => {
  if (!playingAbilityTest || !playingAbilityTest.length) {
    return {}
  }
  const sortedPats = [...playingAbilityTest].sort((a, b) => new Date(b.testDate) - new Date(a.testDate))
  const passedPat = sortedPats.find((test) => checkStatus('PASSED')(test.status))
  const qualifiedPat = sortedPats.find((test) => checkStatus('QUALIFIED')(test.status))
  const newestPat = sortedPats[0]
  return passedPat || qualifiedPat || newestPat
}

export default cond([
  [checkStatus('APPROVED'), always({ text: 'Approved', color: Theme.colors.successGreen })],
  [checkStatus('PASSED'), always({ text: 'Passed', color: Theme.colors.successGreen })],
  [checkStatus('COMPLETED'), always({ text: 'Completed', color: Theme.colors.successGreen })],
  [checkStatus('QUALIFIED'), always({ text: 'Qualified', color: Theme.colors.successGreen })],
  [checkStatus('IN_PROGRESS'), always({ text: 'In Progress', color: Theme.colors.lightBlue })],
  [checkStatus('PENDING'), always({ text: 'Pending', color: Theme.colors.orange })],
  [checkStatus('DENIED'), always({ text: 'Denied', color: Theme.colors.maroon })],
  [checkStatus('FAILED'), always({ text: 'Failed', color: Theme.colors.maroon })],
  [checkStatus('EXPIRED'), always({ text: 'Expired', color: Theme.colors.orange })],
  [checkStatus('DOCUMENTATION_NEEDED'), always({ text: 'Under Review', color: Theme.colors.orange })],
  [checkStatus('IN_REVIEW'), always({ text: 'Under Review', color: Theme.colors.orange })],
  [checkStatus('REGISTERED'), always({ text: 'Registered' })],
  [T, always({ text: 'Not Started' })]
])
