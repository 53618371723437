import React from 'react'
import styled from 'styled-components'
import { Mailto, Tel, Theme } from '@pga/pga-component-library'

const InfoCard = styled.div`
  margin: 5px -15px;
  padding: 10px 15px;
  cursor: pointer;
  background: ${Theme.colors.lightgrey};
`
export const Highlight = styled.span`
  font-family: ${Theme.fonts.Montserrat};
  font-weight: 600;
  line-height: 2;
  color: ${Theme.colors.primary};
`
const InfoLine = styled.div`
  padding: 2px 0;
`

const mentorEmail = 'pgapgmmentor@pgahq.com'
const mentorPhone = '8668663382'

export default () => {
  return (
    <InfoCard>
      <Highlight>Mentor Line</Highlight>
      <InfoLine><Mailto emailAddress={mentorEmail} /></InfoLine>
      <InfoLine><Tel phoneNumber={mentorPhone} /></InfoLine>
    </InfoCard>
  )
}
