import React from 'react'
import styled from 'styled-components'
import { primary, lightgrey, primaryWithOpacity, lightBlue } from '../../../assets/colors'
import { PlayfairDisplay } from '../../../assets/fonts'

export const Icon = styled.i`
  color: grey;
  text-align: center;
  border-radius: 100%;
`

export const FullName = styled.span`
  font-family: ${PlayfairDisplay};
  font-size: ${props => `${(props.small && 20) || 24}px`};
  font-weight: 500;
  color: ${primary};
  margin-right: 10px;
  @media(min-width: 992px) and (max-width: 1199.98px) {
    font-size:20px;
  }
`

export const CertifiedIcon = styled.i.attrs({
  className: 'fa fa-user-o'
})`
  margin: 0 10px;
`

export const CompactProfileContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  padding: 0 10px;
`

export const ProfileDataBox = styled.div`
  padding: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

export const ProfileWidgetBox = styled.div`
  width: 100%;
  background: ${lightgrey};
`

export const FullWidth = styled.div`
  width: 100%;
`

export const CertifiedBox = styled.span`
  display: block;
  font-weight: 500;
`

export const SectionLink = styled.a.attrs(props => ({
  href: props.to,
  target: '_blank'
}))`
  &:hover {
    text-decoration: none;
  }
`

export const PGA_CERTIFIED = 'PGA Certified Professional'
export const PGA_MASTER_PRO = 'PGA Master Professional'
export const PGA_SPECIALIZED = 'PGA Specialized Professional'

export const getCertifiedText = (isMaster, certified, specialized) => {
  if (isMaster) return PGA_MASTER_PRO
  if (certified) return PGA_CERTIFIED
  if (specialized) return PGA_SPECIALIZED
  return null
}

export const Certified = ({ text }) => <CertifiedBox>{text}</CertifiedBox>

const SectionLabel = styled.span`
  display: block;
  color: ${props => props.color || primaryWithOpacity};
`
const MemberIdLabel = styled.span`
  display: block;
  color: ${primaryWithOpacity};
`
export const labelFor = type => ({
  MB: 'Member',
  ST: 'Student',
  AP: 'Member',
  QE: 'Customer'
})[type]
export const Section = ({ section }) => (
  section.website
    ? <SectionLink to={section.website}><SectionLabel color={lightBlue}>{section.name}</SectionLabel></SectionLink>
    : <SectionLabel>{section.name}</SectionLabel>
)

export const Facility = ({ facility }) => <SectionLabel>{facility.name}</SectionLabel>
export const MemberId = ({ type, id }) => <MemberIdLabel>{`${labelFor(type)} ID: #${id}`}</MemberIdLabel>
