import React from 'react'
import { Tile } from '@pga/pga-component-library'

export const getImgUrl = url => `https://news.pga.org${url}`

export default ({
  article: {
    image,
    objectID,
    category,
    title
  }
}) => {
  const tileProps = {
    title,
    link: objectID,
    image: getImgUrl(image),
    label: category
  }
  return (
    <Tile {...tileProps} />
  )
}
