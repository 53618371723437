import React, { Fragment } from 'react'
import { cond } from 'ramda'
import { levels, getInfoFor } from '../../utils/curriculum-helper'
import {
  TimelineWidget,
  TimelineBox,
  LevelConnector,
  Level,
  CURRENT,
  CHECKED,
  EMPTY
} from './styled'

export default ({ current, me }) => {
  const currentInfo = getInfoFor(current, levels, me)
  const getStatusFor = cond([
    [i => i === currentInfo.order, () => CURRENT],
    [i => i > currentInfo.order, () => EMPTY],
    [i => i < currentInfo.order, () => CHECKED]
  ])

  return (
    <TimelineWidget>
      <div>
        <TimelineBox>{
          levels(me).map(({ label }, i) => {
            const status = getStatusFor(i)
            return (
              <Fragment key={label}>
                {i !== 0 ? <LevelConnector status={status} /> : null}
                <Level status={status} label={label} />
              </Fragment>
            )
          })
        }
        </TimelineBox>
      </div>
    </TimelineWidget>
  )
}
