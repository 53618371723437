import { path, pathOr } from 'ramda'
import moment from 'moment'
import { isMember, isAssociate } from '../../../utils'
import { formatDate } from '../../../utils/formatter'

const getDueDate = path(['dues', '0', 'dueDate'])
export const getDefaultDueBalance = me => pathOr('0.00', ['dues', '0', 'duesBalance'], me)
export const getDefaultDueDate = me => (isMember(me) || isAssociate(me)) ? formatDate(getDueDate(me)) : '--/--/--'
export const getDefaultInvoiceNumber = me => pathOr(null, ['dues', '0', 'invoiceNum'], me)
export const hasDueBalance = me => Number(getDefaultDueBalance(me)) > 0

export const getNextCycleDate = (now = new Date()) => {
  const CURRENT_CYCLE = `${now.getFullYear()}-05-01`
  return moment(now).isAfter(CURRENT_CYCLE)
    ? moment(CURRENT_CYCLE).add(1, 'year').format('MM/YYYY')
    : moment(CURRENT_CYCLE).format('MM/YYYY')
}
