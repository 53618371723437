import React, { useRef, useState } from 'react'
import { Theme, Button } from '@pga/pga-component-library'
import { Box, SubTitle, Text, Link, RequirementsText, TooltipTarget, StyledTooltip, DisabledText, Title, StyledModal, ModalTitle, SubText, IntercomLink } from './styles'
import { withProspectQuery } from '../../../../utils/prospect/withProspectQuery'
import calculateRequirementsMet, { affiliateRequirementsPassed, associateRequirementsPassed, isAffiliateRequirementsMet, isAssociateRequirementsMet } from '../../../../utils/prospect/calculateRequirements'
import { withRouter } from 'react-router-dom'
import { PathBoxLoader } from '../WidgetStates'
import { IntercomAPI } from '@pga/react-intercom'

const AFFILIATE_LINK = '/applications/affiliate'
const ASSOCIATE_LINK = '/applications/associate'

export const renderPassedRequirements = (requirements) => (
  Object.entries(requirements).map(([requirement, isPassed], index) => (
    <div key={index}>
      {isPassed ? requirement : <DisabledText>{requirement}</DisabledText>}
    </div>
  ))
)

export const AssociateBox = ({ data, history }) => {
  const [associateTooltipOpen, setAssociateTooltipOpen] = useState(false)
  const passedRequirements = associateRequirementsPassed(data)
  const associateTooltip = useRef(null)
  return (
    <Box bg>
      <div className='container'>
        <SubTitle>Associate <span style={{ color: Theme.colors.deepGrey }}>(Recommended)</span></SubTitle>
        <Text>To become a PGA Member, you'll need to become a registered Associate before completing the PGA Professional Golf Management (PGA PGM)</Text>
        <Text><Link href='https://www.pga.org/membership/associate-program' target='_blank' rel='noreferrer'>Learn More &rarr;</Link></Text>
        <RequirementsText>
          Requirements Met: &nbsp;
          <TooltipTarget ref={associateTooltip} id='associateTooltip'>
            {calculateRequirementsMet(passedRequirements)}
          </TooltipTarget>
          <StyledTooltip
            isOpen={associateTooltipOpen}
            placement='bottom'
            autohide={false}
            target={associateTooltip}
            toggle={() => setAssociateTooltipOpen(!associateTooltipOpen)}
          >
            {renderPassedRequirements(passedRequirements)}
          </StyledTooltip>
        </RequirementsText>
        <Button onClick={() => history.push(ASSOCIATE_LINK)} disabled={!isAssociateRequirementsMet(data)}>
          {isAssociateRequirementsMet(data) ? 'Become Associate' : 'Awaiting Requirements'}
        </Button>
      </div>
    </Box>
  )
}

export const AffiliateModal = ({ isOpen, handleModalOpen, history }) => {
  return (
    <StyledModal isOpen={isOpen} toggle={handleModalOpen} centered size='lg'>
      <div className='modal-body text-center'>
        <ModalTitle>Are You Sure You Want to Become an Affiliate?</ModalTitle>
        <SubText>You are only one step away from becoming an Associate. Are you sure you'd like to continue as an Affiliate?</SubText>
        <Button onClick={handleModalOpen}>Continue As Associate</Button> &nbsp;
        <Button onClick={() => history.push(AFFILIATE_LINK)} outline>Continue As Affiliate</Button>
      </div>
    </StyledModal>
  )
}

export const AssociateRecommendationModal = ({ isOpen, handleModalOpen }) => {
  return (
    <StyledModal isOpen={isOpen} toggle={handleModalOpen} centered size='lg'>
      <div className='modal-body text-center'>
        <ModalTitle>Are You Sure You Want to Become an Affiliate?</ModalTitle>
        <SubText>You're eligible to become an Associate, are you sure you'd like to continue as an Affiliate?</SubText>
        <SubText>
          If you're not sure which path is best for you, <IntercomLink onClick={() => IntercomAPI('show')}>contact membership services</IntercomLink>
        </SubText>
        <Button href={ASSOCIATE_LINK}>Continue As Associate</Button> &nbsp;
        <Button href={AFFILIATE_LINK} outline>Continue As Affiliate</Button>
      </div>
    </StyledModal>
  )
}

export const AffiliateBox = ({ data, history }) => {
  const [affiliateTooltipOpen, setAffiliateTooltipOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const handleModalOpen = () => setIsOpen(!isOpen)
  const affiliateTooltip = useRef(null)
  return (
    <Box>
      <div className='container'>
        <SubTitle>Affiliate</SubTitle>
        <Text>Want to become a PGA Member, but not yet working in golf? Begin in the Affiliate Program, to eventually become an PGA Associate.</Text>
        <Text><Link href='https://www.pga.org/membership/affiliate-program' target='_blank' rel='noreferrer'>Learn More &rarr;</Link></Text>
        <RequirementsText>
          Requirements Met: &nbsp;
          <TooltipTarget ref={affiliateTooltip} id='affiliateTooltip'>
            {calculateRequirementsMet(affiliateRequirementsPassed(data))}
          </TooltipTarget>
          <StyledTooltip
            isOpen={affiliateTooltipOpen}
            placement='bottom'
            autohide={false}
            target={affiliateTooltip}
            toggle={() => {
              setAffiliateTooltipOpen(!affiliateTooltipOpen)
            }}
          >
            {renderPassedRequirements(affiliateRequirementsPassed(data))}
          </StyledTooltip>
        </RequirementsText>
        <Button
          onClick={handleModalOpen} disabled={!isAffiliateRequirementsMet(data)} outline={isAffiliateRequirementsMet(data)}
        >{isAffiliateRequirementsMet(data) ? 'Become Affiliate' : 'Awaiting Requirements'}
        </Button>
        {isAssociateRequirementsMet(data)
          ? <AssociateRecommendationModal isOpen={isOpen} handleModalOpen={handleModalOpen} />
          : <AffiliateModal isOpen={isOpen} handleModalOpen={handleModalOpen} history={history} />}
      </div>
    </Box>
  )
}

export const PathStack = ({ data, history }) => (
  <>
    <div className='row'>
      <div className='col'>
        <AssociateBox data={data} history={history} />
      </div>
      <div className='col'>
        <AffiliateBox data={data} history={history} />
      </div>
    </div>
  </>
)

const PathBox = ({ currentMember, loading, history }) => {
  if (loading) return <PathBoxLoader />
  return (
    <div className='container'>
      <Title marginTop='20px'>Path to Membership</Title>
      <span>Welcome to Path to Membership! You must complete all of the below requirements in no particular order to apply for the Associate program.
        In order to apply for the Affiliate program, you only need to complete the Background Check and Qualifying Level.
      </span>
      <Title marginTop='10px' color={Theme.colors.primary}>Choose Your Path</Title>
      <PathStack data={currentMember} history={history} />
    </div>
  )
}

export default withRouter(withProspectQuery(PathBox))
