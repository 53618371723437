import styled from 'styled-components'
import { FormGroup, Input } from 'reactstrap'
import { Button, Theme } from '@pga/pga-component-library'

export const StyledButton = styled(Button)`
  width: 100%;
  font-size: 1rem;
  margin-top: 25px;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  ${Theme.media.smDown} {
    margin-top: 15px;
  }
`
export const StyledInput = styled(Input)`
  padding: 20px 20px;
  font-size: 14px;
`

export const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 25px;
`

export const DisplayError = styled.p`
  color: ${Theme.colors.danger};
`

export const SmallError = styled.small`
  color: ${Theme.colors.danger};
`

export const TermsOfServiceWrapper = styled.div`
  padding-top: 20px;
`

export const StyledLabel = styled.label`
  display: inline;
`
