import React, { useContext } from 'react'

import FeatureFlagContext from './FeatureFlagContext'

// React hook for pulling feature flags into a function component
const useFeatureFlags = (flagKeys = []) => {
  const featureFlags = useAllFeatureFlags()

  return flagKeys.reduce((agg, key) => {
    return [...agg, featureFlags[key]]
  }, [])
}

const useAllFeatureFlags = () => {
  return useContext(FeatureFlagContext)
}

const ShowFeatureWithFlags = props => {
  const [canUse] = useFeatureFlags([props.flag])
  const children = canUse ? props.children : null

  return <>{children}</>
}

export { useFeatureFlags, useAllFeatureFlags, ShowFeatureWithFlags }
