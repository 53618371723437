import React, { Fragment } from 'react'
import { ChartsBox } from '../Styles/Shared.styles'
import { getPDRCredits } from '../config'
import { MSRCountBoxCompact, Completed } from '../Styles/CompactView.styles'

export const PDRCount = ({ completed, total, color }) => (
  <MSRCountBoxCompact color={color}>
    <Completed color={color}>{completed}</Completed> of {total}
  </MSRCountBoxCompact>
)

export default ({ me }) => {
  return (
    <>
      <ChartsBox>
        {getPDRCredits(me).map((pdr, index) => (
          <PDRCount key={index} completed={pdr.earned} total={pdr.required} color={pdr.color} />
        ))}
      </ChartsBox>
    </>
  )
}
