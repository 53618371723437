import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { TitleBar, ProgressTitle, Title } from './shared'
import { Theme, BackArrowIcon, LinkIcon, Button } from '@pga/pga-component-library'
import { renewCpp3AndRedirect } from './sharedUtils'

export const SectionTitleBar = ({
  title,
  mdHeight,
  gray,
  fontPrimary,
  bold,
  fontSmall
}) => (
  <TitleBar mdHeight={mdHeight} gray={gray}>
    <ProgressTitle fontPrimary={fontPrimary} bold={bold} fontSmall={fontSmall}>{title}</ProgressTitle>
  </TitleBar>
)

export const Wrapper = styled(Link)`
  margin: 30px 0;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  &:hover {
    text-decoration: none;
    color: ${Theme.colors.gold};
  }
`

const BackArrow = styled(BackArrowIcon)`
  color: ${Theme.colors.primary};
  align-self: center;
`

export const GoBack = ({ text, url }) => (
  <Wrapper to={url}>
    <BackArrow />
    <Title noMargin>{text}</Title>
  </Wrapper>
)

export const HalfCol = styled.div`
  flex-basis: 50%;
  height: 200px;
  border: 1px solid ${Theme.colors.darkgrey};
  ${props => props.noBorder && 'border-bottom: none;'}
  padding: 20px;
`

export const InfoBox = ({ title, ContentComp, noBorder }) => (
  <HalfCol noBorder={noBorder}>
    <Title noMargin>{title}</Title>
    <ContentComp />
  </HalfCol>
)

export const FixedWidthButtonComp = styled(Button)`
  width: ${props => props.wide ? '300px' : '100px'};
  margin: 10px 0;
  padding: 12px 0;
  text-align: center;
`

export const FixedWidthButton = ({ wide, children, ...props }) => (
  <FixedWidthButtonComp wide={(wide) ? 1 : undefined} {...props}>{children}</FixedWidthButtonComp>
)

export const StyledRenewLink = styled.span`
  font-size: 14px;
  color: ${Theme.colors.lightBlue};
  cursor: pointer;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`

export const RenewLinkBase = (renewFn) => ({ apolloClient, me, className, text = 'Renew Subscription' }) => {
  const handleClick = (e) => {
    e.preventDefault()
    renewFn({ apolloClient, me })
  }
  return (
    <StyledRenewLink onClick={handleClick} className={className}>
      {text}<LinkIcon />
    </StyledRenewLink>
  )
}

export const RenewCppLink = RenewLinkBase(renewCpp3AndRedirect)

export const SubmitLoaderIcon = ({ isSubmitting }) => isSubmitting ? <i className='fa fa-spinner fa-spin ml-2' aria-hidden='true' /> : null
