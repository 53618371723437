import React from 'react'
import { CardBox, InfoFrame, FlexColumn, InfoLabel, DuesButtons } from '../../shared'
import { white, lightBlue } from '../../../../assets/colors'
import { CARD_HEIGHT } from '.'

const ErrorLoadingDues = ({ refetch }) => (
  <CardBox color={white} minHeight={CARD_HEIGHT}>
    <InfoFrame>
      <FlexColumn>
        <InfoLabel>This widget failed to load. Please refresh.</InfoLabel>
        <br />
        <DuesButtons onClick={() => refetch()} width='100%' color={white} bgColor={lightBlue}>REFRESH</DuesButtons>
      </FlexColumn>
    </InfoFrame>
  </CardBox>
)

ErrorLoadingDues.displayName = 'ErrorLoadingDues'

export default ErrorLoadingDues
