import React from 'react'
import { nth, last, compose, mergeRight, includes } from 'ramda'
import { appserverTld, isAffiliate } from '../utils/index'

export const CongratulationsGoApplyLink = ({ requirementsUrl, applicationUrl }) => (
  <div>
    Congratulations! Your coursework is complete. You are now eligible for election into PGA Membership as long as <a href={requirementsUrl}>all other requirements</a> have been met.
    Please <a href={applicationUrl} target='_blank' rel='noopener noreferrer'>click here</a> to access, complete and submit your membership application.
  </div>
)

export const AffiliateMessage = ({ level }) => (
  <div className='pl-1'>
    Congratulations on completing your Level {level} requirements.
    Please <a href='/applications/associate'>click here to register for the associate program</a> to continue your journey.
  </div>
)

CongratulationsGoApplyLink.defaultProps = {
  requirementsUrl: 'https://www.pga.org/membership/associate-program/',
  applicationUrl: '/applications/membership'
}

export const levelsTag = ['F0', 'F1', 'FE2', 'FE3', 'FO2', 'FO3', 'FT2', 'FT3', 'N0', 'U0', 'A0', 'S0', 'N1', 'U1', 'A1', 'S1', 'N2', 'U2', 'AE2', 'AO2', 'AT2', 'S2', 'N3', 'U3', 'AE3', 'AO3', 'AT3', 'S3', 'V0', 'V1', 'V2', 'V3', 'Z0', 'Z1', 'ZE2', 'ZE3', 'ZO2', 'ZO3', 'ZT2', 'ZT3']

export const levels = me => [
  {
    label: 'Qualifying',
    title: {
      inProgress: 'You are on your way to PGA Membership! Visit your WEKits Transcript for details on your remaining requirements.',
      completed: 'Congratulations on completing the Qualifying Level.',
      Contact: () => <div className='d-inline pl-1'>Before signing up for the PGM Associate Program, please <a href='https://www.pga.org/membership/associate-program/'>review program entry requirements.</a></div>
    }
  },
  {
    label: 'Level 1',
    title: {
      inProgress: 'Great Job! Visit your WEKits Transcript for details on your remaining requirements.',
      completed: 'Congratulations! Level 1 curriculum has been completed.'
    },
    purchaseLink: id => `https://apps.pga.org/professionals/apps/education/pgapgm30/level2reg.cfm?entity_id=${id}`
  },
  {
    label: 'Level 2',
    title: {
      inProgress: 'Awesome! Visit your WEKits Transcript for details on your remaining requirements.',
      completed: 'Congratulations! Level 2 curriculum has been completed.'
    },
    purchaseLink: id => `https://apps.pga.org/pgapgm/level3Reg.cfm?entity_id=${id}`
  },
  {
    label: 'Level 3',
    title: {
      inProgress: 'Almost there! Visit your WEKits Transcript for details on your remaining requirements.',
      completed: <CongratulationsGoApplyLink />
    }
  }
]

export const levelsPGM3 = me => [
  {
    label: 'Qualifying',
    title: {
      inProgress: 'You are on your way to PGA Membership! Complete your Qualifying Test to enter into the PGM Associate Program.',
      completed: 'Congratulations on completing your Qualifying Test.',
      Contact: () => <div className='d-inline pl-1'>Before signing up for the PGM Associate Program, please <a href='https://www.pga.org/membership/associate-program/'>review program entry requirements.</a></div>
    }
  },
  {
    label: 'Level 1',
    title: {
      inProgress: 'Good Job! As you complete your level requirements, we’ll provide the next steps for you to advance.',
      completed: isAffiliate(me) ? <AffiliateMessage level='1' /> : 'Congratulations! Level 1 curriculum has been completed.'
    },
    ...!isAffiliate(me) && {
      purchaseLink: id => `https://${appserverTld}/professionals/apps/education/pgapgm30/level2reg.cfm?entity_id=${id}`
    }
  },
  {
    label: 'Level 2',
    title: {
      inProgress: 'Good Job! As you complete your level requirements, we’ll provide the next steps for you to advance.',
      completed: isAffiliate(me) ? <AffiliateMessage level='2' /> : 'Congratulations! Level 2 curriculum has been completed.'
    },
    ...!isAffiliate(me) && {
      purchaseLink: id => `https://${appserverTld}/pgapgm30/level3Reg.cfm?entity_id=${id}`
    }
  },
  {
    label: 'Level 3',
    title: {
      inProgress: 'Good Job! As you complete your level requirements, we’ll provide the next steps for you to advance.',
      completed: <CongratulationsGoApplyLink />
    }
  }
]

export const levelsPGM31 = me => [
  {
    label: 'Qualifying',
    title: {
      inProgress: 'You are on your way to PGA Membership! Complete your Qualifying Level to enter into the PGM Associate Program.',
      completed: 'Congratulations on completing your Qualifying Level.',
      Contact: () => <div className='d-inline pl-1'>Before signing up for the PGM Associate Program, please <a href='https://www.pga.org/membership/associate-program/'>review program entry requirements.</a></div>
    }
  },
  {
    label: 'Level 1',
    title: {
      inProgress: 'Good Job! As you complete your level requirements, we’ll provide the next steps for you to advance.',
      completed: isAffiliate(me) ? <AffiliateMessage level='1' /> : 'Congratulations! Level 1 curriculum has been completed.'
    },
    ...!isAffiliate(me) && {
      purchaseLink: id => `https://${appserverTld}/professionals/apps/education/pgapgm31/level2reg.cfm?entity_id=${id}`
    }
  },
  {
    label: 'Level 2',
    title: {
      inProgress: 'Good Job! As you complete your level requirements, we’ll provide the next steps for you to advance.',
      completed: isAffiliate(me) ? <AffiliateMessage level='2' /> : 'Congratulations! Level 2 curriculum has been completed.'
    },
    ...!isAffiliate(me) && {
      purchaseLink: id => `https://${appserverTld}/professionals/apps/education/pgapgm31/level3reg.cfm?entity_id=${id}`
    }
  },
  {
    label: 'Level 3',
    title: {
      inProgress: 'Good Job! As you complete your level requirements, we’ll provide the next steps for you to advance.',
      completed: <CongratulationsGoApplyLink />
    }
  }
]

export const reciprocityContent = [
  {
    inProgress: 'You are on your way to PGA Membership! Complete your Qualifying Test before proceeding with Level 1 of the Reciprocity program.',
    completed: 'Congratulations on completing your Qualifying level! You are eligible to purchase Level 1 of the Reciprocity program.',
    makePurchaseUrl: ({ memberId }) => `https://apps.pga.org/professionals/apps/education/pgapgm30/checkOut.cfm?entity_id=${memberId}&level_cd=Z1`
  },
  {
    inProgress: 'Good Job! As you complete your level requirements, we’ll provide the next steps for you to advance.',
    completed: 'Congratulations! Level 1 curriculum has been completed.',
    makePurchaseUrl: ({ memberId }) => `https://apps.pga.org/professionals/apps/education/pgapgm30/level2reg.cfm?entity_id=${memberId}`
  },
  {
    inProgress: 'Good Job! As you complete your level requirements, we’ll provide the next steps for you to advance.',
    completed: 'Congratulations! Level 2 curriculum has been completed.',
    makePurchaseUrl: ({ memberId, nextLevelCd }) => `https://apps.pga.org/professionals/apps/education/pgapgm30/checkOut.cfm?entity_id=${memberId}&level_cd=${nextLevelCd}`
  },
  {
    inProgress: 'Good Job! As you complete your level requirements, we’ll provide the next steps for you to advance.',
    completed: <CongratulationsGoApplyLink />
  }
]

const status = {
  ST: {
    U: '2',
    S: '3',
    V: '31'
  },
  AP: {
    N: '2',
    A: '3',
    F: '31'
  },
  PQ: {
    N: '2',
    A: '3',
    Z: '3',
    F: '31'
  },
  QE: {
    N: '2',
    A: '3',
    Z: '3',
    F: '31'
  }
}

const missingLevel = {
  label: 'Qualifying.',
  title: 'Current Level is Incorrect.'
}

export const getInfoFor = (tag, programLevels = levels, me) => includes(tag, levelsTag)
  ? compose(
    n => mergeRight(nth(n, programLevels(me)), { order: Number(n) }),
    last
  )(tag)
  : missingLevel

export const getStatusFor = (userType, tag) => includes(tag, levelsTag)
  ? status[userType][tag[0]]
  : '3'

export const getInfoForPGM3 = (tag, me) => getInfoFor(tag, levelsPGM3, me)
export const getInfoForPGM31 = (tag, me) => getInfoFor(tag, levelsPGM31, me)
