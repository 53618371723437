import React, { useState } from 'react'
import styled from 'styled-components'
import { withCookies } from 'react-cookie'
import { Theme } from '@pga/pga-component-library'
import { StyledCheckbox } from '../../EditProfile/InputChanges/pieces/Checkbox'

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const HiddenCheckbox = styled.input.attrs({
  type: 'checkbox'
})`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const Text = styled.p`
  font-family: ${Theme.colors.Montserrat};
  font-size: 12px;
  color: ${Theme.colors.primary};
  margin: 0;
`

const useHandleCookie = (id, cookies, cookieName) => {
  const [toggleOpt, setOpt] = useState(false)
  const handleCookie = () => {
    cookies.set(cookieName, JSON.stringify({ id, optedOut: !toggleOpt }), { path: '/' })
    setOpt(!toggleOpt)
  }
  return [toggleOpt, handleCookie]
}

export const OptOutCheckbox = ({ id = 0, cookies, cookieName }) => {
  const [toggleOpt, handleCookie] = useHandleCookie(id, cookies, cookieName)
  return (
    <CheckboxContainer onClick={() => handleCookie(id, cookies)}>
      <HiddenCheckbox />
      <StyledCheckbox checked={toggleOpt}>
        <Icon viewBox='0 0 24 24'>
          <polyline points='20 6 9 17 4 12' />
        </Icon>
      </StyledCheckbox>
      <Text>Do not show again</Text>
    </CheckboxContainer>
  )
}

export default withCookies(OptOutCheckbox)
