import React from 'react'
import {
  FullName,
  ProfileDataBox,
  ProfileWidgetBox,
  FullWidth
} from './shared'
import {
  ProfileImg,
  ProfileImgFrame
} from '../ProfileImage'
import defaultImg from '../../../assets/images/profileImg.svg'
import { Button } from '../../Button'

export default ({ reload, refetch }) => (
  <ProfileWidgetBox>
    <ProfileImgFrame>
      <ProfileImg photo={defaultImg} />
    </ProfileImgFrame>
    <ProfileDataBox>
      <div>
        <FullName>Failed to load</FullName>
      </div>
      <br />
      <FullWidth>
        <Button onClick={() => reload(refetch)} style={{ width: '100%' }}>REFRESH</Button>
      </FullWidth>
    </ProfileDataBox>
  </ProfileWidgetBox>
)
