import React from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'

export const Title = styled.div`
  color: ${Theme.colors.primary};
  font-family: ${Theme.fonts.PlayfairDisplay};
  font-size: 18px;
  font-weight: bold;
`

export const TitleLarge = styled(Title)`
  text-align: center;
  font-size: 24px;
  padding-top: 24px;
`

export const Header = styled.h4`
  font-family: ${Theme.fonts.Montserrat};
  font-size: 12px;
  color: ${Theme.colors.primaryWithOpacity};
  padding-bottom: 15px;
  text-transform: uppercase;
  display: ${props => props.display || 'block'};
  a {
    font-size: 12px;
  }
`

export const LinkLabel = styled.span`
  color: ${Theme.colors.lightBlue};
  font-weight: 500;
  font-size: ${props => props.fs || '12px'};
  cursor: pointer;
`

export const ExternalLink = ({ url, children, className }) => (
  <a href={url} target='_blank' rel='noopener noreferrer' className={className}>{children}</a>
)
