import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import { CardText } from 'reactstrap'
import Ellipsis from 'react-lines-ellipsis'
import EllipsisModalWrapper from '../../utils/EllipsisModal'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { primary, lightBlue } from '../../../assets/colors'

const LinesEllipsis = responsiveHOC()(Ellipsis)

const Title = styled(CardText)`
  font-size: 12px;
  font-weight: 600;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: ${props => props.color};
  margin-bottom: 5px;
  width: 100%;
`

const TitleBar = ({ text }) =>
  <Title tag='div' color={primary}>{text}</Title>

const AnnouncementBox = styled.div`
  margin: 15px 0;
  margin-top: 0;
  padding: 5px;
`

const ExcerptBox = styled(CardText)`
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 7px;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: ${props => props.color};
  width: 100%;
`

const ReadMoreBox = styled.a`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.color};
`
const Toggle = styled.span`
  color: ${Theme.colors.lightBlue};
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 10px;
`

export const EllipseReadMoreModal = (title, message) => {
  const [open, onToggleModal] = useState(false)
  return (
    <>
      <Toggle title={message} onClick={() => onToggleModal(!open)}>... Read More</Toggle>
      <EllipsisModalWrapper {...{ open, onToggleModal, title, message }} />
    </>
  )
}

export const buildAnnouncement = ({
  title,
  message,
  label,
  link
}) => (
  <AnnouncementBox key={title}>
    <TitleBar text={title} />
    <ExcerptBox color={primary} tag='div'>
      <LinesEllipsis
        text={message}
        title={message}
        component='p'
        maxLine='3'
        ellipsis={EllipseReadMoreModal(title, message)}
        trimRight
      />
    </ExcerptBox>
    <ReadMoreBox href={link} target='_blank' rel='noopener noreferrer' color={lightBlue}>{label}</ReadMoreBox>
  </AnnouncementBox>
)

export default ({ items }) => items.map(buildAnnouncement)
