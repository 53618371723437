import { COACH_TOOLS_API } from '../../apolloClient'
import { useQuery, gql } from '@apollo/client'

export const BACKGROUND_CHECK_ORDER_FRAGMENT = `
  id
  url
  status
  paidAt
  awaitingModeration
`
export const PROSPECT_QUERY = gql`
  query ProspectQuery {
    currentMember {
      prospect {
        playingAbilityTest {
          expiredAt
          score
          status
          testDate
        }
        qualifyingLevel {
          status
          materialsPurchased
          startDate
          expiredAt
        }
        backgroundCheck {
          createdAt
          status
          screenedAt
          expiredAt
        }
        backgroundCheckOrder {
          ${BACKGROUND_CHECK_ORDER_FRAGMENT}
        }
      }
    }
  }
`

export const withProspectQuery = (Component) => (props) => {
  const { error, data, refetch, networkStatus } = useQuery(PROSPECT_QUERY, {
    context: {
      api: COACH_TOOLS_API
    },
    notifyOnNetworkStatusChange: true
  })
  const loading = !data && !error
  const { currentMember } = data || {}
  return (
    <Component
      {...{ currentMember, loading, error, refetch, networkStatus, ...props }}
    />
  )
}
