import React from 'react'
import { isEmpty, isNil, and } from 'ramda'
import { PanelStyled, Flex, Wrapper, Title, Text } from './shared'

export default ({ panelInfo: { facilities, Button } }) => {
  return (and(!isEmpty(facilities), !isNil(facilities)))
    ? (
        facilities.map(({ facility, key }) => (
          <PanelStyled key={key}>
            <Flex>
              <Wrapper>
                {facility.map(({ label, field }) => (
                  <Flex flexStart key={field}>
                    <Title>{label}</Title>
                    <Text>{field}</Text>
                  </Flex>
                ))}
              </Wrapper>
              <Button
                disabled
                options={[{
                  link: 'http://apps.pga.org/professionals/apps/changeForm/index.cfm?f=display&mod_type=classChange',
                  label: 'CHANGE CLASSIFICATION'
                }, {
                  link: 'https://apps.pga.org/professionals/apps/changeForm/index.cfm?f=display&mod_type=formerEmployment',
                  label: 'END EMPLOYMENT'
                }]}
              />
            </Flex>
          </PanelStyled>
        )))
    : null
}
