import { Row } from 'reactstrap'
import React, { useState } from 'react'
import {
  Button,
  ContactMembership,
  FormError,
  ModalLink,
  StyledLink,
  selectOverride
} from './styles'
import { genderOptions, howTheyHeardOptions, reasons, vetStatuses, yesNo } from './conditions'

import { IntercomAPI } from '@pga/react-intercom'
import { StyledFormGroup } from '../../components/SignUp/CreateAccount/SignUpForm/styles'
import EmploymentStatus from './EmploymentStatus'
import { DisclaimerModal } from './DisclaimerModal'
import { FormSelect, RowFormSelect } from './FormComponents'

const SubmissionError = () => (
  <>
    <FormError red className='mb-1'>
      There was an error submitting the form. Please try again.
    </FormError>
    <FormError>
      If the problem persists, please <ContactMembership onClick={() => IntercomAPI('show')} data-testid='intercom-link'>Contact Membership Services</ContactMembership>
    </FormError>
  </>
)

const QualifyingQuestions = ({ control, hasError, handleSubmit, isValid, isSubmitting, setStep, setValue, watch, errors }) => {
  const [vetStatus, setVetStatus] = useState(null)
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false)
  const handleDisclaimerOpen = () => setIsDisclaimerOpen(!isDisclaimerOpen)
  return (
    <>
      <StyledFormGroup>
        <Row>
          <FormSelect
            ariaLabel='Gender'
            control={control}
            errors={errors}
            label='Gender'
            name='gender'
            options={genderOptions}
            placeholder='Gender'
            styles={selectOverride}
          >
            <ModalLink onClick={handleDisclaimerOpen} data-testid='disclaimer-link'>Why We Collect This</ModalLink>
            <DisclaimerModal {...{ isDisclaimerOpen, handleDisclaimerOpen }} />
          </FormSelect>
          <FormSelect
            ariaLabel='Are You a Veteran?'
            control={control}
            errors={errors}
            name='isVeteran'
            label='Are You a Veteran?'
            options={yesNo}
            styles={selectOverride}
            onChange={(selectedOption) => setVetStatus(selectedOption.value)}
          />
        </Row>
        {vetStatus && (
          <RowFormSelect
            control={control}
            errors={errors}
            label='Which Best Describes You?'
            name='militaryStatus'
            options={vetStatuses}
            styles={selectOverride}
          />
        )}
        <EmploymentStatus {...{ control, setValue, watch, errors }} />
        <RowFormSelect
          control={control}
          errors={errors}
          label='Reason For Becoming a Member'
          name='reasonForBecomingMember'
          options={reasons}
          required
          styles={selectOverride}
        />
        <RowFormSelect
          control={control}
          errors={errors}
          label='How did you hear about us?'
          name='howTheyHeard'
          options={howTheyHeardOptions}
          required
          styles={selectOverride}
        />
      </StyledFormGroup>
      {hasError ? <SubmissionError /> : null}
      <Button
        data-testid='submit-btn'
        onClick={handleSubmit}
        disabled={!isValid || isSubmitting}
        type='submit'
        name='submit'
        className={hasError ? 'mt-1' : 'mt-3'}
      >
        {isSubmitting ? 'Submitting...' : 'Submit'}
      </Button>
      <StyledLink onClick={() => setStep(1)}>&larr; Go Back</StyledLink>
    </>
  )
}

export default QualifyingQuestions
