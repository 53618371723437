import React, { useState, Fragment } from 'react'
import styled from 'styled-components'
import { ModalFooter } from 'reactstrap'
import { Paragraph, Tel } from '@pga/pga-component-library'
import { redirectToLogout } from '../../lib/auth'
import { Button } from '../Button'
import { Modal, ModalBody, Title } from '../Modal'
import { path } from 'ramda'
import { pgaComTld } from '../utils'
import './modal.css'

const ModalWrapper = styled(Modal)`
  .modal-content {
    border-radius: 0;
    border: none;
    @media screen and (max-width: 575.98px) {
      text-align: center;
    }
  }
  .modal-footer {
    width: 100%;
    justify-content: center;
    padding-top: 1.5rem;
    padding-bottom: 0;
  }
`

const Body = styled(ModalBody)`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Telephone = styled(Tel)`
  font-size: 24px;
  margin-bottom: 24px;
`

const MEMBERSHIP_NUMBER = '(800) 474-2776'

export default ({ me }) => {
  const [open] = useState(true)
  const duesTermination = path(['termination', 'terminateCd'])(me) === 'TN'

  return (
    <>
      <ModalWrapper
        isOpen={open}
        toggle={() => {}}
        size='md'
        centered
      >
        {duesTermination
          ? (
            <Body>
              <Title className='mb-4'>Membership Not Current</Title>
              <Paragraph fw='normal' className='mb-2 ml-4'>
                Pay your outstanding dues to re-establish your membership.
              </Paragraph>
              <Paragraph fw='normal' className='ml-4'>
                For additional support contact PGA Membership Services at:
              </Paragraph>
              <Telephone phoneNumber={MEMBERSHIP_NUMBER} />
              <ModalFooter>
                <Button href={`https://${pgaComTld}/member/dues/overview`} target='_blank' rel='noopener noreferrer' className='m-2'>Pay Dues Now</Button>
                <Button color='primary' onClick={() => redirectToLogout()}>Return to Public Content</Button>
              </ModalFooter>
            </Body>
            )
          : (
            <Body>
              <Title className='mb-4'>Membership Not Current</Title>
              <Paragraph fw='normal' className='mb-2'>
                Your membership is <Paragraph fw='normal' className='d-inline text-danger'><u>not current.</u></Paragraph>
              </Paragraph>
              <Paragraph fw='normal'>
                Please contact PGA Membership Services at:
              </Paragraph>
              <Telephone phoneNumber={MEMBERSHIP_NUMBER} />
              <ModalFooter>
                <Button color='primary' onClick={() => redirectToLogout()}>Return to Public Content</Button>
              </ModalFooter>
            </Body>
            )}
      </ModalWrapper>
    </>
  )
}
