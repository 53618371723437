import React, { useState } from 'react'
import styled from 'styled-components'
import { PanelStyled, Flex, Text } from './shared'
import ExitModal from './ExitModal'
import { ActionBtn } from './ActionButton'

const addEmploymentLink = 'https://apps.pga.org/professionals/apps/changeForm/index.cfm?f=display&mod_type=newEmployment&pass=1'

const Wrapper = styled.div`
  align-self: self-end;
  padding: 10px;
`

export default () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <PanelStyled>
      <Flex>
        <Wrapper>
          <Text>Click here to add additional facilities to your record</Text>
        </Wrapper>
        <ActionBtn disabled outline onClick={() => setIsOpen(true)}>ADD NEW FACILITY</ActionBtn>
        <ExitModal isOpen={isOpen} setIsOpen={setIsOpen} link={addEmploymentLink} />
      </Flex>
    </PanelStyled>
  )
}
