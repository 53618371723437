import React, { useContext, useState, useEffect } from 'react'
import initializeFeatures from './initializeFeatures'
import FeatureFlagContext from './FeatureFlagContext'
import { withAuthProvider } from '../auth'
import { LoadingComponent } from '@pga/pga-component-library/lib/components/LoadingComponent'
import { UserContext } from '../UserProvider/'

const FeatureFlagProvider = withAuthProvider(props => {
  const [featureFlags, setFeatureFlags] = useState()
  const { App, externalId } = props

  useEffect(() => {
    const getFeatures = async () => {
      const flags = await initializeFeatures({ externalId })
      setFeatureFlags(flags)
    }
    getFeatures()
  }, [externalId])

  if (featureFlags === undefined) {
    return <LoadingComponent />
  }

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      <App {...props} />
    </FeatureFlagContext.Provider>
  )
})

const withFeatureFlagProvider = App => props => {
  const { user } = useContext(UserContext)

  return <FeatureFlagProvider externalId={user?.externalId} App={App} {...props} />
}

export default withFeatureFlagProvider
