import React from 'react'
import { getMeData } from '../../../utils'
import { getDues } from '../../../queries/member'
import MembershipDues from '../LegacyMembershipDues/MembershipDues'
import LoadingDues from '../LegacyMembershipDues/LoadingDues'
import ErrorLoadingDues from '../LegacyMembershipDues/ErrorLoadingDues'
import WrapWithFetch from '../../WrapWithFetch'

export const MembershipFinanceWrapper = ({
  me,
  loading,
  isFetching,
  error,
  refetch,
  reload
}) => {
  if (loading || isFetching) {
    return <LoadingDues />
  } else if (error) {
    return <ErrorLoadingDues {...{ refetch, reload }} />
  }
  return <MembershipDues {...{ me }} />
}

export const MemberDues = WrapWithFetch(MembershipFinanceWrapper)

export default getMeData(getDues)(MemberDues)
