import FlexRow from '../FlexRow'
import Briefing from '../widgets/Briefing'
import JobRecommendation from '../widgets/JobRecommendation'
import { Headline } from '../widgets/shared'
import { primary } from '../../assets/colors'
import News from '../widgets/News'
import { CookiesProvider } from 'react-cookie'
import EducationAppDecisionModal from '../widgets/EducationAppDecisionModal'
import StatusTracker from '../widgets/Prospect/StatusTracker'

export default ({ me }) => {
  return (
    <>
      <FlexRow>
        <Briefing />
      </FlexRow>
      <FlexRow>
        <StatusTracker />
      </FlexRow>
      <FlexRow>
        <div style={{ width: '100%' }}>
          <JobRecommendation {...{ me }} />
        </div>
      </FlexRow>
      <FlexRow>
        <Headline color={primary}>Top Headlines</Headline>
      </FlexRow>
      <FlexRow>
        <News me={me} />
      </FlexRow>
      <CookiesProvider>
        <EducationAppDecisionModal />
      </CookiesProvider>
    </>
  )
}
