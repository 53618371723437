import React from 'react'
import { cond, always, T, allPass } from 'ramda'
import { LinkIcon } from '@pga/pga-component-library'

import { Header, ExternalLink } from './styled'
import { hasNullCurrentLevel, hasLinkAndLevel } from './utils'
import { isAffiliate, isPreQualifier, isAssociate } from '../../../utils'

const headerText = cond([
  [isAffiliate, always('Affiliate Curriculum')],
  [isPreQualifier, always('Pre Qualifying Curriculum')],
  [isAssociate, always('Associate Curriculum')],
  [T, always('Associate Curriculum')]
])

export const HeaderDefault = ({ text }) => <Header>{text}</Header>

const HeaderWithPurchaseLink = ({ me, purchaseLink, nextLevel }) => (
  <div className='pb-3'>
    <Header display='inline'>
      {headerText(me)}
    </Header>
    {nextLevel &&
      <ExternalLink url={purchaseLink(me.id)}>| Purchase Level {nextLevel} <LinkIcon className='px-1' /></ExternalLink>}
  </div>
)

export default ({ me, nextLevel, purchaseLink }) => cond([
  [
    allPass([hasNullCurrentLevel, hasLinkAndLevel(purchaseLink, nextLevel)]),
    always(<HeaderWithPurchaseLink {...{ me, nextLevel, purchaseLink }} />)
  ],
  [T, always(<HeaderDefault text={headerText(me)} />)]
])(me)
