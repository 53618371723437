import { Col, Row } from 'reactstrap'
import { Controller } from 'react-hook-form'
import React from 'react'
import {
  ErrorText,
  InputLabel,
  Required,
  StyledSelect
} from './styles'

export const ErrorMessage = ({ errors, name }) => (
  <>
    {errors && errors[name] && (
      <ErrorText>{errors[name].message}</ErrorText>
    )}
  </>
)

export const RowFormSelect = ({ control, errors, label, name, options, styles, required }) => (
  <Row className='mt-4'>
    <FormSelect
      ariaLabel={label}
      control={control}
      errors={errors}
      label={label}
      name={name}
      options={options}
      placeholder={label}
      required={required}
      styles={styles}
    />
  </Row>
)

export const FormSelect = ({ ariaLabel, control, errors, label, name, onChange, options, placeholder, required, styles, children }) => (
  <Col>
    <InputLabel>{label} {required && <Required>*</Required>}</InputLabel>
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <StyledSelect
          {...field}
          aria-label={ariaLabel}
          onChange={onChange || ((selectedOption) => field.onChange(selectedOption.value))}
          options={options}
          placeholder={placeholder}
          styles={styles}
          value={options.find((option) => option.value === field.value)}
        />
      )}
    />
    {children}
    <ErrorMessage errors={errors} name={name} />
  </Col>
)
