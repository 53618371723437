import React from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import moment from 'moment/moment'

export const OpportunityTableBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px;
`

const FilterBox = styled.div`
  color: ${Theme.colors.primary};
  font-weight: 500;
`

const FilterLabel = styled.span`
  margin-right: 5px;
`

export const WrappedAnchor = styled.a`
  text-decoration: underline;
`

export const ExpireMessage = styled.p`
  text-transform: uppercase;
  color: ${Theme.colors.maroon};
  margin-bottom: 0px;
  font-size: 0.70rem;
`

export const Filter = () => <FilterBox><FilterLabel>Filter</FilterLabel><i className='fa fa-angle-down' /></FilterBox>

export const isExpired = date => date && moment().isAfter(date)

export const formatDate = date => moment(date).format('LL')

export const endOfDay = date => moment(date).endOf('day')
