import { pathEq } from 'ramda'

export const provisionMppAndRedirect = async ({
  history,
  provisionMpp,
  redirectUrl,
  me,
  setButtonText
}) => {
  try {
    const response = await provisionMpp({ variables: { id: me.id } })
    if (pathEq(['data', 'provisionMpp', 'success'], true, response)) {
      history.push(redirectUrl)
    }
  } catch (ex) {
    console.log(ex.message)
    setButtonText('Error')
  }
}
