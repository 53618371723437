import { Theme } from '@pga/pga-component-library'
import styled from 'styled-components'
import { JOB_DETAILS_URL } from './'

export const DetailsBox = styled.div`
  i {
    padding-left: 0;
  }

  span {
    font-family: ${Theme.fonts.Montserrat};
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: ${Theme.colors.primary};
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  justify-content: space-between;
  min-height: 198px;
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.14px;
  line-height: 25px;
  font-weight: bold;
  color: ${Theme.colors.primary};
  font-family: ${Theme.fonts.PlayfairDisplay};
`

export const Detail = styled.div`
  margin: 2px 0;
  color: ${Theme.colors.primaryWithOpacity};
`

export const Blocks = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 5px 10px 0 ${Theme.colors.primaryShadow};
  margin-bottom: 20px;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`

export const Block = styled.a.attrs(({ id }) => ({
  href: `${JOB_DETAILS_URL}${id}`
}))`
  flex: 0 0 25%;
  background-color: white;
  
  :hover {
    text-decoration: none;
    background-color: ${Theme.colors.lightgrey};
  }

  & + a {
    border: none;
    border-left: 1px solid ${Theme.colors.darkgrey};
  }

  @media (max-width: 576px) {
    & + a {
      border: none;
      border-top: 1px solid ${Theme.colors.darkgrey};
    }
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    color: ${Theme.colors.primaryWithOpacity};
  }
`
