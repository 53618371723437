import React from 'react'
import styled from 'styled-components'
import emailIcon from '../../assets/icons/icon-email.png'
import searchIcon from '../../assets/icons/icon-search-inactive.png'
import shopIcon from '../../assets/icons/icon-shop.png'
import contactIcon from '../../assets/icons/icon-contact.svg'
import addressIcon from '../../assets/icons/icon-address.svg'
import flagIcon from '../../assets/icons/icon-flag.svg'
import locationIcon from '../../assets/icons/icon-location.svg'
import userIcon from '../../assets/icons/icon-user.svg'
import visibilityOnIcon from '../../assets/icons/icon-visibility-on.svg'
import editIcon from '../../assets/icons/icon-edit.svg'
import cancelIcon from '../../assets/icons/icon-cancel.svg'
import fb from '../../assets/icons/fb.svg'
import g from '../../assets/icons/g.svg'
import lin from '../../assets/icons/in.svg'
import twitter from '../../assets/icons/twitter.svg'
import youtube from '../../assets/icons/youtube.svg'
import homeworkIcon from '../../assets/icons/icon-homework.svg'
import studyIcon from '../../assets/icons/icon-study.svg'
import todoIcon from '../../assets/icons/icon-todo.svg'
import checkIcon from '../../assets/icons/icon-check.svg'

const iconStyles = `
  padding: 5px 10px;
`
const IcoImg = styled.img`
  ${props => iconStyles}
  ${({ pt }) => pt && `padding-top: ${pt}px`};
  ${({ pr }) => pr && `padding-right: ${pr}px`};
  ${({ pb }) => pb && `padding-bottom: ${pb}px`};
  ${({ pl }) => pl && `padding-left: ${pl}px`};
  ${props => props.pointer && 'cursor: pointer;'};
`

export const SearchIcon = (props) => <IcoImg src={searchIcon} alt='search' {...props} />

export const ShopIcon = (props) => <IcoImg src={shopIcon} alt='shop' {...props} />

export const EmailIcon = (props) => <IcoImg src={emailIcon} alt='email' {...props} />

export const ContactIcon = (props) => <IcoImg src={contactIcon} alt='contact' {...props} />

export const AddressIcon = (props) => <IcoImg src={addressIcon} alt='contact' {...props} />

export const FlagIcon = (props) => <IcoImg src={flagIcon} alt='contact' {...props} />

export const LocationIcon = (props) => <IcoImg src={locationIcon} alt='contact' {...props} />

export const UserIcon = (props) => <IcoImg src={userIcon} alt='contact' {...props} />

export const VisibilityOnIcon = (props) => <IcoImg src={visibilityOnIcon} alt='contact' {...props} />

export const EditIcon = (props) => <IcoImg src={editIcon} alt='edit' {...props} />

export const CancelIcon = (props) => <IcoImg src={cancelIcon} alt='cancel' {...props} />

export const FbIcon = (props) => <IcoImg src={fb} alt='facebook' {...props} />

export const GIcon = (props) => <IcoImg src={g} alt='google plus' {...props} />

export const InIcon = (props) => <IcoImg src={lin} alt='linkedin' {...props} />

export const TwitterIcon = (props) => <IcoImg src={twitter} alt='twitter' {...props} />

export const YoutubeIcon = (props) => <IcoImg src={youtube} alt='youtube' {...props} />

export const HomeworkIcon = (props) => <IcoImg src={homeworkIcon} alt='homework' {...props} />

export const StudyIcon = (props) => <IcoImg src={studyIcon} alt='study' {...props} />

export const TodoIcon = (props) => <IcoImg src={todoIcon} alt='todo' {...props} />

export const CheckIcon = (props) => <IcoImg src={checkIcon} alt='check' {...props} />

export const MissingIcon = styled.i.attrs({
  className: 'fa fa-question'
})`
  ${iconStyles}
  font-size: 14px;
`
export const LinkIcon = styled.i.attrs({
  className: 'fa fa-angle-right'
})`
  ${iconStyles}
`

export default undefined
