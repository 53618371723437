import { gql, useLazyQuery, useQuery } from '@apollo/client'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { COACH_TOOLS_API } from '../../../../apolloClient'
import { white } from '../../../../assets/colors'
import { Button } from '../../../Button'
import { pgaComTld } from '../../../utils'
import { formatDate } from '../../../utils/formatter'
import { CardBox, DynamicInfoFrame, InfoLabel } from '../../shared'
import ErrorLoadingDues from './ErrorLoadingDues'
import LoadingDues from './LoadingDues'
import { ActionLink, ActionsContainer, Layout, LeftHalf, LinkIcon, RightHalf } from './styled'

export const OrderStage = {
  DRAFT: 'draft',
  OPEN: 'open',
  PENDING: 'pending',
  PAID: 'paid',
  FAILED: 'failed'
}

export const CARD_HEIGHT = '190px'

const PAY_DUES_URL = `https://${pgaComTld}/member/dues`
const PAY_DUES_PROXY_URL = `https://${pgaComTld}/member/dues?assignProxy=true`

export const formatCents = (value, minimumFractionDigits = 2) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits }).format((value || 0) / 100)

const CardBoxPadding = styled(CardBox)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  margin: 0;
  min-height: 190px;
  padding: 1rem 1.5rem;
  @media (min-width: 768px) {
    flex-basis: 48%;
  }

  @media (min-width: 992px) {
    flex-basis: 33%;
  }

  @media(max-width: 767.98px) {
    flex: 1;
    min-height: 218px;
  }
}
`

export const DUES_QUERY = gql`
  query DuesQuery {
    duesCycle: currentDuesCycle {
      id
      dueDate
      endsOn
      order {
        id
        balance
        dueDate
        stage
        payable
        pdfAvailable
      }
    }
  }
`

export const PDF_QUERY = gql`
  query PDFQuery {
    duesCycle: currentDuesCycle {
      id
      order {
        id
        pdfUrl
      }
    }
  }
`

function getNextCycleDate (endsOn) {
  if (endsOn) {
    return moment(endsOn).add(1, 'day').format('MM/YYYY')
  }
}

const DownloadInvoice = ({ orderStage }) => {
  const [pdfQuery, { loading }] = useLazyQuery(PDF_QUERY, {
    context: {
      api: COACH_TOOLS_API
    }
  })

  const handleOrderPDFClick = async () => {
    const { data } = await pdfQuery()
    const pdfUrl = data?.duesCycle?.order?.pdfUrl
    pdfUrl && (window.location.assign(pdfUrl))
  }

  return (
    <>
      <ActionLink onClick={handleOrderPDFClick}>
        Print {orderStage === OrderStage.PAID ? 'Receipt' : 'Invoice'} <LinkIcon />
      </ActionLink>
      {loading && <p className='mt-1'>Loading...</p>}
    </>
  )
}

export const MembershipDues = ({ data }) => {
  const duesCycle = data.duesCycle
  const order = duesCycle.order
  const hasBalance = (order?.balance || 0) > 0

  return (
    <>
      <InfoLabel fs='12px' className='text-uppercase' data-testid='dues-title'>
        DUES | {hasBalance ? 'Billing Cycle Open' : `Next Cycle ${getNextCycleDate(duesCycle?.endsOn)}`}
      </InfoLabel>
      <Layout className='mt-4 mt-md-3'>
        <LeftHalf>
          <InfoLabel className='text-uppercase'>
            {hasBalance ? 'Due Date' : 'Status'}
          </InfoLabel>
          <DynamicInfoFrame noPadX className={hasBalance && order?.dueDate ? '' : 'text-success'} data-testid='dues-status'>
            {hasBalance && order?.dueDate ? formatDate(order.dueDate) : 'Current'}
          </DynamicInfoFrame>
        </LeftHalf>
        <RightHalf>
          <InfoLabel className='text-uppercase'>
            Balance
          </InfoLabel>
          <DynamicInfoFrame noPadX data-testid='dues-balance'>
            {formatCents(order?.balance || 0)}
          </DynamicInfoFrame>
        </RightHalf>
      </Layout>
      <Layout align='flex-end' className='mt-3 mt-md-2'>
        <LeftHalf>
          <ActionsContainer>
            {hasBalance && order?.payable && (
              <a href={PAY_DUES_PROXY_URL} target='_blank' className='mb-1' rel='noopener noreferrer'>
                Assign Proxy <LinkIcon />
              </a>
            )}
            {order?.pdfAvailable && (
              <DownloadInvoice orderStage={order?.stage} />
            )}
          </ActionsContainer>
        </LeftHalf>
        <RightHalf>
          {hasBalance && (
            <a href={PAY_DUES_URL}>
              <Button margin='0px' data-testid='pay-dues-button' disabled={!order?.payable}>
                PAY DUES
              </Button>
            </a>
          )}
        </RightHalf>
      </Layout>
    </>
  )
}

export default function MembershipCommerceDues () {
  const { data, error, loading, refetch } = useQuery(DUES_QUERY, {
    fetchPolicy: 'cache-and-network',
    context: { api: COACH_TOOLS_API }
  })

  if (loading) {
    return <LoadingDues />
  } else if (error) {
    return <ErrorLoadingDues refetch={refetch} />
  }
  return (
    <CardBoxPadding color={white}>
      <MembershipDues data={data} />
    </CardBoxPadding>
  )
}
