import React from 'react'
import styled from 'styled-components'
import { Container } from 'reactstrap'
import { Button, Theme, WarningIcon } from '@pga/pga-component-library'

export const ErrorWrapper = styled.div`
  background-color: ${Theme.colors.bgGrey};
  max-width: calc(100% - 20px);
  margin: 80px;
  @media (max-width: 575.98px) {
    margin: 40px;
  }
`

export const ErrorBox = styled.div`
  padding: 30px 40px;
  color: ${Theme.colors.primary};
  text-align: center;
  font-family: ${Theme.fonts.Montserrat};
  font-weight: 500;
  font-size: 16px;

  h3 {
    font-size: 25px;
    font-family: ${Theme.fonts.PlayfairDisplay};
    font-weight: 700;
  }
`

export const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  padding: 25px;
  margin-bottom: 20px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  a {
    margin: 8px;
  }
  @media (max-width: 575.98px) {
    flex-direction: column;
  }
`

const ErrorComp = ({ message, educationLink }) => (
  <Container>
    <ErrorWrapper>
      <ErrorBox>
        <WarningIcon />
        <MessageContainer>
          <h3>{message}</h3>
        </MessageContainer>
        <ButtonWrapper>
          <Button href='/'>Home</Button>
          {educationLink && <Button href='progress'>Education</Button>}
        </ButtonWrapper>
      </ErrorBox>
    </ErrorWrapper>
  </Container>
)

ErrorComp.defaultProps = {
  message: 'Something went wrong.',
  educationLink: true
}

export default ErrorComp
