import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { Theme } from '@pga/pga-component-library'
import styled from 'styled-components'

import FlexRow from '../../FlexRow'
import JobRecommendation from '../../widgets/JobRecommendation'
import { Headline } from '../../widgets/shared'
import News from '../../widgets/News'
import { Col, H1, IdLabel } from './styles'
import CtaPanel, { ctaConfig } from './CtaPanel'
import PathToMembership from './PathToMembership'

const StyledWrapper = styled.div`
  margin-top: 1rem;
  padding-left: 10px;
`

export const UserInfo = ({ me }) => (
  <StyledWrapper>
    <H1>Welcome, {me.firstName} {me.lastName}</H1>
    <IdLabel><strong>ID: </strong>{me.id}</IdLabel>
    <hr />
  </StyledWrapper>
)

export const JobSeekerDash = ({ me, history }) => (
  <>
    <UserInfo {...{ me }} />
    <PathToMembership />
    <hr />
    <JobRecommendation {...{ me }} />
    <FlexRow>
      {ctaConfig(history).map(({ title, subTitle, IconComp, goToFunc }, i) => (
        <Col key={i}>
          <CtaPanel
            title={title}
            subTitle={subTitle}
            IconComp={IconComp}
            goToFunc={goToFunc}
          />
        </Col>
      ))}
    </FlexRow>
    <FlexRow>
      <Headline color={Theme.colors.primary}>Top Headlines</Headline>
    </FlexRow>
    <FlexRow>
      <News me={me} />
    </FlexRow>
  </>
)

export default withRouter(JobSeekerDash)
