import React from 'react'
import styled from 'styled-components'
import { Button } from '../../Button'
import { primary, primaryShadow } from '../../../assets/colors'
import { PlayfairDisplay } from '../../../assets/fonts'

export const ErrorTitle = styled.span`
  font-family: ${PlayfairDisplay};
  font-size: 24px;
  font-weight: 500;
  color: ${primary};
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 25px 10px 25px;
  min-width: 300px;
  width: 100%;

  @media(max-width: 1199px) {
    border-right: 0;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 5px 10px 0 ${primaryShadow};
    flex-wrap: wrap;
    height: 320px;
    margin-bottom: 20px;
  }
`

export default ({ refetch, reload, collapsed }) => (
  collapsed
    ? <ErrorTitle>Failed to load</ErrorTitle>
    : (
      <BoxContainer>
        <ErrorTitle>Failed to load</ErrorTitle>
        <Button onClick={() => reload(refetch)}>REFRESH</Button>
      </BoxContainer>
      )
)
