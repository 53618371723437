import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'

export const Text = styled.p`
  font-family: ${Theme.colors.Montserrat};
  font-size: 16px;
  color: ${props => props.gray ? Theme.colors.blueGrey : Theme.colors.primary};
  ${props => !props.gray && 'font-weight bold;'}
  margin-top: 40px;
`

export const SuccessTextComp = ({ programName, text }) => (
  <>
    <Text>
      Good news, your application to the {programName} has been approved.
    </Text>
    <Text gray>
      {text}
    </Text>
  </>
)

export const DeniedTextComp = ({ programName }) => (
  <>
    <Text>
      Your application to the {programName} was not approved.
    </Text>
    <Text gray>
      Please check your email for details, or call the Education Mentor Line at 1.866.866.3382, option 6
    </Text>
  </>
)
