import moment from 'moment'

const getPhoneParts = phoneNumber => `${phoneNumber}`.replace(/\D/g, '').match(/^(\d{3})(\d{3})(\d{4})$/)

export const formatPhone = phoneNumberString => {
  const phoneParts = getPhoneParts(phoneNumberString)
  return phoneParts ? `(${phoneParts[1]}) ${phoneParts[2]}-${phoneParts[3]}` : null
}

export const formatDate = (date, format = 'MM/DD/YY') => moment.utc(date).format(format)

export const formatDateWithZeroes = (fullDateStr, defaultValue = '') => {
  const formattedDate = moment(fullDateStr).format('MM/DD/YYYY')
  return (formattedDate === 'Invalid date') ? defaultValue : formattedDate
}
/*
  Will show the least number of decimal digits as possible
  EX:
    1234 --> $1,234
    1234.5 --> $1,234.5
    1234.56 --> $1,234.56
    1234.567 --> $1,234.57
*/
export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})
