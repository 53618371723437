import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Query } from '@apollo/client/react/components'
import { LoadingComponent } from '@pga/pga-component-library'
import ErrorCard from '../components/ErrorCard'
import { map, any, equals, prop, isNil, propOr, propEq, path, isEmpty, pipe } from 'ramda'
import { gql } from '@apollo/client'

export const educationStatusQuery = gql`
  query CheckEducationStatus {
    me {
      id
      education {
        currentCppProgramStatus
        currentMppProgramStatus
      }
    }
  }
`

export const resolveRules = ({
  memberCppStatus,
  memberMppStatus
}) => (
  map(rule => {
    const memberHasCppStatus = (typeof memberCppStatus === 'string' && memberCppStatus !== '')
    const memberHasMppStatus = (typeof memberMppStatus === 'string' && memberMppStatus !== '')
    const specificStatusCodes = propOr([], 'status', rule)
    const needToCheckSpecificCodes = (Array.isArray(specificStatusCodes) && !isEmpty(specificStatusCodes))
    const shouldCheckCpp3 = propEq('programCode', 'CPP3', rule)
    const shouldCheckMpp3 = propEq('programCode', 'MPP3', rule)

    if (shouldCheckCpp3 && memberHasCppStatus) {
      return needToCheckSpecificCodes
        ? specificStatusCodes.includes(memberCppStatus)
        : true
    }

    if (shouldCheckMpp3 && memberHasMppStatus) {
      return needToCheckSpecificCodes
        ? specificStatusCodes.includes(memberMppStatus)
        : true
    }

    return false
  })
)

export const memberCanAccessRoute = ({ me, rules = [] }) => pipe(
  resolveRules({
    memberCppStatus: path(['education', 'currentCppProgramStatus'], me),
    memberMppStatus: path(['education', 'currentMppProgramStatus'], me)
  }),
  any(equals(true))
)(rules)

export const RouteOrRedirect = ({ me, Component, rules }) => props => (
  memberCanAccessRoute({ me, rules })
    ? <Component {...props} />
    : <Redirect to='/education/programs' />
)

export const HandleQuery = ({ data, loading, error, Component, rules, routeProps }) => {
  if (loading) return <LoadingComponent />
  if (error) return <ErrorCard />

  const me = prop('me', data)

  return !isNil(me)
    ? <Route {...routeProps} render={RouteOrRedirect({ me, rules, Component })} />
    : null
}

export default ({ component: Component, rules, ...routeProps }) => (
  <Query query={educationStatusQuery}>
    {({ data, loading, error }) => HandleQuery({ data, loading, error, Component, rules, routeProps })}
  </Query>
)
