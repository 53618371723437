import React from 'react'
import { getMeData } from '../../utils'
import { getCurrentProgram } from '../../queries/member'
import Registration from './Registration'
import Loading from './Loading'
import ErrorLoading from './ErrorLoading'
import WrapWithFetch from '../WrapWithFetch'

export const ProgramEnrollmentCard = ({
  me,
  loading,
  isFetching,
  error,
  refetch,
  reload
}) => {
  if (loading || isFetching) {
    return <Loading />
  } else if (error) {
    return <ErrorLoading {...{ refetch, reload }} />
  }
  return <Registration {...{ me }} />
}

export default getMeData(getCurrentProgram)(WrapWithFetch(ProgramEnrollmentCard))
