import { Col, Row } from 'reactstrap'
import { Controller } from 'react-hook-form'
import React from 'react'
import {
  Button,
  InputLabel,
  Required,
  StyledInput,
  selectOverride
} from './styles'

import AddressAutocomplete from './AddressAutocomplete'
import { AsYouType } from 'libphonenumber-js'
import { StyledFormGroup } from '../../components/SignUp/CreateAccount/SignUpForm/styles'
import { isEmpty } from 'ramda'
import { ErrorMessage, FormSelect } from './FormComponents'
import { countryOptions } from './conditions'

const FormField = ({ label, name, placeholder, control, errors, required, type, maxLength }) => (
  <Col>
    <InputLabel>{label} {required && <Required>*</Required>}</InputLabel>
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <StyledInput
          {...field}
          type={type}
          data-testid={name}
          placeholder={placeholder}
          value={field.value}
          maxLength={maxLength}
        />
      )}
    />
    <ErrorMessage errors={errors} name={name} />
  </Col>
)

const formatPhoneNumber = (value) => new AsYouType('US').input(value)

const DemographicQuestions = ({ control, errors, setValue, isValid, handleSetSecondPage, history, watch }) => {
  const requiredFields = [
    'mobileNumber',
    'dob',
    'address1',
    'city',
    'state',
    'zip',
    'country'
  ]
  const watchedFields = watch(requiredFields)
  const isButtonEnabled = () => {
    const hasError = requiredFields.some((field) => errors[field])
    const hasEmptyInput = watchedFields.some((watchedField) => isEmpty(watchedField))
    return hasError || !hasEmptyInput
  }
  return (
    <>
      <StyledFormGroup>
        <Row>
          <Col>
            <InputLabel className='mb-4'>Mobile Number <Required>*</Required></InputLabel>
            <Controller
              control={control}
              name='mobileNumber'
              render={({ field }) => (
                <StyledInput
                  {...field}
                  data-testid='mobileNumber'
                  placeholder='Mobile Number'
                  maxLength={14}
                  onChange={(e) => field.onChange(formatPhoneNumber(e.target.value))}
                  value={field.value}
                />
              )}
            />
            <ErrorMessage errors={errors} name='mobileNumber' />
          </Col>
          <FormField
            label='Birthdate'
            name='dob'
            placeholder='Birthdate'
            control={control}
            type='date'
            required
            errors={errors}
          />
        </Row>
        <Row className='mt-4'>
          <Col>
            <InputLabel>Street Address <Required>*</Required></InputLabel>
            <Controller
              control={control}
              name='address1'
              render={({ field }) => {
                return (
                  <AddressAutocomplete
                    testId='address1'
                    field={field}
                    placeholder='Mailing Address'
                    setValue={setValue}
                    value={field.value}
                  />
                )
              }}
            />
            <ErrorMessage errors={errors} name='address1' />
          </Col>
        </Row>
        <Row className='mt-4'>
          <FormField
            label='Apt. or Suite'
            name='address2'
            placeholder='Apt. or Suite'
            control={control}
            type='text'
          />
        </Row>
        <Row className='mt-4'>
          <FormField
            label='City'
            name='city'
            placeholder='City'
            control={control}
            required
            errors={errors}
            type='text'
          />
          <FormField
            label='State / Province'
            name='state'
            placeholder='State / Province'
            control={control}
            required
            maxLength={2}
            errors={errors}
            type='text'
          />
        </Row>
        <Row className='mt-4'>
          <FormField
            label='Postal Code'
            name='zip'
            placeholder='Postal Code'
            control={control}
            required
            errors={errors}
          />
          <FormSelect
            ariaLabel='Country'
            control={control}
            errors={errors}
            label='Country'
            name='country'
            options={countryOptions}
            placeholder='Country'
            required
            syle={selectOverride}
          />
        </Row>
      </StyledFormGroup>
      {!isValid && <span className='mt-3'>Fill out all of the required fields in order to continue</span>}
      <Button
        data-testid='continue-btn'
        disabled={!isButtonEnabled()}
        onClick={(event) => handleSetSecondPage(event, history)}
        className='mt-2'
        type='button'
      >
        Continue
      </Button>
    </>
  )
}

export default DemographicQuestions
