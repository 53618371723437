import styled from 'styled-components'
import { Modal, Tooltip } from 'reactstrap'
import { Theme } from '@pga/pga-component-library'

export const Title = styled.p`
  color: ${props => props.color || Theme.colors.darkGrey};
  text-transform: uppercase;
  font-size: 0.90rem;
  margin-top: ${props => props.marginTop || '0px'};
  font-weight: 600;
  margin-bottom: 3px;
  font-family: ${Theme.fonts.Monterrat};
`

export const StyledModal = styled(Modal)`
  .modal-content {
    padding: 15px;
    border-radius: 5px;
    border: none;
  }
`

export const SubText = styled.p`
  color: ${Theme.colors.darkGrey};
  font-size: 0.90rem;
  font-weight: 500;
`

export const ModalTitle = styled.h3`
  color: ${Theme.colors.primary};
  font-size: 1.25rem;
  font-weight: 600;
`

export const SubTitle = styled.p`
  color: black;
  font-weight: 600;
  font-size: 0.95rem;
  margin-top: 0.5rem;
  margin-bottom: 3px;
`

export const Box = styled.p`
  padding: 0.75rem;
  margin-top: 5px;
  background-color: ${props => props.bg ? Theme.colors.lightBgGrey : 'white'};
  width: 100%;
`

export const Link = styled.a`
  font-size: 13px;
  font-weight: 600;
`

export const RequirementsText = styled.p`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
`

export const Text = styled.p`
  margin-bottom: 5px;
`

export const StyledTooltip = styled(Tooltip)`
  opacity: 1 !important;
  filter: alpha(opacity=100);
  .tooltip-inner {
    background-color: ${Theme.colors.primary};
    font-family: ${Theme.fonts.Montserrat};
    font-size: ${props => props.fs || '13px'};
    color: ${Theme.colors.white};
    white-space: pre-wrap;
    border-radius: 0px;
    border: 1px solid ${Theme.colors.primary};
    width: 200px;
    padding: 10px 15px;
    font-weight: ${props => props.fw || 600};
    text-align: left;
    margin-top: 4px;
  }
  .arrow:after,
  .arrow:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .arrow:before{
    border-color: rgba(151, 151, 151, 0);
    border-bottom-color: ${Theme.colors.primary};
    border-width: 11px;
    margin-left: -11px;
    top: -9px !important;
  }
  .arrow:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: ${Theme.colors.primary};
    border-width: 10px;
    margin-left: -10px;
    top: -8px !important;
  }
`

export const TooltipTarget = styled.span`
  color: ${Theme.colors.lightBlue};
  font-weight: 800;
  cursor: pointer;
`

export const DisabledText = styled.p`
  opacity: 0.5;
`

export const IntercomLink = styled.span`
  color: ${Theme.colors.lightBlue};
  font-weight: 500;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
