import styled, { css } from 'styled-components'
import { Theme } from '@pga/pga-component-library'

export const Wrapper = styled.div`
  background: ${props => props.color && Theme.colors[props.color]};
  margin-top: ${props => props.removeMarginTop ? '0' : '20px'};
  padding: 10px 5px;
  text-align: center;
`

const TextStyles = css`
  font-size: 16px;
  font-weight: 400;
  font-family: ${Theme.fonts.Montserrat};
  color: ${props => props.textColor && Theme.colors[props.textColor]};
  margin: 10px;
  text-align: center;
  display: inline;

  @media screen and (max-width: 767.98px) {
    line-height: 1.5;
    font-size: 14px;
  }
`

export const Title = styled.h5`
  ${TextStyles};

  @media screen and (max-width: 767.98px) {
    display: block;
    margin: 0;
  }
`

export const TitleLink = styled.a`
  ${TextStyles};
  text-decoration: underline;

  &:hover {
    color: ${props => props.textColor && Theme.colors[props.textColor]};
  }

  @media screen and (max-width: 767.98px) {
    margin: 0;
  }
`

export const InlineLink = styled.a`
  text-decoration: underline;
  color: ${props => props.textColor && Theme.colors[props.textColor]};
  font-size: 16px;
  font-weight: 400;
  font-family: ${Theme.fonts.Montserrat};

  &:hover {
    color: ${props => props.textColor && Theme.colors[props.textColor]};
  }
`
