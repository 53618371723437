import { CallMemberServices, Btn } from './shared'
import ActionButton from './ActionButton'
import { path, and, isEmpty, isNil } from 'ramda'
import { formatPhone } from '../utils/formatter'
import { GenericPanel, GroupedDataPanel, PrimaryFacility } from './SectionPanels'
import MappedFields from './MappedFields'
import Facilities from './Facilities'
import AddFacilities from './AddFacilities'
import { pgaComTld } from '../utils'

export const getLastLine = address => `${address.city}, ${address.state} ${address.zip}`
const getLegalName = data => `${data.firstName} ${data.lastName}`

export const getFacilities = data => {
  const primaryFacId = path(['primaryFacility', 'id'], data)
  const facilities = data.facilities ? data.facilities.filter(fac => fac) : []
  return and(!isEmpty(facilities), !isNil(facilities))
    ? facilities.reduce((acc, { id, name, address, section }) => {
      if (id !== primaryFacId) {
        acc.push({
          key: name,
          facility: [{
            label: 'FACILITY NAME',
            field: name
          }, {
            label: 'STATE/PROVINCE',
            field: address.state
          }, {
            label: 'SECTION',
            field: section.name
          }]
        })
      }
      return acc
    }, [])
    : null
}
export const NEW_ACCOUNT_EDIT_PAGE = `https://${pgaComTld}/member/account/edit`

export default data => [{
  id: 1,
  title: 'Member Records',
  text: 'Official PGA Member Records can be modified by submitting a Change Request.  Requested changes will be reflected in your records once your submission has been approved.  Requests are normally processed within 2 business days',
  panels: ['displayName', 'address', 'phoneFields'],
  displayName: {
    title: 'LEGAL NAME',
    panelData: getLegalName(data),
    mappedFields: false,
    Button: CallMemberServices,
    Component: GenericPanel
  },
  address: {
    title: 'HOME ADDRESS',
    panelData: {
      address1: path(['address', 'address1'], data),
      address2: path(['address', 'address2'], data),
      lastLine: getLastLine(data.address),
      viewAddressMember: path(['viewingPermissions', 'viewAddressMember'], data)
    },
    mappedFields: ['address1', 'address2', 'lastLine', 'state', 'zip'],
    Button: ActionButton,
    link: NEW_ACCOUNT_EDIT_PAGE,
    Component: MappedFields
  },
  phoneFields: {
    Component: GroupedDataPanel,
    link: NEW_ACCOUNT_EDIT_PAGE,
    Button: Btn,
    infoArray: [{
      title: 'EMAIL',
      panelData: path(['primaryEmail'], data),
      mappedFields: false
    }, {
      title: 'HOME PHONE',
      panelData: formatPhone(path(['phoneNumber'], data)),
      mappedFields: false
    }, {
      title: 'CELL PHONE',
      panelData: formatPhone(path(['primaryMobile'], data)),
      mappedFields: false
    }]
  }
}, {
  id: 2,
  title: 'Jobs & Section',
  text: 'The Job & Section information listed below is used by the PGA of America for member-related business purposes.  Some information is also viewable in your Directory Profile.  If you are associated with multiple facilities, your classification and class description are assigned by your primary facility.',
  panels: ['addEmployment', 'primaryFacility', 'facilities'],
  addEmployment: {
    Component: AddFacilities
  },
  primaryFacility: {
    title: ['FACILITY NAME', 'STATE/PROVINCE', 'SECTION', 'CLASSIFICATION', 'CLASS DESCRIPTION'],
    panelData: {
      primaryFacilityName: path(['primaryFacility', 'name'], data),
      primaryFacilityState: path(['primaryFacility', 'address', 'state'], data),
      section: path(['section', 'name'], data),
      classification: path(['class'], data),
      classDescription: path(['memberClassDescription'], data)
    },
    mappedFields: [{
      label: 'FACILITY NAME',
      field: 'primaryFacilityName'
    }, {
      label: 'STATE/PROVINCE',
      field: 'primaryFacilityState'
    }, {
      label: 'SECTION',
      field: 'section'
    }, {
      label: 'CLASSIFICATION',
      field: 'classification'
    }, {
      label: 'CLASS DESCRIPTION',
      field: 'classDescription'
    }],
    Button: ActionButton,
    Component: PrimaryFacility
  },
  facilities: {
    title: 'Facilities',
    panelData: path(['facilities'], data),
    mappedFields: [],
    Button: ActionButton,
    facilities: getFacilities(data),
    Component: Facilities
  }
}]
