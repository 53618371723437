import { filter, includes } from 'ramda'
import { NEW_ACCOUNT_EDIT_PAGE } from '../PgaRecord/options'

export const getNavigationOptions = () => [{
  id: 'dashboard',
  label: 'dashboard',
  to: '/',
  isActive: (match, location) => {
    if (!location) return false
    return location.pathname === '/'
  }
}, {
  id: 'profile',
  label: 'My Directory Profile',
  to: '/profile/edit'
}, {
  id: 'pga record',
  label: 'My Official PGA Records',
  to: NEW_ACCOUNT_EDIT_PAGE,
  external: true
}, {
  id: 'job-preferences',
  label: 'Job Preferences',
  to: '/job-preferences'
}, {
  id: 'education-programs',
  label: 'Education Programs',
  to: '/education/programs'
}, {
  id: 'compensation-profile',
  label: 'Compensation Profile',
  to: '/compensation-profile'
}]

const typeNavigation = {
  MB: ['dashboard', 'profile', 'pga record', 'job-preferences', 'education-programs', 'compensation-profile'],
  ST: ['dashboard', 'profile', 'pga record', 'job-preferences', 'compensation-profile'],
  AP: ['dashboard', 'profile', 'pga record', 'job-preferences', 'compensation-profile'],
  PQ: ['dashboard', 'job-preferences', 'compensation-profile'],
  QE: ['dashboard', 'job-preferences', 'compensation-profile']
}
const defaultNavigation = ['dashboard']

export default ({ type }) => {
  const typeDestiny = typeNavigation[type] || defaultNavigation
  const navOptions = getNavigationOptions()
  return filter(({ id }) => includes(id, typeDestiny), navOptions)
}
