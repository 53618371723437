import React from 'react'
import styled from 'styled-components'
import { graphql } from '@apollo/client/react/hoc'
import { Mailto, Tel } from '@pga/pga-component-library'
import { primary, lightgrey } from '../../../assets/colors'
import { Montserrat } from '../../../assets/fonts'
import { getMySection } from '../../queries/member'
import LoadingPlaceholder from '../../LoadingPlaceholder'
import WrapWithFetch from '../WrapWithFetch'
import { Button } from '../../Button'
import { path } from 'ramda'

const InfoCard = styled.div`
  margin: 5px -15px 5px -15px;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  background: ${lightgrey};
`
export const Highlight = styled.span`
  font-family: ${Montserrat};
  font-weight: 600;
  line-height: 2;
  color: ${primary};
`
const InfoLine = styled.div`
  padding: 2px 0;
`

const Title = styled.span`
  color: ${primary};
`

const labelFor = {
  ST: 'Program Director',
  AP: 'Career Consultant'
}

const attachQuery = graphql(getMySection, {
  props: ({ data }) => ({
    loading: data.loading,
    me: data.me,
    error: data.error,
    refetch: data.refetch
  })
})

export const Loading = () => (
  <InfoCard>
    <LoadingPlaceholder w={100} h={15} />
    <LoadingPlaceholder w={100} h={15} />
    <LoadingPlaceholder w={100} h={15} />
    <LoadingPlaceholder w={100} h={15} />
  </InfoCard>
)

export const ErrorLoading = ({ refetch, reload }) => (
  <InfoCard>
    <p>Whoops! We had some trouble loading your Career Consultant information.</p><br /> <p>Please refresh.</p>
    <Button onClick={() => reload(refetch)}>REFRESH</Button>
  </InfoCard>
)

export const ContactInfo = ({
  me,
  loading,
  isFetching,
  error,
  refetch,
  reload
}) => {
  if (loading || isFetching) {
    return <Loading />
  } else if (error || !me) {
    return (
      <ErrorLoading {...{ refetch, reload }} />
    )
  }
  const careerConsultant = path(['section', 'careerConsultant'], me)
  return (
    <InfoCard>
      <Highlight>{labelFor[me.type]}</Highlight>
      <InfoLine><Title>{careerConsultant && `${careerConsultant.fullName}`}</Title></InfoLine>
      {careerConsultant && <InfoLine><Mailto emailAddress={careerConsultant.email} /></InfoLine>}
      {careerConsultant && <InfoLine><Tel phoneNumber={careerConsultant.phone} /></InfoLine>}
    </InfoCard>
  )
}

export default attachQuery(WrapWithFetch(ContactInfo))
