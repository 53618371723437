import React from 'react'
import { MSRBox, TotalWrapper } from './Styles/Shared.styles'
import { Title } from '../Widget'

export default () => (
  <MSRBox>
    <Title text='YOUR PDR CREDITS' />
    <div>
      <TotalWrapper>
        MSR data is not currently available as the MSR Cycle Evaluation processes.  You can access the online <a href='https://resources.pga.org/my-membership/earn-and-report-msrs/continuing-education-offers-msr-opportunities' target='_blank' rel='noopener noreferrer'>MSR videos here</a>.  Please check back tomorrow, or call the PGA Membership Department at <a href='tel:8004742776'>(800) 474-2776</a>.
      </TotalWrapper>
    </div>
  </MSRBox>
)
