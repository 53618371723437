import React, { useState, useEffect } from 'react'
import { withCookies } from 'react-cookie'
import { isNil, pathOr, prop, propOr } from 'ramda'
import { applicationDecisionQuery } from './query'
import { Query } from '@apollo/client/react/components'
import DecisionModal from './DecisionModal'
import { doesModalMatchMemberStatus, getModalConfig, ifDeniedSetOptOut } from './shared'

export const AppDecisionModal = ({ memberId, modalConfig, cookies, modalCookie }) => {
  const [isOpen, toggle] = useState(propOr(false, 'optedOut', modalCookie) !== true)

  return isOpen
    ? <DecisionModal {...{ id: memberId, isOpen, toggle, modalConfig, cookies }} />
    : null
}

// Since there is both a CPP and a MPP modal, this function will choose which one to use
// based on the member's education profile (maybe neither)
export const whichModalToUse = ({
  cppProgramStatus,
  cppApplicationInfo,
  mppProgramStatus,
  mppApplicationInfo
}) => {
  if (doesModalMatchMemberStatus(cppProgramStatus, cppApplicationInfo)) {
    return {
      programStatus: cppProgramStatus,
      programCode: 'CPP3',
      cookieName: 'cppDecisionModal',
      ...getModalConfig(cppProgramStatus, 'CPP3')
    }
  }

  if (doesModalMatchMemberStatus(mppProgramStatus, mppApplicationInfo)) {
    return {
      programStatus: mppProgramStatus,
      programCode: 'MPP3',
      cookieName: 'mppDecisionModal',
      ...getModalConfig(mppProgramStatus, 'MPP3')
    }
  }

  return null
}

export const ChooseModal = ({ me, cookies }) => {
  const memberId = prop('id', me)
  const cppProgramStatus = pathOr(null, ['education', 'currentCppProgramStatus'], me)
  const cppApplicationInfo = pathOr(null, ['education', 'currentCppApplicationInfo'], me)
  const mppProgramStatus = pathOr(null, ['education', 'currentMppProgramStatus'], me)
  const mppApplicationInfo = pathOr(null, ['education', 'currentMppApplicationInfo'], me)

  const modalConfig = whichModalToUse({
    cppProgramStatus,
    cppApplicationInfo,
    mppProgramStatus,
    mppApplicationInfo
  })

  const modalCookie = isNil(modalConfig) ? null : cookies.get(modalConfig.cookieName)

  useEffect(() => {
    if (!isNil(modalConfig)) {
      ifDeniedSetOptOut({
        currentStatus: modalConfig.programStatus,
        modalCookie,
        memberId,
        cookies,
        cookieName: modalConfig.cookieName
      })
    }
  }, [])

  return isNil(modalConfig) ? null : <AppDecisionModal {...{ modalConfig, memberId, cookies, modalCookie }} />
}

export const HandleQuery = (cookies, data, loading, error) => {
  if (loading) return null
  if (error) return null
  return data ? <ChooseModal cookies={cookies} me={prop('me', data)} /> : null
}

export const ApplyQuery = ({ cookies }) => (
  <Query query={applicationDecisionQuery}>
    {({ data, loading, error }) => HandleQuery(cookies, data, loading, error)}
  </Query>
)

export default withCookies(ApplyQuery)
