import styled from 'styled-components'
import profileImg from '../../../assets/images/profileImg.svg'

export const ProfilePicture = styled.div`
  display: flex;
  min-height: 230px;
  background: lightgrey;
  flex-direction: column;
  align-items: center;
`

export const ProfileImgFrame = styled.div``

export const ProfileImg = styled.div`
  display: flex;
  min-height: 255px;
  width: 100%;
  opacity: 0.9;
  flex-direction: column;
  align-items: center;
  background-image: ${props => `url("${props.photo || profileImg}")`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

export const PhotoProfile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: lightgrey;
  text-align: center;
  width: ${props => `${(props.small && '30') || '150'}px`};
  height: ${props => `${(props.small && '30') || '150'}px`};
  border-radius: 100%;
  font-size: ${props => `${(props.small && '15') || '40'}px`};
`
