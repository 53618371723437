import { Panel, Theme } from '@pga/pga-component-library'
import { LevelTrackBox, SectionWrapper, Title } from './StatusTracker/styles'
import styled from 'styled-components'
import LoadingPlaceholder from '../../LoadingPlaceholder'
import { NetworkStatus } from '@apollo/client'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledLink = styled.div`
  color: ${Theme.colors.lightBlue};
  font-weight: 600;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const Col = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StatusTrackerLoader = () => (
  <Panel>
    <LevelTrackBox>
      <div className='row'>
        <div className='col-sm-12 col-lg-3 d-flex flex-column'>
          <SectionWrapper>
            <LoadingPlaceholder h={16} w={100} />
            <LoadingPlaceholder h={22.8} w={175} />
            <LoadingPlaceholder h={72} w={210.7} />
            <LoadingPlaceholder h={16} w={164.88} className='mb-4' />
            <LoadingPlaceholder h={85} w={204.75} />
          </SectionWrapper>
        </div>
        <div className='col-sm-12 col-lg-3 d-flex flex-column'>
          <SectionWrapper>
            <LoadingPlaceholder h={16} w={100} />
            <LoadingPlaceholder h={22.8} w={195} />
            <LoadingPlaceholder h={72} w={210.7} />
            <LoadingPlaceholder h={16} w={110.97} className='mb-4' />
            <LoadingPlaceholder h={85} w={204.75} />
          </SectionWrapper>
        </div>
        <div className='col-sm-12 col-lg-3 d-flex flex-column'>
          <SectionWrapper>
            <LoadingPlaceholder h={16} w={100} />
            <LoadingPlaceholder h={22.8} w={150} />
            <LoadingPlaceholder h={72} w={210.7} />
            <LoadingPlaceholder h={16} w={164.88} className='mb-4' />
            <LoadingPlaceholder h={85} w={204.75} />
          </SectionWrapper>
        </div>
        <Col className='col-sm-12 col-lg-3 d-flex flex-column'>
          <LoadingPlaceholder h={175} w={210.7} />
        </Col>
      </div>
    </LevelTrackBox>
  </Panel>
)

export const PathBoxLoader = () => {
  return (
    <div className='container'>
      <LoadingPlaceholder h={20} w={200} className='mt-4' />
      <LoadingPlaceholder h={50} w={400} />
      <LoadingPlaceholder h={20} w={250} />
      <div className='row mt-4'>
        <div className='col-md-6'>
          <LoadingPlaceholder h={20} w={250} />
          <LoadingPlaceholder h={65} w={350} />
          <LoadingPlaceholder h={20} w={150} />
          <LoadingPlaceholder h={20} w={200} />
          <LoadingPlaceholder h={55} w={200} className='mt-4 mb-4' />
        </div>
        <div className='col-md-6'>
          <LoadingPlaceholder h={20} w={250} />
          <LoadingPlaceholder h={65} w={350} />
          <LoadingPlaceholder h={20} w={150} />
          <LoadingPlaceholder h={20} w={200} />
          <LoadingPlaceholder h={55} w={200} className='mt-4 mb-4' />
        </div>
      </div>
    </div>
  )
}

export const StatusTrackerError = ({ refetch, networkStatus }) => {
  if (networkStatus === NetworkStatus.refetch) {
    return <StatusTrackerLoader />
  }
  return (
    <Panel>
      <LevelTrackBox>
        <Wrapper>
          <Container>
            <Title center>An error occurred while loading the page <StyledLink onClick={() => refetch()}>please try again</StyledLink></Title>
          </Container>
        </Wrapper>
      </LevelTrackBox>
    </Panel>
  )
}
