import React from 'react'
import { cond, T } from 'ramda'
import { Side } from '../Panel'
import { shouldSeeAnnouncements } from '../../utils'
import Announcements from '../Announcements'
import LoadingPlaceholder from '../Announcements/LoadingPlaceholder'
import { withAuthProvider } from '../../../lib/auth'
import { withProspectDateQuery } from '../../../utils/prospect/withProspectDateQuery'

const SideComp = ({ collapsed, loading, me }) => <Side basis={!collapsed && 45}><SecondaryContent {...{ me, loading, collapsed }} /></Side>

const SecondaryContent = cond(
  [
    [({ loading }) => loading, () => <LoadingPlaceholder />],
    [T, ({ collapsed }) => <Announcements collapsed={collapsed} />]
  ]
)
const MembershipSailing = ({ me, loading, collapsed }) => {
  const ShowSide = shouldSeeAnnouncements(me) ? <SideComp {...{ collapsed, loading, me }} /> : null
  return ShowSide
}

export default withProspectDateQuery(withAuthProvider(MembershipSailing))
