import React, { Fragment } from 'react'
import { cond, always, T } from 'ramda'
import { Tel } from '@pga/pga-component-library'

import { Title } from './styled'
import { hasNullCurrentLevel, hasLinkAndLevel } from './utils'
import { isTerminated, isSuspended, isGptpSuspended } from '../../../utils'

export const MessagePurchaseLink = ({ me, nextLevel, purchaseLink, title: { completed, Contact } }) => (
  <Title>
    {completed}
    {Contact && <Contact />}
    {hasLinkAndLevel(purchaseLink, nextLevel)() && (
      <>
        <a href={`${purchaseLink(me.id)}`} target='_blank' rel='noopener noreferrer' className='pl-1'>Purchase Level {nextLevel}</a> now to continue.
      </>
    )}
  </Title>
)

export const MessageTerminated = () => (
  <Title>
    Your membership is not <u className='text-danger'>current</u>.
    Please contact PGA Membership Services at <Tel phoneNumber='(800) 474-2776' />
  </Title>
)

export const MessageSuspended = () => (
  <Title>
    You are currently <u className='text-danger'>suspended</u> for failing to meet Acceptable Progress. Please <a href='https://resources.pga.org/my-membership/associate-program/pga-associate-acceptable-progress-tracking'>click here</a> to review the Acceptable Progress policy as you work toward completing the requirements to have the suspension lifted.
  </Title>
)

export const MessageGptpSuspension = () => (
  <Title>
    You are currently suspended for failure to make Acceptable Progress in the PGA Professional Golf Management Program. Please contact the PGA Membership Department at <Tel phoneNumber='(800) 474-2776' /> for guidance on getting back on track.
  </Title>
)

export const MessageDefault = ({ title = {} }) => <Title>{title.inProgress}</Title>

export default ({ me, title, nextLevel, purchaseLink }) => cond([
  [isTerminated, always(<MessageTerminated />)],
  [isGptpSuspended, always(<MessageGptpSuspension />)],
  [isSuspended, always(<MessageSuspended />)],
  [hasNullCurrentLevel, always(<MessagePurchaseLink {...{ me, title, nextLevel, purchaseLink }} />)],
  [T, always(<MessageDefault {...{ me, title, nextLevel, purchaseLink }} />)]
])(me)
