import React from 'react'
import { T, cond } from 'ramda'
import { DynamicInfoFrame } from '../../../shared'
import { getDefaultDueBalance, hasDueBalance, getDefaultDueDate, getNextCycleDate } from '../utils'
import PastGracePeriodView from './PastGracePeriodView'
import { isTerminated } from '../../../../utils'
import ExtendedDuesView from './ExtendedDuesView'

export const NextCycleDate = () => <span className='pl-1'>NEXT CYCLE {getNextCycleDate()}</span>

const Views = {
  pastGracePeriod: () => {
    return {
      name: 'pastGracePeriod',
      Component: PastGracePeriodView,
      header: <span className='text-danger pl-1'>*PAYMENT NOTICE</span>
    }
  },

  hasDueBalance: (me) => {
    const dueDate = getDefaultDueDate(me)
    const duesBalance = getDefaultDueBalance(me)

    return {
      name: 'hasDueBalance',
      Component: ExtendedDuesView,
      header: <NextCycleDate />,
      dateLabel: 'DUE BY:',
      balanceLabel: 'BALANCE:',
      DateResult: () => <DynamicInfoFrame noPadX>{dueDate}</DynamicInfoFrame>,
      BalanceResult: () => <DynamicInfoFrame noPadX>$ {duesBalance}</DynamicInfoFrame>,
      showPayDuesButton: true
    }
  },

  current: (me) => {
    const duesBalance = getDefaultDueBalance(me)

    return {
      name: 'current',
      Component: ExtendedDuesView,
      header: <NextCycleDate />,
      dateLabel: 'STATUS:',
      balanceLabel: 'BALANCE:',
      DateResult: () => <DynamicInfoFrame noPadX className='text-success'>Current</DynamicInfoFrame>,
      BalanceResult: () => <DynamicInfoFrame noPadX>$ {duesBalance}</DynamicInfoFrame>,
      showPayDuesButton: false
    }
  }
}

const getView = cond([
  [isTerminated, Views.pastGracePeriod],
  [hasDueBalance, Views.hasDueBalance],
  [T, Views.current]
])

export default (props) => {
  const { me } = props
  const { Component, ...config } = getView(me)

  return <Component {...props} config={config} />
}
