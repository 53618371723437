import React from 'react'
import styled from 'styled-components'
import { primary } from '../../assets/colors'
import { Montserrat } from '../../assets/fonts'

const MessageBox = styled.div`
  color: ${primary};
  text-align: justify;
  font-size: 16px;
  font-family: ${Montserrat};
  font-weight: 500;
`
export default ({ subject }) => (
  <MessageBox>
    <p>Hello!</p>
    <p>
      It looks like there is a problem {subject}.
      <span> Please contact us at <a href='tel:800-474-2776'>800-474-2776</a></span> or <span><a href='mailto:membership@pgahq.com'>membership@pgahq.com</a></span> so that we can correct the issue.
    </p>
    <p>Thank you!</p>
  </MessageBox>
)
