import { GET_LOADING, GET_IS_SAVED } from '../../components/queries/loading'
import { GET_EDITING_COMPS, GET_DIRTY_COMPS } from '../../components/queries/editProfile'

const __typename = 'Auth'

export const defaults = {
  auth: {
    isLoggedIn: false,
    silentRenewalTokenUri: null,
    __typename
  },
  user: {
    firstName: null,
    lastName: null,
    email: null,
    entityId: null,
    __typename: 'User'
  },
  isLoading: {
    component: [],
    __typename: 'isLoading'
  },
  savedSuccess: {
    isSaved: false,
    __typename: 'savedSuccess'
  },
  isEditing: {
    component: [],
    __typename: 'isEditing'
  },
  isDirty: {
    component: [],
    __typename: 'isDirty'
  }
}

export const resolvers = {
  Mutation: {
    setLoading: (_, { component }, { cache }) => {
      const data = getAddComponentData('isLoading', component)

      cache.writeQuery({
        query: GET_LOADING,
        data
      })

      return null
    },
    removeLoading: (_, { component }, { cache }) => {
      const data = getRemovedComponentData(GET_LOADING, cache, component, 'isLoading')

      cache.writeQuery({
        query: GET_LOADING,
        data
      })

      return null
    },
    setSaveSuccess: (_, { isSaved }, { cache }) => {
      const data = {
        savedSuccess: {
          isSaved,
          __typename: 'savedSuccess'
        }
      }

      cache.writeQuery({
        query: GET_IS_SAVED,
        data
      })

      return null
    },
    setEditingComponent: (_, { component }, { cache }) => {
      const data = getAddComponentData('isEditing', component)

      cache.writeQuery({
        query: GET_EDITING_COMPS,
        data
      })

      return null
    },
    removeEditing: (_, { component }, { cache }) => {
      const data = getRemovedComponentData(GET_EDITING_COMPS, cache, component, 'isEditing')

      cache.writeQuery({
        query: GET_EDITING_COMPS,
        data
      })

      return null
    },
    setDirtyComponent: (_, { component }, { cache }) => {
      const data = getAddComponentData('isDirty', component, 'Dirty')

      cache.writeQuery({
        query: GET_DIRTY_COMPS,
        data
      })

      return null
    },
    removeDirtyComponent: (_, { component }, { cache }) => {
      const data = getRemovedComponentData(GET_DIRTY_COMPS, cache, component, 'isDirty')

      cache.writeQuery({
        query: GET_DIRTY_COMPS,
        data
      })

      return null
    }
  }
}

export const getAddComponentData = (fieldName, component) => ({
  [fieldName]: {
    component: [component],
    __typename: fieldName
  }
})

export const getRemovedComponentData = (query, cache, component, fieldName) => {
  const currentComponent = cache.readQuery({ query })
  const res = currentComponent[fieldName].component.filter(comp => comp !== component)

  return {
    [fieldName]: {
      component: [...res],
      __typename: fieldName
    }
  }
}

export default undefined
