import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import { cond, always, T, prop } from 'ramda'

const getBgColor = cond([
  [prop('lightBgGrey'), always(Theme.colors.lightBgGrey)],
  [prop('white'), always(Theme.colors.white)],
  [T, always(Theme.colors.bgGrey)]
])

export const ContentWrapper = styled.div`
  font-size: 12px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${getBgColor};
  padding-bottom: 50px;
`
