import React from 'react'
import { NavLink } from 'react-router-dom'
import { pathOr } from 'ramda'
import { getUserEnrollment } from './utils'
import { MainWrapper } from '../shared'
import {
  CardImage,
  Container,
  Header,
  SubHeader,
  Links,
  CardContainer,
  AngleRight,
  HeaderWrapper
} from './shared'

export const LadyBgImageUrl = 'https://res.cloudinary.com/pgahq/image/upload/v1567691952/front-end-assets/lifelong_learning.jpg'

export const Enrolled = ({ program: { label = '', portalUrl = '#' } = {} }) => (
  <CardContainer>
    <Container>
      <HeaderWrapper>
        <SubHeader>LIFELONG LEARNING</SubHeader>
        <Header>{label}</Header>
      </HeaderWrapper>
      <Links>
        <NavLink to='/education/programs'>Your Programs <AngleRight /></NavLink>
        <a target='_blank' rel='noopener noreferrer' href={portalUrl}>Education Portal <AngleRight /></a>
      </Links>
    </Container>
  </CardContainer>
)

export const NotEnrolled = () => (
  <CardContainer>
    <NavLink to='/education/programs'>
      <CardImage src={LadyBgImageUrl} />
    </NavLink>
  </CardContainer>
)

export default ({ me }) => {
  const currentPrograms = pathOr([], ['education', 'currentPrograms'], me)
  const currentEnrollment = getUserEnrollment(currentPrograms)

  return (
    <MainWrapper>
      {currentEnrollment ? <Enrolled program={currentEnrollment} /> : <NotEnrolled />}
    </MainWrapper>
  )
}
