import React from 'react'
import { times } from 'ramda'
import { white } from '../../../../assets/colors'
import { CardBox, InfoFrame, FlexColumn, LoadingPlaceholderResponsive } from '../../shared'
import { CARD_HEIGHT } from '.'

const LoadingDues = () => (
  <CardBox color={white} minHeight={CARD_HEIGHT}>
    <InfoFrame>
      {
        times((idx) => (
          <FlexColumn key={idx}>
            <LoadingPlaceholderResponsive h={15} />
            <LoadingPlaceholderResponsive h={15} />
            <LoadingPlaceholderResponsive h={35} />
          </FlexColumn>
        ), 2)
      }
    </InfoFrame>
  </CardBox>
)

LoadingDues.displayName = 'LoadingDues'

export default LoadingDues
