import React, { Fragment } from 'react'
import { Padding, Title, CardBox } from './shared'
import AnnouncementsList from './AnnouncementsList'
import NoAnnouncements from './NoAnnouncements'
import AnnouncementsModal from './AnnouncementsModal'

export const ANNOUNCEMENT_COUNT = 3

export const AnnouncementsWidget = ({ announcements }) => (
  <Padding>
    <Title text='ANNOUNCEMENTS' />
    {
      announcements.length
        ? (
          <>
            <AnnouncementsList items={announcements.slice(0, ANNOUNCEMENT_COUNT)} />
            {announcements.length > ANNOUNCEMENT_COUNT
              ? <AnnouncementsModal {...{ announcements: announcements.slice(ANNOUNCEMENT_COUNT, announcements.length) }} />
              : null}
          </>
          )
        : <NoAnnouncements />
    }
  </Padding>
)

export default ({ announcements = [], collapsed, card }) => (
  <>
    {
      !collapsed
        ? (
          <>
            {
              card
                ? (
                  <CardBox>
                    <AnnouncementsWidget {...{ announcements }} />
                  </CardBox>
                  )
                : <AnnouncementsWidget {...{ announcements }} />
            }
          </>
          )
        : null
    }
  </>
)
