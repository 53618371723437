import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { withAuthProvider } from '../../lib/auth'
import { Header, Nav, IconsBox, MobileNavigation, useEmailMigrationData, IframeRolex } from '@pga/pga-component-library'
import MediaQuery from 'react-responsive'
import { useQuery, useMutation } from '@apollo/client'

import logo from '../../assets/img/logo.svg'
import { primary, gold } from '../../assets/colors'
import options, { getDropdownLinks, getMobileOptions } from './options'
import AuthHeader from '../AuthHeader'
import { Montserrat } from '../../assets/fonts'
import { setLoading, removeLoading } from '../queries/loading'
import { EMAIL_MIGRATION_QUERY, EMAIL_MIGRATION_MUTATION } from '../queries/member'

const EditLink = styled(NavLink)`
  font-family: ${Montserrat};
  color: #fff;
  font-size: 16px;
  position: relative;

  &.active {
    font-weight: 700;
  }
  &.active::before {
    content: "";
    position: absolute;
    vertical-align: middle;
    width: 100%;
    color: ${gold};
    bottom: -10px;
    border-bottom: 4px solid;
  }

  :hover, &.active {
    text-decoration: none;
    color: #fff;
  }
`

const LinkStyled = styled(NavLink)`
  color: #fff;
  text-decoration: none;
  font-family: ${Montserrat};
  font-weight: 700;
  font-size: 16px;
  padding-left: 20px;
  width: 100%;
  flex-grow: 45;
  position: relative;

  &.active::before {
    content: "";
    position: absolute;
    vertical-align: middle;
    width: 100%;
    color: ${gold};
    bottom: -31px;
    left: 9%;
    border-bottom: 4px solid;
  }

  :hover, &.active {
    text-decoration: none;
    color: #fff;
  }
`

export const HeaderComp = ({ isLoggedIn, authReady, me }) => {
  const queryProps = { query: EMAIL_MIGRATION_QUERY, mutation: EMAIL_MIGRATION_MUTATION }
  const apolloProps = { useQuery, useMutation }
  const { loading, meEmailMigrationData, onToggleDoNotShowAgain } = useEmailMigrationData(queryProps, apolloProps)

  !authReady || loading ? setLoading('header') : removeLoading('header')

  const dropdownLinks = getDropdownLinks(me)
  const mobileOptions = getMobileOptions(me)

  return (
    <Header {...{ logo, logoLink: '/', bgColor: primary }}>
      <MediaQuery minWidth={992}>
        <Nav {...{ options, comp: LinkStyled, b: -31, l: 10 }} />
        <div className='d-flex align-items-center'>
          <IframeRolex />
          <IconsBox {...{ isLoggedIn, meEmailMigrationData, onToggleDoNotShowAgain, me }} />
        </div>
        <AuthHeader {...{ isLoggedIn, me, authReady, options: dropdownLinks, meEmailMigrationData, onToggleDoNotShowAgain }} />
      </MediaQuery>
      <MediaQuery maxWidth={991}>
        {authReady
          ? <MobileNavigation {...{ linkComponent: EditLink, options: mobileOptions, me, comp: EditLink, b: -7, l: 11, isLoggedIn, meEmailMigrationData, onToggleDoNotShowAgain }} vertical />
          : null}
      </MediaQuery>
    </Header>
  )
}

export default withAuthProvider(HeaderComp)
