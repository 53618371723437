import { iso31661 } from 'iso-3166'

export const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Non-Binary', label: 'Non-Binary' },
  { value: 'Other', label: 'Other' }
]

export const yesNo = [
  { value: null, label: 'Select An Option' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
]

export const vetStatuses = [
  { value: 'Veteran', label: 'Veteran' },
  { value: 'Reserves', label: 'Reserves' },
  { value: 'National Guard', label: 'National Guard' },
  { value: 'Active Duty', label: 'Active Duty' }
]

export const reasons = [
  { value: 'Prestige associated with PGA Membership', label: 'Prestige associated with PGA Membership' },
  { value: 'Networking Opportunities', label: 'Networking Opportunities' },
  { value: 'Education programs/offerings', label: 'Education programs/offerings' },
  { value: 'Career services and career development resources', label: 'Career services and career development resources' },
  { value: 'PGA is an advocate for its members', label: 'PGA is an advocate for its members' }
]

export const jobTitles = [
  { value: 'Assistant General Manager', label: 'Assistant General Manager' },
  { value: 'Assistant Golf Professional', label: 'Assistant Golf Professional' },
  { value: 'Assistant Superintendent', label: 'Assistant Superintendent' },
  { value: 'Association Administration', label: 'Association Administration' },
  { value: 'Association Executive', label: 'Association Executive' },
  { value: 'Back of House', label: 'Back of House' },
  { value: 'Caddie/Caddie Management', label: 'Caddie/Caddie Management' },
  { value: 'Club Fitter', label: 'Club Fitter' },
  { value: 'Club Technician', label: 'Club Technician' },
  { value: 'Clubhouse Manager', label: 'Clubhouse Manager' },
  { value: 'College/University/High School Golf Coach', label: 'College/University/High School Golf Coach' },
  { value: 'Director of Agronomy/Superintendent', label: 'Director of Agronomy/Superintendent' },
  { value: 'Director of Golf', label: 'Director of Golf' },
  { value: 'Director of Instruction', label: 'Director of Instruction' },
  { value: 'Director of Retail', label: 'Director of Retail' },
  { value: 'Executive Director', label: 'Executive Director' },
  { value: 'F&B Manager', label: 'F&B Manager' },
  { value: 'Foundation Operations', label: 'Foundation Operations' },
  { value: 'Front of House', label: 'Front of House' },
  { value: 'General Manager', label: 'General Manager' },
  { value: 'General Manager/Head Golf Professional', label: 'General Manager/Head Golf Professional' },
  { value: 'Golf Shop Staff', label: 'Golf Shop Staff' },
  { value: 'Head Golf Professional', label: 'Head Golf Professional' },
  { value: 'Independent Contractor', label: 'Independent Contractor' },
  { value: 'Junior Golf', label: 'Junior Golf' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Media', label: 'Media' },
  { value: 'Membership Sales', label: 'Membership Sales' },
  { value: 'Merchandiser', label: 'Merchandiser' },
  { value: 'Off-Course Retail', label: 'Off-Course Retail' },
  { value: 'Outside Golf Operations', label: 'Outside Golf Operations' },
  { value: 'Player Development', label: 'Player Development' },
  { value: 'Project Manager', label: 'Project Manager' },
  { value: 'Regional Manager', label: 'Regional Manager' },
  { value: 'Regional/Multi-Facility Manager', label: 'Regional/Multi-Facility Manager' },
  { value: 'Sales Representative', label: 'Sales Representative' },
  { value: 'Teaching Professional', label: 'Teaching Professional' },
  { value: 'Tournament Operations', label: 'Tournament Operations' },
  { value: 'Tournaments/Events Management', label: 'Tournaments/Events Management' },
  { value: 'Other', label: 'Other' }
]

export const howTheyHeardOptions = [
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'PGA Email', label: 'PGA Email' },
  { value: 'PGA Event', label: 'PGA Event' },
  { value: 'PGA Staff Member', label: 'PGA Staff Member' },
  { value: 'TikTok', label: 'TikTok' },
  { value: 'Twitter', label: 'Twitter' },
  { value: 'TV Ad', label: 'TV Ad' },
  { value: 'Other', label: 'Other' }
]

export const countryOptions = iso31661.map(i => ({ value: i.alpha2, label: i.name }))
