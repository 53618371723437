import { gql } from '@apollo/client'

export const applicationDecisionQuery = gql`
  query ApplicationDecisionQuery {
    me {
      id
      education {
        currentCppProgramStatus
        currentMppProgramStatus
        currentMppApplicationInfo {
          applicationId
          latestApplicationStatusCode
          careerPathName
          applicationStatusStartDate
          careerPathCode
        }
        currentCppApplicationInfo {
          applicationId
          latestApplicationStatusCode
          careerPathName
          applicationStatusStartDate
          careerPathCode
        }
      }
    }
  }
`
