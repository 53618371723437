import styled from 'styled-components'
import { lightBlue } from '../assets/colors'
import { Button as RSButton } from 'reactstrap'

export const Button = styled(RSButton)`
  padding: ${props => (props.outline && '13px 15px') || '15px 20px'};
  border: ${props => (props.outline && `solid 2px ${lightBlue}`) || 'none'};
  color: ${props => (props.outline && lightBlue) || 'white'};
  background-color: ${props => (props.outline && 'transparent') || lightBlue};
  border-radius: 0;
  min-width: 120px;
  white-space: nowrap;
  margin: ${props => props.margin || '5px'};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  text-transform: uppercase;
  font-size: 12px
  ${({ fw }) => fw && 'width: 100%;'};
`
export const ButtonFw = styled(Button)`
  width: 100%;
`

export default undefined
