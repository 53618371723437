import React, { useRef, useState } from 'react'
import { FaQuestionCircle } from 'react-icons/fa'
import { Theme } from '@pga/pga-component-library'
import styled from 'styled-components'
import { Tooltip } from 'reactstrap'

export const StyledTooltip = styled(Tooltip)`
  opacity: 1 !important;
  filter: alpha(opacity=100);
  .tooltip-inner {
    background-color: ${Theme.colors.primary};
    font-family: ${Theme.fonts.Montserrat};
    font-size: ${props => props.fs || '13px'};
    color: ${Theme.colors.white};
    white-space: pre-wrap;
    border-radius: 0px;
    border: 1px solid ${Theme.colors.primary};
    width: 200px;
    padding: 10px 15px;
    font-weight: ${props => props.fw || 600};
    text-align: left;
    margin-top: 4px;
  }
  .arrow:after,
  .arrow:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .arrow:before{
    border-color: rgba(151, 151, 151, 0);
    border-bottom-color: ${Theme.colors.primary};
    border-width: 11px;
    margin-left: -11px;
    top: -9px !important;
  }
  .arrow:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: ${Theme.colors.primary};
    border-width: 10px;
    margin-left: -10px;
    top: -8px !important;
  }
`

const TooltipTarget = styled.span`
  color: ${Theme.colors.lightBlue};
  font-weight: 800;
  cursor: pointer;
`

const DisclaimerToolTip = ({ target, disclaimerText, size = 16 }) => {
  const [isOpen, setIsOpen] = useState(false)
  const tooltipRef = useRef(null)

  return (
    <>
      <TooltipTarget color={Theme.colors.blueGrey} id={target} ref={tooltipRef}>
        <FaQuestionCircle size={size} />
      </TooltipTarget>
      <StyledTooltip
        isOpen={isOpen}
        placement='bottom'
        autohide={false}
        target={tooltipRef}
        fs='12px'
        fw='400'
        toggle={() => setIsOpen(!isOpen)}
      >
        {disclaimerText}
      </StyledTooltip>
    </>
  )
}

export default DisclaimerToolTip
