import { Theme } from '@pga/pga-component-library'
import { formatDate, showExpiredOrDate, showExpiresOrNull } from '../../../../utils/prospect'
import { checkStatus, findMostRecentPAT } from '../../../../utils/prospect/getStatus'
import DisclaimerToolTip from '../../../DisclaimerTooltip'
import QualifyingLevel from './QualifyingLevel'
import BackgroundCheckDepartureLink from './BackgroundCheckDepartureLink'

export const canBackgroundCheck = (backgroundCheckStatus, orderIsPending) => {
  if (orderIsPending) {
    return false
  }
  return !backgroundCheckStatus || checkStatus('EXPIRED')(backgroundCheckStatus)
}

const backgroundCheckSection = (data) => {
  const backgroundCheck = data?.prospect?.backgroundCheck
  const createdOrExpired = showExpiredOrDate(
    backgroundCheck?.status,
    backgroundCheck?.expiredAt,
    backgroundCheck?.createdAt
  )
  const screenedAtOrExpired = showExpiredOrDate(
    backgroundCheck?.status,
    backgroundCheck?.expiredAt,
    backgroundCheck?.screenedAt
  )
  const order = data?.prospect?.backgroundCheckOrder
  const orderIsPending = order?.awaitingModeration
  const orderStatus = orderIsPending ? 'PENDING' : null

  return {
    disclaimer:
      !orderIsPending && (
        <DisclaimerToolTip
          target='backgroundCheck'
          disclaimerText='If you recently requested a background check but have not yet heard back, please wait instead of requesting another.'
        />
      ),
    expiresText: showExpiresOrNull(backgroundCheck?.status, backgroundCheck?.expiredAt),
    title: 'Background Check',
    description:
      'You must register or renew and complete a background check in order to register as an Associate or Affiliate.',
    linkComponent:
      canBackgroundCheck(backgroundCheck?.status, orderIsPending) && (
        <BackgroundCheckDepartureLink order={order} />
      ),
    action: !canBackgroundCheck(backgroundCheck?.status, orderIsPending),
    status: backgroundCheck?.status || orderStatus,
    date: screenedAtOrExpired || createdOrExpired
  }
}

const playingAbilityTestSection = (data) => {
  const playingAbilityTest = data?.prospect?.playingAbilityTest

  return {
    disclaimer: (
      <DisclaimerToolTip
        target='pat'
        disclaimerText='If you have already registered, please note that the status will remain unavailable until the results are in.'
      />
    ),
    expiresText: showExpiresOrNull(
      findMostRecentPAT(playingAbilityTest)?.status,
      findMostRecentPAT(playingAbilityTest)?.expiredAt
    ),
    title: 'Playing Ability Test',
    description:
      'You must receive a passing or qualifying score in order to register as an Associate. Not required for Affiliate.',
    link: 'https://apps.pga.org/patinfo/patresults.cfm',
    action: 'Register For PAT',
    status: findMostRecentPAT(playingAbilityTest)?.status,
    date: formatDate(findMostRecentPAT(playingAbilityTest)?.testDate)
  }
}

const qualifyingLevelSection = (data, history) => {
  const qualifyingLevel = data?.prospect?.qualifyingLevel
  const materialsPurchased = qualifyingLevel?.materialsPurchased
  const isExpired = checkStatus('EXPIRED')(qualifyingLevel?.status)

  return {
    expiresText: showExpiresOrNull(qualifyingLevel?.status, qualifyingLevel?.expiredAt),
    title: 'Qualifying Level',
    description:
      'You must purchase and complete the Qualifying Level course in order to become an associate or affiliate.',
    linkComponent:
      !materialsPurchased && !isExpired ? <QualifyingLevel {...{ qualifyingLevel, history }} /> : null,
    link: materialsPurchased && !isExpired ? 'https://pgaofamerica.instructure.com/' : null,
    action: materialsPurchased && !isExpired ? 'Access Education Portal' : null,
    status: qualifyingLevel?.status,
    date: showExpiredOrDate(
      qualifyingLevel?.status,
      qualifyingLevel?.expiredAt,
      qualifyingLevel?.startDate
    )
  }
}

const employmentSection = {
  title: 'Eligible Employment',
  description:
    'You must be employed in an eligible position not less than 30 hours per week within one of the approved classifications in order to register as an Associate. This is not required for Affiliate.',
  link: 'https://jobs.pga.org',
  action: 'View the Job Board',
  bg: Theme.colors.lightBgGrey
}

export default (data, history) => [
  { ...backgroundCheckSection(data) },
  { ...playingAbilityTestSection(data) },
  { ...qualifyingLevelSection(data, history) },
  { ...employmentSection }
]
