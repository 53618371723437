import React from 'react'
import styled from 'styled-components'
import { withAuthProvider } from '../../lib/auth'
import { Theme, SecondaryNav, IframeRolex } from '@pga/pga-component-library'
import navigationFor from './NavOptions'

export const SubNavFrame = styled.div`
  @media (max-width: 997.98px) {
    width: 100%;
    box-shadow: 0 5px 10px 0 ${Theme.colors.primaryShadow};
  }
`

export const SubNavigation = ({ me, loading }) =>
  !loading
    ? (
      <SubNavFrame>
        <SecondaryNav options={navigationFor(me)} title='My Account' menuTitle='Account Menu' />
        <IframeRolex mobile />
      </SubNavFrame>
      )
    : null

export default withAuthProvider(SubNavigation)
