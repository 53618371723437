import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'ramda'
import styled, { keyframes } from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import { GET_IS_SAVED } from '../queries/loading'

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: ${Theme.colors.successGreen};
  z-index: 20;
`

const Text = styled.p`
  font-family: ${Theme.fonts.Montserrat};
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin: 0;
`

const CircleCheckIcon = styled.i.attrs({
  className: 'fa fa-check-circle-o fa-2x'
})`
  color: white;
  width: 30px;
  height: 30px;
  padding-top: 2px;
`

const fromTop = keyframes`
  0% {
    top: ${window.pageYOffset} - 60px;
  }
  100% {
    top: ${window.pageYOffset}px;
  }
`

export const SliderAnimationWrapper = styled.div`
  display: inline-block;
  height: 100%
  width: 100%;
  position: sticky;
  z-index: 101;
  animation-name: ${fromTop};
  animation-duration: .5s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
`

const getIsSaved = graphql(GET_IS_SAVED, {
  props: ({ data }) => ({
    data,
    isSaved: data.savedSuccess.isSaved
  })
})

export const SaveSuccess = ({ isSaved }) =>
  isSaved
    ? (
      <SliderAnimationWrapper>
        <Wrapper>
          <CircleCheckIcon />
          <Text>CHANGES SAVED</Text>
        </Wrapper>
      </SliderAnimationWrapper>
      )
    : null

export default compose(getIsSaved)(SaveSuccess)
