import React from 'react'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody as RSModalBody, Title, TitleBar } from '../Modal'

const TitleBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  & span {
    margin-left: 20px;
  }
  @media(max-width: 667px){
    justify-content: center;
    & span {
      margin-left: 0;
    }
  }
`
const ModalBody = styled(RSModalBody)`
  padding: 20px 0px 20px 0px;
`
const ModalBodyCopy = styled.div`
  padding: 0px 40px 20px 40px;
`

export default ({ open, onToggleModal, title, message }) => (
  <Modal
    isOpen={open}
    toggle={() => onToggleModal(!open)}
    size='lg'
    centered
  >
    <ModalHeader toggle={() => onToggleModal(!open)} />
    <TitleBar>
      <TitleBox>
        <Title>{title}</Title>
      </TitleBox>
    </TitleBar>
    <ModalBody>
      <ModalBodyCopy>{message}</ModalBodyCopy>
    </ModalBody>
  </Modal>
)
