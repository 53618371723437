import React from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'ramda'
import { getMember } from '../../queries/member'
import ProfileWidget, { LoadingProfile } from './Profile'
import CompactProfileWidget, { LoadingCompactProfile } from './CompactProfile'
import ErrorLoadingProfile from './ErrorLoadingProfile'
import WrapWithFetch from '../WrapWithFetch'

const attachQuery = compose(
  graphql(getMember, {
    props: ({ data }) => ({
      data,
      loading: data.loading,
      user: data.me,
      error: data.error,
      refetch: data.refetch
    })
  })
)

export const CompactProfileBrief = attachQuery(({
  user,
  loading
}) => (loading && <LoadingCompactProfile />) || <CompactProfileWidget user={user} />)

const ProfileBrief = WrapWithFetch(({
  user,
  loading,
  isFetching,
  error,
  refetch,
  reload,
  vertical
}) => {
  if (loading || isFetching) {
    return <LoadingProfile vertical={vertical} />
  } else if (error || !user) {
    return <ErrorLoadingProfile {...{ refetch, reload }} />
  }

  return (
    <ProfileWidget
      {...{
        user,
        error,
        refetch
      }}
      vertical={vertical}
    />
  )
})

export default attachQuery(ProfileBrief)
