import React, { Fragment, useState } from 'react'
import { filledPercent } from './utils'
import { getPDRCredits } from './config'
import { gte } from 'ramda'

import { MSRTrackingBar, MSRTrackingBox, MSRLabel, Wrapper, Completed, MSRCountBox, Separator, TotalDeficitWrapper, TotalDeficitCounter, BarContainer, CheckIcon, CompletedText, PDRText, QuestionIcon, ModalContainer, ModalTitle, ModalContent } from './Styles/Shared.styles'
import { IoClose } from 'react-icons/io5'

const MSRCount = ({ completed, total, color }) => {
  return (
    <>
      <MSRCountBox color={color}>
        <Completed color={color}>{completed} of {total}</Completed>
      </MSRCountBox>
    </>
  )
}

export const MoreDetailsModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpenClose = () => setIsOpen(!isOpen)
  return (
    <>
      <QuestionIcon onClick={handleOpenClose} />
      <ModalContainer isOpen={isOpen} toggle={handleOpenClose} className='modal-dialog-centered'>
        <div className='modal-header border-0'>
          <ModalTitle>What are Recovery Credits?</ModalTitle>
          <button type='button' className='close' onClick={handleOpenClose}>
            <span aria-hidden='true'><IoClose /></span>
          </button>
        </div>
        <div className='modal-body'>
          <ModalContent>
            Notice: PDRs earned will apply to recovery credits before they are applied to the current cycle's requirements.
          </ModalContent>
          <ModalContent>
            Members in good standing who end a PDR cycle with a deficit will fall into Class F and must earn Recovery credits
            before restoring their good standing status. Recovery credits are defined as the number of credits in deficit from the
            last cycle plus the same number of credits in the current cycle. For more information about your situation, please <a href='mailto:membership@pgahq.com'>contact Member Support</a>.
          </ModalContent>
        </div>
      </ModalContainer>
    </>
  )
}

export const RecoveryCount = ({ totalDeficit }) => {
  const hasDeficit = totalDeficit > 0
  return (
    <TotalDeficitWrapper>
      <TotalDeficitCounter color={hasDeficit ? '#FF5247' : '#000000'}>
        {totalDeficit}
      </TotalDeficitCounter>
      <PDRText>Recovery Credits Remaining</PDRText>
      <MoreDetailsModal />
    </TotalDeficitWrapper>
  )
}

export const PDRTracking = ({ completed, total, color, label, recovery, totalDeficit }) => {
  const percent = filledPercent(total)
  const isComplete = gte(completed, total)
  return (
    <MSRTrackingBox>
      <MSRCount completed={completed} recovery={recovery} total={total} color={color} />
      {!!recovery && <RecoveryCount totalDeficit={totalDeficit} />}
      <BarContainer>
        <MSRTrackingBar completed={percent(completed)} color={color} />
        {isComplete
          ? (
            <CheckIcon color={color} size={25} />
            )
          : (
            <CompletedText color={color}>{completed}</CompletedText>
            )}
      </BarContainer>
      <MSRLabel>{label}</MSRLabel>
      <Separator className='mt-3 mb-3' horizontal />
    </MSRTrackingBox>
  )
}

export default (me) => (
  <Wrapper>
    {getPDRCredits(me).map((pdr, i) => (
      <PDRTracking
        completed={pdr.earned}
        total={pdr.required}
        label={pdr.label}
        color={pdr.color}
        recovery={pdr.recovery}
        totalDeficit={pdr.totalDeficit}
        new={pdr.new}
        key={i}
      />
    ))}
  </Wrapper>
)
