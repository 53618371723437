import React from 'react'
import { withConfig } from '../../utils/index'
import { FixedWidthButton } from '../sharedComps'
import MppProvisionBase from './MppProvisionBase'

export const MppProvisionButtonView = ({ wide, onClick, className, buttonText }) => (
  <FixedWidthButton {...{ wide, onClick, className }}>
    {buttonText}
  </FixedWidthButton>
)

export const MppProvisionButton = withConfig(MppProvisionButtonView, 'ButtonComp')(MppProvisionBase)

MppProvisionButton.defaultProps = {
  text: 'Get Started',
  redirectUrl: '/education/programs/mpp/thank-you'
}

export default MppProvisionButton
