import React, { Fragment } from 'react'
import { isMember } from '../utils'
import bannerOptions from './bannerOptions'
import { Wrapper, Title, TitleLink } from './styles'

export const GenericBanner = ({ color, textColor, title, link, linkText, removeMarginTop }) => {
  return (
    <Wrapper color={color} removeMarginTop={removeMarginTop}>
      <Title textColor={textColor}>{title}</Title>
      {link && <TitleLink href={link} textColor={textColor}>{linkText}</TitleLink>}
    </Wrapper>
  )
}

export default ({ me }) => {
  return (
    <>
      {bannerOptions.map((banner, index) => {
        const displayForMembersOrPublic = banner.membersOnly ? banner.showBanner && isMember(me) : banner.showBanner
        return (
          displayForMembersOrPublic
            ? <GenericBanner key={index} {...banner} />
            : null)
      })}
    </>
  )
}
