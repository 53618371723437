import React from 'react'
import moment from 'moment'
import ReactTable from 'react-table'
import {
  columns,
  CellStyle,
  TableStyle,
  NoDataStyle
} from './shared'
import { getMsrHistory } from '../../queries/member'
import { getMeData } from '../../utils'
import 'react-table/react-table.css'
import './react-table-override.css'

const Loading = () => (
  <div className='d-flex justify-content-center mb-3 mt-3'>
    <div className='spinner-border text-secondary' role='status'>
      <span className='sr-only'>Loading...</span>
    </div>
  </div>
)

export const MsrHistoryTable = ({ loading, me, error = null }) => {
  if (loading) return <Loading />
  const history = me ? me.msrHistory : []
  const tableData = history
    ? history.map(({
      addedDate,
      creditsAccepted,
      description1
    }) => ({
      addedDate: moment(addedDate).format('MM/DD/YYYY'),
      creditsAccepted,
      description1
    }))
    : []
  const noDataMsg = error ? 'We\'re sorry.  There was an error retrieving your history.' : 'No History Found'
  return (
    <ReactTable
      data={tableData}
      columns={columns}
      getTheadProps={() => ({ style: CellStyle })}
      getTbodyProps={() => ({ style: TableStyle })}
      getNoDataProps={() => ({ style: NoDataStyle })}
      noDataText={noDataMsg}
    />
  )
}

export default getMeData(getMsrHistory)(MsrHistoryTable)
