import Select from 'react-select'
import styled from 'styled-components'
import { Theme, Button as PGAButton } from '@pga/pga-component-library'

export const StyledLink = styled.span`
  color: ${Theme.colors.lightBlue};
  cursor: pointer;
  justify-content: center;
  margin-top: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`

export const ModalLink = styled.span`
  color: ${Theme.colors.lightBlue};
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`

export const StyledSelect = styled(Select)`
  .css-yk16xz-control {
    border: 1px solid #CFD4D9;
    border-radius: 5px;
    width: 100% !important;
    height: 42px;
    font-size: 14px;
    outline: none;
  }
  &::placeholder {
    color: ${Theme.colors.blueGrey};
  }
`

export const Required = styled.span`
  color: ${Theme.colors.maroon};
`

export const StyledInputRange = styled.div`
  & div.input-range__track--active, div.input-range__slider {
    border: 1px solid ${Theme.colors.lightBlue};
    background: ${Theme.colors.lightBlue};
  }
  padding: 10px 0;
`

export const StyledInput = styled.input`
  width: 100%;
  height: 42px;
  border: 1px solid #CFD4D9;
  border-radius: 5px;
  padding: 0 12px;
  font-size: 14px;
  outline: none;
  &::placeholder {
    color: ${Theme.colors.blueGrey};
  }
  &:focus {
    border: 2px solid ${Theme.colors.lightBlue};
  }
`

export const selectOverride = {
  control: (provided, state) => ({
    ...provided,
    height: '42px',
    boxShadow: state.isFocused ? `0 0 0 1px ${Theme.colors.lightBlue}` : null,
    borderColor: state.isFocused ? Theme.colors.lightBlue : 'hsl(0, 0%, 80%)',
    '&:hover': {
      borderColor: state.isFocused ? Theme.colors.lightBlue : 'hsl(0, 0%, 80%)'
    }
  })
}

export const Button = styled(PGAButton)`
  width: 100%;
`

export const ErrorText = styled.span`
  color: ${Theme.colors.maroon};
  font-size: 13px;
  text-align: ${props => props.align || 'left'};
`

export const FormError = styled.p`
  font-size: 13px;
  color: ${props => props.red && Theme.colors.maroon};
  text-align: center;
`

export const ContactMembership = styled.span`
  font-size: 13px;
  color: ${Theme.colors.lightBlue};
  font-weight: 500;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const InputLabel = styled.span`
  font-size: 13px;
  color: ${Theme.colors.primary};
  font-weight: 500;
`
