import React from 'react'
import { withConfig } from '../../utils/index'
import { StyledRenewLink } from '../sharedComps'
import { LinkIcon } from '@pga/pga-component-library'
import MppProvisionBase from './MppProvisionBase'

export const MppProvisionRenewLinkView = ({ onClick, className, buttonText }) => (
  <StyledRenewLink {...{ onClick, className }}>
    {buttonText}<LinkIcon />
  </StyledRenewLink>
)

export const MppRenewLink = withConfig(MppProvisionRenewLinkView, 'ButtonComp')(MppProvisionBase)

MppRenewLink.defaultProps = {
  text: 'Renew',
  redirectUrl: '/education/programs/mpp/thank-you'
}

export default MppRenewLink
