import React, { Fragment } from 'react'
import { InfoLabel } from '../../../shared'
import CardFooter from '../Footer'
import { Layout, LeftHalf, RightHalf } from '../styled'

const ExtendedDuesView = ({ me, config }) => {
  const { header, dateLabel, balanceLabel, DateResult, BalanceResult } = config

  return (
    <>
      <InfoLabel fs='12px'>DUES |{header}</InfoLabel>
      <Layout className='mt-4 mt-md-3'>
        <LeftHalf>
          <InfoLabel>{dateLabel}</InfoLabel>
          <DateResult />
        </LeftHalf>
        <RightHalf>
          <InfoLabel>{balanceLabel}</InfoLabel>
          <BalanceResult />
        </RightHalf>
      </Layout>
      <CardFooter {...config} me={me} />
    </>
  )
}

export default ExtendedDuesView
