import React from 'react'
import { CardBox, InfoFrame, FlexColumn, InfoLabel, DuesButtons, MainWrapper } from '../../shared'
import { white, lightBlue } from '../../../../assets/colors'
import { CARD_HEIGHT } from './MembershipDues'

export default ({ refetch, reload }) => (
  <MainWrapper>
    <CardBox color={white} minHeight={CARD_HEIGHT}>
      <InfoFrame>
        <FlexColumn>
          <InfoLabel>This widget failed to load. Please refresh.</InfoLabel>
          <br />
          <DuesButtons onClick={() => reload(refetch)} width='100%' color={white} bgColor={lightBlue}>REFRESH</DuesButtons>
        </FlexColumn>
      </InfoFrame>
    </CardBox>
  </MainWrapper>
)
