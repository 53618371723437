import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { ActionLink, LinkIcon } from './styled'

export const DOWNLOAD_INVOICE_MUTATION = gql`
  mutation {
    createPdfInvoiceLink
  }
`

export const handleMutation = ({ mutate, action, error, loading, data, invoiceText, OnSuccess }) => {
  if (loading) return <p className='mb-0'>Loading...</p>
  if (error) return <p className='mb-0'>Error Loading</p>
  if (data) return <OnSuccess {...{ data, invoiceText }} />

  return (
    <ActionLink onClick={() => mutate()}>
      {action} {invoiceText}<LinkIcon />
    </ActionLink>
  )
}

const DownloadInvoiceSuccess = ({ data, invoiceText }) => {
  const { createPdfInvoiceLink } = data
  const downloadSignedUrl = href => Object.assign(document.createElement('a'), { href }).click()
  if (createPdfInvoiceLink) {
    downloadSignedUrl(createPdfInvoiceLink)
    return <p className='text-success mb-0'>{invoiceText} Ready</p>
  }
  return null
}

export const DownloadInvoice = () => (
  <Mutation mutation={DOWNLOAD_INVOICE_MUTATION}>
    {(mutate, props) => handleMutation({ ...props, action: 'Print', mutate, invoiceText: 'Invoices/Receipts', OnSuccess: DownloadInvoiceSuccess })}
  </Mutation>
)
