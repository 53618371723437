import { sortBy, prop, last, filter, pipe, defaultTo, and, propEq, pluck } from 'ramda'

export const programOptions = [
  {
    label: 'Specialized Professional Program',
    portalUrl: 'https://pgaofamerica.instructure.com',
    programCode: 'SPP',
    priority: 10
  },
  {
    label: 'Certified Professional Program',
    portalUrl: 'http://apps.pga.org/professionals/apps/education/lms/index.cfm?action=cp_req',
    programCode: 'CPP2',
    priority: 20
  },
  {
    label: 'Certified Professional Program',
    portalUrl: 'https://pgaofamerica.instructure.com',
    programCode: 'CPP3',
    priority: 30
  },
  {
    label: 'Master Professional Program',
    portalUrl: 'https://apps.pga.org/professionals/apps/education/lms/index.cfm?action=mp_progress',
    programCode: 'MPP',
    priority: 40
  },
  {
    label: 'Master Professional Program',
    portalUrl: 'https://apps.pga.org/professionals/apps/education/lms/index.cfm?action=mp_progress',
    programCode: 'MPP2',
    priority: 50
  },
  {
    label: 'Master Professional Program',
    portalUrl: 'https://pgaofamerica.instructure.com',
    programCode: 'MPP3',
    priority: 60
  }
]

export const getUserEnrolledPrograms = (programCodes) => (
  filter(({ programCode }) => (
    programCodes.includes(programCode)
  ), programOptions)
)

export const filterCurrentPrograms = filter((program) => and(
  propEq('currentStatus', 'Y', program),
  ['EXP', 'PROG'].includes(prop('programStatusCode', program))
))

export const getUserEnrollment = pipe(
  defaultTo([]),
  filterCurrentPrograms,
  pluck('programCode'),
  getUserEnrolledPrograms,
  sortBy(prop('priority')),
  last
)
