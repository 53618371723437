import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'

export const provisionMPPMutation = gql`
  mutation ProvisionMPP($id: ID!) {
    provisionMpp(id: $id) {
      success
    }
  }
`

export const withProvisionMPPMutation = graphql(provisionMPPMutation, {
  name: 'provisionMpp'
})
