import React from 'react'

export default WrappedComponent => (
  class DataComponentShell extends React.Component {
    constructor (props) {
      super(props)
      this.state = { isFetching: false }
      this.reload = this.reload.bind(this)
    }

    async reload (refetch) {
      this.setState({ isFetching: true })
      try {
        await refetch()
      } catch (e) {
        console.log('error in refresh:', e)
      } finally {
        this.setState({ isFetching: false })
      }
    }

    render () {
      return (
        <WrappedComponent
          isFetching={this.state.isFetching}
          reload={this.reload}
          {...this.props}
        />
      )
    }
  }
)
