import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import resources from './resources'
import { LinkFrame } from '../shared'

const Title = styled.div`
  color: ${Theme.colors.blueGrey};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
`

export const QuickLinksBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 25px 10px 25px;
  min-width: 280px;
  color: ${Theme.colors.primary};
  width: 100%;
  ${({ collapsed }) => collapsed ? 'border-right: none;' : `border-right: 2px solid ${Theme.colors.darkgrey};`}
  ${({ collapsed }) => collapsed && 'padding: 0 5px;'}
  ${({ collapsed }) => collapsed && 'margin: 10px;'}

  ${Title} ~ ${Title} {
    margin-top: 10px;
  }

  @media(max-width: 1199px){
    border-right: 0;
  }

  @media(max-width: 767px){
    border-right: 0;
    padding-top: 35px;
  }
`

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 310px;

  @media(max-width: 767px){
    max-height: none;
  }
`

export const Header = styled.div`
  color: ${Theme.colors.primaryWithOpacity};
  font-family: ${Theme.fonts.Montserrat};
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-transform: uppercase;
  margin-bottom: 20px;
`

const LinksBlock = styled.div`
  width: 48%;

  @media(max-width: 767px){
    width: 100%
  }
`
export default ({ collapsed }) =>
  <QuickLinksBox collapsed={collapsed}>
    <Header>Quick Links</Header>
    <ContentBox>
      {!collapsed &&
        resources.map(({ label, links }) => (
          <Fragment key={label}>
            <Title>{label}</Title>
            {
              links.map(link => <LinksBlock key={link.label}><LinkFrame key={link.label} href={link.url} rel='noopener noreferrer' target={link.target}>{link.title}</LinkFrame></LinksBlock>)
            }
          </Fragment>
        ))}
    </ContentBox>
  </QuickLinksBox>
