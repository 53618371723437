import { endOfDay } from './shared'

export default [
  {
    id: 143,
    credits: 1,
    name: 'How to Become a GameChanger Award Winner',
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '51m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=148&ems_event=180'
  },
  {
    id: 130,
    credits: 14,
    name: 'PGA Coach - Learning Center (6 Courses)',
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '45m+',
    link: 'https://vt.lightspeedvt.com/share/redirect.cfm?signInURL=https://webservices.lightspeedvt.net/SAML/lsvt/login.aspx?h=auth0.pga.com&dest=https://vt.lightspeedvt.com/trainingCenter/'
  },
  {
    id: 131,
    credits: 1,
    name: '2024 PGA Show - Negotiating Your Next Compensation Agreement',
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '61m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=131&ems_event=179'
  },
  {
    id: 132,
    credits: 1,
    name: '2024 PGA Show - Player Engagement Programming',
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '36m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=132&ems_event=179'
  },
  {
    id: 133,
    credits: 1,
    name: '2024 PGA Show - PGA of America Lifelong Learning',
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '31m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=133&ems_event=179'
  },
  {
    id: 134,
    credits: 1,
    name: '2024 PGA Show - PGA of America Town Hall Meeting',
    format: 'VIDEO',
    category: 'MEETING',
    duration: '53m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=134&ems_event=179'
  },
  {
    id: 135,
    credits: 1,
    name: '2024 PGA Show - PGA of America Candidate Forum',
    format: 'VIDEO',
    category: 'MEETING',
    duration: '39m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=135&ems_event=179'
  },
  {
    id: 136,
    credits: 1,
    name: '2024 PGA Show - Shop My Shop',
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '37m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=136&ems_event=179'
  },
  {
    id: 137,
    credits: 1,
    name: '2024 PGA Show - Unlocking Your Growth and Success - PGA Coach',
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '52m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=137&ems_event=179'
  },
  {
    id: 138,
    credits: 1,
    name: '2024 PGA Show - Recruitment and Retention',
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '57m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=138&ems_event=179'
  },
  {
    id: 139,
    credits: 1,
    name: '2024 PGA Show - Mental Health in the Workplace',
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '50m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=139&ems_event=179'
  },
  {
    id: 140,
    credits: 1,
    name: '2024 PGA Show - Corebridge Financial - Golf Retirement Plus/Deferred Comp',
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '48m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=140&ems_event=179'
  },
  {
    id: 141,
    credits: 1,
    name: '2024 PGA Show - PGA Value Guide/ 2nd Swing',
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '25m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=141&ems_event=179'
  },
  {
    id: 142,
    credits: 1,
    name: '2024 PGA Show - Showcase your Value',
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '58m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=142&ems_event=179'
  },
  {
    id: 118,
    credits: 1,
    name: '2023 PGA Show - ShopMyShop',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '21m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=118&ems_event=178'
  },
  {
    id: 119,
    credits: 1,
    name: '2023 PGA Show - Deferred Compensation Wednesday Session',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '24m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=119&ems_event=178'
  },
  {
    id: 120,
    credits: 1,
    name: '2023 PGA Show - Golf Retirement Plus',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '38m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=120&ems_event=178'
  },
  {
    id: 117,
    credits: 1,
    name: '2023 PGA Show - Recruiting PGA University Interns and Best Practices',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '58m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=117&ems_event=178'
  },
  {
    id: 121,
    credits: 1,
    name: '2023 PGA Show - Elevate Your Coaching Business',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '46m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=121&ems_event=178'
  },
  {
    id: 122,
    credits: 1,
    name: '2023 PGA Show - Work-Life Integration',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '60m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=122&ems_event=178'
  },
  {
    id: 123,
    credits: 1,
    name: '2023 PGA Show - Learn from the pros and Jump Start 2023',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '64m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=123&ems_event=178'
  },
  {
    id: 124,
    credits: 1,
    name: '2023 PGA Show - Deferred Compensation Thursday Session',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '25m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=124&ems_event=178'
  },
  {
    id: 125,
    credits: 1,
    name: '2023 PGA Show - Adaptive Golf',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '51m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=125&ems_event=178'
  },
  {
    id: 126,
    credits: 1,
    name: '2023 PGA Show - Reset Expectations and Reclaim Your Life',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '55m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=126&ems_event=178'
  },
  {
    id: 127,
    credits: 1,
    name: '2023 PGA Show - Town Hall Meeting',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '44m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=127&ems_event=178'
  },
  {
    id: 128,
    credits: 1,
    name: '2023 PGA Show - Creating you Career Roadmap',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '56m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=128&ems_event=178'
  },
  {
    id: 129,
    credits: 1,
    name: '2023 PGA Show - Drive Value and Community at your Facility',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '32m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=129&ems_event=178'
  },
  {
    id: 130,
    credits: 1,
    name: '2023 PGA Show - Attracting & Retaining Talent',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '61m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=130&ems_event=178'
  },
  {
    id: 100,
    credits: 1,
    name: "2022 PGA Show - Attracting Talent in Today's Workforce",
    expireDate: endOfDay('04/17/2023'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '52m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=116&ems_event=177'
  },
  {
    id: 101,
    credits: 1,
    name: '2022 PGA Show - Engage & Retain Golfers w/ PGA Jr. League',
    expireDate: endOfDay('04/17/2023'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '40m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=105&ems_event=177'
  },
  {
    id: 102,
    credits: 1,
    name: '2022 PGA Show - Coach Tools',
    expireDate: endOfDay('04/17/2023'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '52m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=106&ems_event=177'
  },
  {
    id: 103,
    credits: 1,
    name: '2022 PGA Show - New Home of the PGA, Frisco TX Update',
    expireDate: endOfDay('04/17/2023'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '50m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=107&ems_event=177'
  },
  {
    id: 104,
    credits: 1,
    name: '2022 PGA Show - ADM & PGA Coach',
    expireDate: endOfDay('04/17/2023'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '59m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=108&ems_event=177'
  },
  {
    id: 105,
    credits: 1,
    name: '2022 PGA Show - Sagacity, Operations Benchmark',
    expireDate: endOfDay('04/17/2023'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '61m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=109&ems_event=177'
  },
  {
    id: 106,
    credits: 1,
    name: '2022 PGA Show - Prepare for the Next Step',
    expireDate: endOfDay('04/17/2023'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '60m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=110&ems_event=177'
  },
  {
    id: 107,
    credits: 1,
    name: '2022 PGA Show - Gender Equity & Intersectionality',
    expireDate: endOfDay('04/17/2023'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '48m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=111&ems_event=177'
  },
  {
    id: 108,
    credits: 1,
    name: '2022 PGA Show - Cyber Security',
    expireDate: endOfDay('04/17/2023'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '38m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=112&ems_event=177'
  },
  {
    id: 109,
    credits: 1,
    name: '2022 PGA Show - Town Hall',
    expireDate: endOfDay('04/17/2023'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '40m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=113&ems_event=177'
  },
  {
    id: 110,
    credits: 1,
    name: '2022 PGA Show - Golf Retirement Plus',
    expireDate: endOfDay('04/17/2023'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '32m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=114&ems_event=177'
  },
  {
    id: 111,
    credits: 1,
    name: '2022 PGA Show - How to Present You & Your Materials',
    expireDate: endOfDay('04/17/2023'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '63m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=115&ems_event=177'
  },
  {
    id: 1,
    credits: 1,
    name: '2021 Annual Mtg - Recruitment, Retention, & Compensation',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '47m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=98&ems_event=176'
  },
  {
    id: 2,
    credits: 1,
    name: '2021 Annual Mtg - Government & Lobbying',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '25m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=99&ems_event=176'
  },
  {
    id: 3,
    credits: 1,
    name: '2021 Annual Mtg - Mike Loustalot (Sagacity Golf)',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '38m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=100&ems_event=176'
  },
  {
    id: 4,
    credits: 1,
    name: '2021 Annual Mtg - Will Robins (PGA.Coach)',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '33m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=101&ems_event=176'
  },
  {
    id: 5,
    credits: 1,
    name: '2021 Annual Mtg - PGA Frisco Update (John Easterbrook)',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '27m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=102&ems_event=176'
  },
  {
    id: 6,
    credits: 1,
    name: '2021 Annual Mtg - How Our Championships Help Drive Our Mission',
    expireDate: endOfDay('04/14/2024'),
    format: 'VIDEO',
    category: 'EDUCATION',
    duration: '37m',
    link: 'https://apps.pga.org/professionals/apps/education/video_education/index.cfm?video_id=103&ems_event=176'
  }
]
