import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Theme } from '@pga/pga-component-library'
import { Headline } from '../shared'
import { makeUrlParams } from './helpers'

const Title = styled.span`
  padding-right: 15px;

  @media (max-width: 576px) {
    padding-right: 0;
  }
`

const Sub = styled.div`
  color: ${Theme.colors.primaryWithOpacity};
  font-family: ${Theme.fonts.Monserrat};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding: 6px 0;
  text-transform: none;
`

const JOB_SEARCH_URL = 'https://jobs.pga.org/search/'

export default ({ jobIds }) => (
  <Headline>
    <Title>Recommended Jobs</Title>
    <Sub>Based on your <Link to='/job-preferences'>Job Preferences</Link></Sub>
    {jobIds.length > 4
      ? <Sub><a href={`${JOB_SEARCH_URL}${makeUrlParams(jobIds)}&recommended=true`}>View all Recommended Jobs &gt;</a></Sub>
      : null}
  </Headline>
)
