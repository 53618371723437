import styled from 'styled-components'
import collapseIcon from '../../../assets/icons/icon-collapse.svg'
import expandIcon from '../../../assets/icons/icon-expand.svg'

export const CollapseIcon = styled.img.attrs(props => ({
  src: (props.open && collapseIcon) || expandIcon
}))`
  height: 16px;
  width: 16px;
  margin-left: 10px;
`

export const CollapseLabel = styled.span`
  font-weight: 500;
  padding-left: 20px;
`
export const ProfileSide = styled.div`
  position: relative;
  display: flex;
  flex-basis: 23%;
  word-wrap: break-word;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 220px;

  @media(min-width: 768px) and (max-width: 1199.98px) {
    flex: none;
    flex-basis: 30%;
  }
  @media(max-width: 767.98px){
    flex: 1;
  }
`

export const SideCollapse = styled.div`
  position: relative;
  display: flex;
  flex-basis: ${props => (props.basis && `${props.basis}%`) || 1};
  flex-direction: ${props => (props.vertical && 'column') || 'row'};
  justify-content: space-between;
  padding-bottom: 20px;
  flex-grow: 1;
  padding-top: 20px;
  ${({ collapsible }) => collapsible && 'padding-top: 0;'}
  @media(max-width: 1199px) {
    flex: 1;
  }
`
