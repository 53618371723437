import React from 'react'
import { getMeData } from '../../utils'
import { getMsr } from '../../queries/member'
import PDRCredits, { LoadingMSR } from './PDRCredits'
import CompactPDR from './CompactView/CompactPDR'
import ErrorLoading from './ErrorLoading'
import WrapWithFetch from '../WrapWithFetch'

const PDRBrief = WrapWithFetch(({
  me,
  loading,
  isFetching,
  error,
  refetch,
  reload
}) => {
  if (loading || isFetching) {
    return <LoadingMSR />
  } else if (error || !me) {
    return (
      <ErrorLoading {...{ refetch, reload }} />
    )
  }
  return (
    <PDRCredits
      {...{
        me,
        error,
        refetch,
        loading
      }}
    />
  )
})

export default getMeData(getMsr)(PDRBrief)

export const CompactPDRBrief = getMeData(getMsr)(CompactPDR)
