import React from 'react'
import { ContentWrapper } from './utils/styles'
import { Container } from 'reactstrap'
import { useMutation } from '@apollo/client'
import { CREATE_CONSENT_RECEIPT } from './SignUp/CreateAccount/SignUpForm/mutations/createConsentReceipt'
import { ConsentModal } from '@pga/pga-component-library'

const ConsentWrapper = ({ me, history }) => {
  const [createConsentReceipt] = useMutation(CREATE_CONSENT_RECEIPT)
  return (
    <ContentWrapper data-testid='consent-wrapper'>
      <Container>
        <div className='my-4'>
          <ConsentModal data={me} mutation={createConsentReceipt} history={history} />
        </div>
      </Container>
    </ContentWrapper>
  )
}

export default ConsentWrapper
