import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'
import { ReactComponent as Logo } from '../../../../assets/img/logo-navy.svg'

const backgroundImgUrl = 'https://res.cloudinary.com/pgahq/image/upload/v1579707234/front-end-assets/account-creation-bg_2.jpg'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: ${Theme.colors.darkgrey};
  background-image: url(${backgroundImgUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: scroll;
  p,
  span {
    text-align: center;
  }
`

export const Card = styled.div`
  width: 530px;
  max-width: 90%;
  margin: auto;
  background-color: ${Theme.colors.white};
  box-shadow: 0 5px 10px 0 rgba(0, 35, 75, 0.15);
  ${Theme.media.mdDown} {
    padding: 20px 50px;
  }
  ${Theme.media.smDown} {
    padding: 20px 30px;
  }
  @media screen and (max-width: 576.98px) {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
`

export const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 68px 74px;
  padding-bottom: 0;
  margin-bottom: 10px;
  ${Theme.media.mdDown} {
    padding: 48px 34px;
    padding-bottom: 0;
  }
  ${Theme.media.smDown} {
    padding: 28px 14px;
    padding-bottom: 0;
  }
`

export const Title = styled.h1`
  width: 100%;
  font-family: ${Theme.fonts.Montserrat};
  font-weight: bold;
  font-size: 25px;
  color: ${Theme.colors.primary};
  flex-grow: 1;
  letter-spacing: 1px;
  ${Theme.media.smDown} {
    font-size: 20px;
  }
`
export const PGALogo = styled(Logo)`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  width: 100%;
  height: 60px;
  ${Theme.media.smDown} {
    height: 50px;
  }
`

export const SmallText = styled.div`
  align-self: flex-start;
  font-size: 13px;
`
