import apolloClient from '../../apolloClient'
import { graphql } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'

export const setLoading = (component = null) => apolloClient.mutate({
  mutation: SET_LOADING,
  variables: { component }
})

const SET_LOADING = gql`
  mutation SetLoading($component: String!) {
    setLoading(component: $component) @client
  }
`

export const getLoading = () => graphql(GET_LOADING, {
  props: ({ data }) => ({
    loading: data.loading,
    data
  })
})

export const GET_LOADING = gql`
  query getLoading {
    isLoading @client {
      component
    }
  }
`
export const removeLoading = (component = null) => apolloClient.mutate({
  mutation: REMOVE_LOADING,
  variables: { component }
})

const REMOVE_LOADING = gql`
  mutation RemoveLoading($component: String!) {
    removeLoading(component: $component) @client
  }
`

export const setIsSaved = (isSaved = false) => apolloClient.mutate({
  mutation: SET_IS_SAVED,
  variables: { isSaved }
})

export const SET_IS_SAVED = gql`
  mutation setSaveSuccess($isSaved: Boolean!) {
    setSaveSuccess(isSaved: $isSaved) @client
  }
`

export const GET_IS_SAVED = gql`
  query @client {
    savedSuccess {
      isSaved
    }
  }
`
