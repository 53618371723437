import { Theme } from '@pga/pga-component-library'

export const getPDRCredits = ({ eduPdr, meetPdr, totalPdr }) => {
  return [
    {
      label: eduPdr.label,
      earned: eduPdr.earned,
      required: eduPdr.required,
      recovery: eduPdr.recovery,
      totalDeficit: eduPdr.totalDeficit,
      new: eduPdr.new,
      color: Theme.colors.lightBlue
    },
    {
      label: meetPdr.label,
      earned: meetPdr.earned,
      required: meetPdr.required,
      recovery: meetPdr.recovery,
      totalDeficit: meetPdr.totalDeficit,
      new: meetPdr.new,
      color: Theme.colors.gold
    },
    {
      label: totalPdr.label,
      earned: totalPdr.earned,
      required: totalPdr.required,
      recovery: totalPdr.recovery,
      totalDeficit: totalPdr.totalDeficit,
      new: totalPdr.new,
      color: Theme.colors.primary
    }
  ]
}
