import React from 'react'
import { Field } from 'formik'
import { Theme } from '@pga/pga-component-library'
import styled from 'styled-components'

const HiddenCheckbox = styled(Field).attrs({
  type: 'checkbox'
})`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
  cursor: pointer;
  background: ${props => props.checked ? Theme.colors.lightBlue : 'white'}
  border: 1px solid ${props => props.checked ? Theme.colors.lightBlue : Theme.colors.pearlGrey};
  transition: all 150ms;
  ${Icon} {
    visibility: ${props => props.checked ? 'visible' : 'hidden'}
  }
`

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const CheckboxComp = ({ checked, name }) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} name={name} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox='0 0 24 24'>
        <polyline points='20 6 9 17 4 12' />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)
