import React, { Fragment } from 'react'
import styled from 'styled-components'
import { ListGroup as RSListGroup, ListGroupItem as RSListGroupItem, ListGroupItemText as RSListGroupItemText } from 'reactstrap'
import { map } from 'ramda'
import { blueGrey, lightgrey, lightBlue } from '../../assets/colors'
import { Montserrat } from '../../assets/fonts'
import { LinkIcon } from '../Icons'
import { ExpireMessage, formatDate, isExpired, WrappedAnchor } from './shared'

const ListGroupItem = styled(RSListGroupItem)`
  border: none;
  padding: 0.75rem 0;
  margin: 0 1.25rem;
`
const ListGroup = styled(RSListGroup)`
  border: none;

  && li + li {
    border-top: 2px solid ${lightgrey};
  }
`
const StyledI = styled.i`
  width: 7px;
  height: 19px;
  font-family: ${Montserrat};
  font-size: 14px;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: ${blueGrey};
`
const Dot = () => <StyledI>•</StyledI>

const DataBox = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
`
const Name = styled.div`
  font-family: ${Montserrat};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: ${lightBlue};
`
const ListGroupItemText = styled(RSListGroupItemText)`
  font-family: ${Montserrat};
  font-size: 14px;
  line-height: 1.36;
  color: ${blueGrey};
`
const MegaLinkIcon = styled(LinkIcon)`
  font-size: 20px;
  color: ${lightBlue};
`

export default ({ opportunities }) =>
  <ListGroup>
    {
      map(opp =>
        <>
          {!isExpired(opp.expireDate)
            ? (
              <ListGroupItem className='d-flex justify-content-left align-items-center' key={opp.id}>
                <div className='d-flex justify-content-between align-items-center w-100'>
                  <DataBox>
                    <WrappedAnchor href={opp.link} target='_blank'>
                      <Name>[{opp.format}] {opp.name}</Name>
                    </WrappedAnchor>
                    <ListGroupItemText tag='div'>{`+ ${opp.credits} CREDITS`} <Dot /> {opp.category} <Dot /> {opp.duration}</ListGroupItemText>
                    {opp.expireDate && <ExpireMessage>Expires {formatDate(opp.expireDate)}</ExpireMessage>}
                  </DataBox>
                  <WrappedAnchor href={opp.link} target='_blank'>
                    <MegaLinkIcon />
                  </WrappedAnchor>
                </div>
              </ListGroupItem>
              )
            : null}
        </>,
      opportunities
      )
    }
  </ListGroup>
