import React from 'react'
import { times, path } from 'ramda'
import LoadingPlaceholder from '../../LoadingPlaceholder'
import CreditOpportunitiesModal from '../../CreditOpportunitiesModal'
import { ButtonLink } from '../../ButtonLink'
import PDRInfo from './PDRInfo'
import { isValidMSR } from './utils'
import ReportFailure from '../../ReportFailure'
import MsrHistoryModal from '../../MsrHistoryModal'
import { formatDate } from '../../utils/formatter'
import { MSRBox, ButtonsBox, TitleBox, LoadingMSRBox, LinkFrame } from './Styles/Shared.styles'

export const Title = ({ text, dueDate }) => <TitleBox>{`${text} ${dueDate ? ` | DUE ${formatDate(dueDate)}` : ''}`} <LinkFrame href='https://resources.pga.org/my-membership/earn-and-report-pdrs' target='_blank' rel='noopener noreferrer'>(Learn More)</LinkFrame></TitleBox>

const MSRTitle = ({ dueDate }) => <Title text='PDR STATUS' dueDate={dueDate} />

export const LoadingMSR = () => (
  <MSRBox>
    <div>
      <LoadingPlaceholder h={25} w={125} />
      <LoadingPlaceholder h={40} w={350} className='mb-4' />
    </div>
    <div>
      <LoadingPlaceholder h={30} w={60} />
      <LoadingPlaceholder h={11} br={5} />
      <LoadingPlaceholder w={230} h={10} />
    </div>
    <div>
      <LoadingPlaceholder h={30} w={60} />
      <LoadingPlaceholder h={11} br={5} />
      <LoadingPlaceholder w={200} h={10} />
    </div>
    <div>
      <LoadingPlaceholder h={30} w={60} />
      <LoadingPlaceholder h={11} br={5} />
      <LoadingPlaceholder w={150} h={10} className='mb-4' />
    </div>
    <div>
      <LoadingPlaceholder h={10} />
      <ButtonsBox>
        {times(idx => <LoadingPlaceholder className='msr-btn' key={idx} h={45} />, 2)}
      </ButtonsBox>
      <LoadingPlaceholder h={10} w={100} />
    </div>
  </MSRBox>
)

export const CompressedMSRLoader = () => (
  <LoadingMSRBox>
    <LoadingPlaceholder h={30} w={100} />
    <LoadingPlaceholder h={30} w={100} />
    <LoadingPlaceholder h={30} w={100} />
  </LoadingMSRBox>
)

export default ({ me }) => (
  <MSRBox>
    <MSRTitle dueDate={path(['msrStatus', 'cycle', 'endDate'], me)} />
    {
      isValidMSR(path(['msrStatus', 'credits'], me))
        ? <PDRInfo me={me} />
        : <ReportFailure subject='displaying your PDR Credits' />
    }

    <ButtonsBox>
      <ButtonLink outline href='http://apps.pga.org/professionals/apps/membership/msr/' target='_blank' rel='noopener noreferrer' className='text-center msr-btn'>Report PDR Credits</ButtonLink>
      <CreditOpportunitiesModal />
    </ButtonsBox>
    <div>
      <MsrHistoryModal />
    </div>
  </MSRBox>
)
