import React from 'react'
import styled from 'styled-components'
import { Button } from '../../Button'
import { primaryShadow } from '../../../assets/colors'

export const InfoBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
`

export const AlignCenterBox = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media(max-width: 1199px){
    justify-content: center;
    flex-direction: column;
    ${Button} {
      width: 100%;
    }
  }
`

export const InfoFrame = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${props => props.color};
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 171px;
  padding: 15px 0;
`

export const AnnLabel = styled.span`
  margin-right: 10px;
`

export const Padding = styled.div`
  padding: 0 20px;
  width: 100%;
  @media(max-width: 1199px) {
    width: 100%;
  }
`

export const BoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-basis: 33%;
  box-shadow: 0 5px 10px 0 ${primaryShadow};  
  border-right: 0;
  background-clip: border-box;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

export const Title = ({ text }) => <div className='text-muted p-1'>{text}</div>

export const CardBox = styled.div`
  padding: 20px 0;
  background-color: white;
  box-shadow: 0 5px 10px 0 ${primaryShadow};
  flex-basis: 33%;
  height: 375px;
  @media (min-width: 768px) {
    flex-basis: 50%;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    flex-basis: 65%;
  }

  @media(max-width: 991.98px) {
    height: 385px;
  }

  @media(max-width: 767.98px) {
    height: 100%;
    flex-basis: 100%;
  }
`

export default undefined
