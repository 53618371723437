import { Loading, Text } from './Shared'

export default function Step1 ({ loading }) {
  if (loading) {
    return <Loading />
  } else {
    return (
      <>
        <Text>
          We've made the process to complete your background check quick and
          easy. Review the steps below and get started today.
        </Text>

        <Text>
          <b>Step 1: Pay for Background Check</b>

          <Text>
            The fee for the background check ensures your report is accurate and
            most importantly your data is secure. Quick online payment.
            <Text><b>Price: $60</b></Text>
          </Text>
        </Text>

        <Text>
          <b>Step 2: Submit Your Information </b>

          <Text>
            You Current Address, SSN, and Date of Birth are all you'll need to
            start your background check. Your results will be available in 5-10
            business days.
          </Text>
        </Text>
      </>
    )
  }
}
