const ACCOUNT_PGA_ORG_TAG = 'account-pga-org'

const fetchActiveFlagKeys = async () => {
  const rootEndpoint = process.env.REACT_APP_FLAGR_API_ROOT || 'https://flagr.dev.pga.com/api/v1'
  const res = await fetch(`${rootEndpoint}/flags?enabled=true&tags=${ACCOUNT_PGA_ORG_TAG}`)
  const data = await res.json()
  return new Set(
    data.map(flag => flag.key)
  )
}

const evaluateFlags = async (flagKeys, user) => {
  const rootEndpoint = process.env.REACT_APP_FLAGR_API_ROOT || 'https://flagr.dev.pga.com/api/v1'
  const res = await fetch(`${rootEndpoint}/evaluation/batch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      enableDebug: false,
      flagKeys,
      entities: [
        {
          entityID: (user && user.externalId) || '',
          entityType: 'user',
          entityContext:
            user != null
              ? {
                  user_id: user.externalId
                }
              : {}
        }
      ]
    })
  })
  const data = await res.json()

  const flagEvaluations = data.evaluationResults.reduce((acc, val) => {
    const { flagKey, variantKey } = val
    acc[flagKey] = variantKey
    return acc
  }, {})

  return flagEvaluations
}

export { fetchActiveFlagKeys, evaluateFlags }
