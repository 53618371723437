import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { Modal, ModalHeader, ModalBody, Title, TitleBar } from '../Modal'
import { lightBlue } from '../../assets/colors'
import { Montserrat } from '../../assets/fonts'
import MsrHistoryTable from './MsrHistoryTable/index'

export const StyledLink = styled.p`
  color: ${lightBlue};
  font-family: ${Montserrat};
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
`

export default () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <StyledLink onClick={handleToggle}>View All PDR History <i className='fa fa-angle-right' /></StyledLink>
      <Modal
        isOpen={isOpen}
        toggle={handleToggle}
        size='lg'
        centered
      >
        <ModalHeader toggle={handleToggle} />
        <TitleBar>
          <Title>Your PDR History</Title>
        </TitleBar>
        <ModalBody>
          <MsrHistoryTable />
        </ModalBody>
      </Modal>
    </>
  )
}
