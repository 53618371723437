import { useMutation } from '@apollo/client'
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import { COACH_TOOLS_API } from '../../../../../apolloClient'
import { BACKGROUND_CHECK_PAYMENT_SESSION } from '../../../../../pages/ProspectRegistration/query'
import { Loading } from './Shared'

const PUBLISHABLE_KEYS = {
  coach: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  membership: process.env.REACT_APP_STRIPE_MEMBERSHIP_PUBLISHABLE_KEY
}

const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState('')
  const [stripePromise, setStripePromise] = useState(null)

  const [createPaymentSession] = useMutation(BACKGROUND_CHECK_PAYMENT_SESSION, {
    variables: {
      returnUrl: `${window.location.origin}${window.location.pathname}?backgroundCheckConfirmation=true`
    },
    context: { api: COACH_TOOLS_API }
  })

  useEffect(() => {
    const fetchClientSecret = async () => {
      const { data } = await createPaymentSession()
      const stripeAccount = data?.createMembershipBackgroundCheckPaymentSession.stripeAccount
      setStripePromise(loadStripe(PUBLISHABLE_KEYS[stripeAccount] || PUBLISHABLE_KEYS.coach))
      setClientSecret(data?.createMembershipBackgroundCheckPaymentSession.clientSecret)
    }

    fetchClientSecret()
  }, [])

  return (
    <div id='checkout'>
      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  )
}

export default function Step2 ({ loading }) {
  return loading ? <Loading /> : <CheckoutForm />
}
