import React from 'react'
import styled from 'styled-components'
import { Theme, Button, InfoIcon, SuccessCheckmarkIcon } from '@pga/pga-component-library'
import { Modal, ModalHeader as RSModalHeader, ModalBody } from 'reactstrap'
import { PlayfairDisplay } from '../../assets/fonts'
import { primary } from '../../assets/colors'

export const ModalHeader = styled(RSModalHeader)`
  &.modal-header{
    padding-bottom: 0;
    border-bottom: none;
  }
`
export const TitleBar = styled.header`
  display: flex;
  flex-direction: ${props => props.titleVertical ? 'column' : 'row'};
  justify-content: ${props => props.jc || 'space-between'};
  align-items: center;
  margin: 0 30px;
`

export const Title = styled.div`
  font-family: ${props => props.fontFamily || PlayfairDisplay};
  font-size: ${props => props.mdFont ? '20' : '32'}px;
  font-weight: bold;
  color: ${primary};
`

export const NarrowModal = styled(Modal)`
  max-width: 490px;
  @media screen and (max-width: 575.98px) {
    min-width: 0px;
    width: 95%;
    margin: 10px;
  }
`
export const Body = styled(ModalBody)`
  padding: 0;
  margin: 30px;
`

export const Text = styled.p`
  font-family: ${Theme.fonts.Montserrat};
  font-size: 16px;
  color: ${Theme.colors.blueGrey};
  text-align: center;
`

export const Btn = styled(Button)`
  padding: 5px;
  margin-right: 10px;
  min-height: 42px;
  min-width: 135px;
  border: 1px solid ${Theme.colors.lightBlue};
  @media screen and (max-width: 575.98px) {
    margin-bottom: 10px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`

const getIconComponent = {
  Info: () => <InfoIcon />,
  Success: () => <SuccessCheckmarkIcon />
}

export const ModalComp = ({
  headerHandler,
  isOpen,
  message: { type, text, titleLabel, TextComp },
  modalBody,
  noHeader,
  primaryAction,
  secondaryAction,
  titleVertical,
  testId
}) => {
  const { handler: handlePrimaryAction, label: primaryActionLabel, outline: primaryActionOutline } = primaryAction || {}
  const { handler: handleSecondaryAction, label: secondaryActionLabel, outline: secondaryActionOutline } = secondaryAction || {}

  return (
    <NarrowModal isOpen={isOpen} size='lg' centered data-testid={testId}>
      <ModalHeader toggle={noHeader ? null : headerHandler} />

      <TitleBar jc='flex-start' titleVertical={titleVertical}>
        {type && getIconComponent[type]()}
        <Title fontFamily={Theme.fonts.Montserrat} mdFont>{titleLabel}</Title>
      </TitleBar>

      <Body>
        {text && <Text>{text}</Text>}
        {TextComp && <TextComp />}
        {modalBody}

        <ButtonWrapper>
          {
            primaryAction &&
              <Btn onClick={handlePrimaryAction} outline={primaryActionOutline}>
                {primaryActionLabel}
              </Btn>
          }

          {
            secondaryAction &&
              <Btn onClick={handleSecondaryAction} outline={secondaryActionOutline}>
                {secondaryActionLabel}
              </Btn>
          }
        </ButtonWrapper>
      </Body>
    </NarrowModal>
  )
}

export {
  Modal,
  ModalBody
}
