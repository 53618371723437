import { gql } from '@apollo/client'

export const cppProgramStatusQuery = gql`
  query {
    me {
      id
      education {
        currentCppProgramStatus
      }
    }
  }
`

export const registerEducationProgramMutation = gql`
  mutation RegisterEducationProgram($id: ID!, $productCode: String!) {
    register(id: $id, productCode: $productCode) {
      id
    }
  }
`
