import React from 'react'
import { useQuery } from '@apollo/client'
import MembershipDues from './MembershipDues'
import { getDues as CCMS_DUES_QUERY } from '../../queries/member'
import LoadingDues from './MembershipDues/LoadingDues'
import ErrorLoadingDues from './MembershipDues/ErrorLoadingDues'

import TabComponent from '../../Tabs'
import styled from 'styled-components'
import { flags, useFeatureFlags } from '../../../lib/feature-flags'
import LegacyMembershipDues from './LegacyMembershipDues'

const Container = styled.div`
  display: flex;
  flex: 1;
  min-height: 227px;
  & > div {
    flex: 1; 
  }
`

export const MembershipDuesWidget = () => {
  const [isCommerceDuesEnabled] = useFeatureFlags([flags.FLAG_FEAT_MEMBERSHIP_COMMERCE_DUES])

  return isCommerceDuesEnabled ? <MembershipDues /> : <LegacyMembershipDues />
}

const TabbedDuesWidget = () => {
  const tabs = [
    { label: '2024 - 2025 DUES', content: <MembershipDues /> },
    { label: '2023 - 2024 DUES', content: <LegacyMembershipDues />, selected: true }
  ]

  return <TabComponent tabs={tabs} />
}

const hasOutstandingDues = (dues) => {
  if (!dues || dues.length === 0) return false

  const invoiceDateThreshold = new Date('2023-05-01')
  const dueDateThreshold = new Date('2023-06-30')

  return dues.some(due =>
    Number(due.duesBalance) > 0 &&
    new Date(due.invoiceDate) >= invoiceDateThreshold &&
    new Date(due.dueDate) >= dueDateThreshold
  )
}

export default function MembershipFinance () {
  const [isMergeEnabled] = useFeatureFlags([flags.FLAG_FEAT_MERGE_CCMS_M360_INVOICE_REQS])

  if (!isMergeEnabled) return <Container><MembershipDuesWidget /></Container>

  return (
    <Container>
      <MembershipDuesData />
    </Container>
  )
}

const MembershipDuesData = () => {
  const { data: ccmsDuesData, error, loading, refetch } = useQuery(CCMS_DUES_QUERY, { fetchPolicy: 'cache-and-network' })

  if (loading) return <LoadingDues />

  if (error) return <ErrorLoadingDues refetch={refetch} />

  const ccmsDues = ccmsDuesData?.me?.dues
  return hasOutstandingDues(ccmsDues) ? <TabbedDuesWidget /> : <MembershipDuesWidget />
}
