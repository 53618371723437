import { ModalComp } from '../Modal'
import { Text } from './shared'

export default ({ isOpen, setIsOpen, link }) => (
  <ModalComp
    testId='exit-modal'
    isOpen={isOpen}
    message={{
      titleLabel: null
    }}
    primaryAction={{
      label: 'Continue',
      handler: () => window.open(link, '_blank')
    }}
    secondaryAction={{
      label: 'Cancel',
      handler: () => setIsOpen(false),
      outline: true
    }}
    secondaryActionOutline
    modalBody={
      <Text>
        Tapping "continue" will take you to the online change form. While we work to improve this experience, <b>you will be asked to login again</b>. We're sorry for the inconvenience.
      </Text>
    }
  />
)
