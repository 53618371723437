import React from 'react'
import { withAuthProvider } from '../../lib/auth'
import { CookiesProvider } from 'react-cookie'
import styled from 'styled-components'
import MediaQuery from 'react-responsive'
import MembershipFinance from '../widgets/MembershipFinance'
import ProgramEnrollmentCard from '../widgets/ProgramEnrollmentCard'
import { ImgFrame, Headline } from '../widgets/shared'
import Briefing from '../widgets/Briefing'
import AnnouncementsCard from '../widgets/Announcements'
import Resources from '../widgets/Resources'
import FlexRow from '../FlexRow'
import News from '../widgets/News'
import { primary, white } from '../../assets/colors'
import {
  isMember,
  isStudent,
  isAssociate,
  hasMembershipType,
  shouldNotSeeGeneric,
  isQualifierEligible,
  shouldSeeJobRecommendations,
  isTerminatedStaff,
  shouldSeeProspectDashboard
} from '../utils'
import { setLoading, removeLoading } from '../queries/loading'
import golfImg from '../../assets/img/golfduesimg.png'
import EducationAppDecisionModal from '../widgets/EducationAppDecisionModal'
import JobRecommendation from '../widgets/JobRecommendation'
import JobSeekerDashboard from './JobSeekerDashboard'
import MarketingBanner from '../MarketingBanner'
import MemberResources from '../widgets/Resources/MemberResources'
import ProspectDashboard from '../ProspectDashboard'
import { withProspectDateQuery } from '../../utils/prospect/withProspectDateQuery'

const FinanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 375px;
  @media (min-width: 768px) {
    flex-basis: 48%;
  }
  @media (min-width: 992px) {
    flex-basis: 33%;
    height: 100%;
  }

  @media (max-width: 767.98px) {
    flex-basis: 100%;
  }
`

export const MembershipDashboardContainer = styled(FlexRow)`
  align-items: flex-start;
  row-gap: 20px;
`

export const Dashboard = ({ me, authReady, currentMember }) => {
  !authReady ? setLoading('dashboard') : removeLoading('dashboard')

  if (isTerminatedStaff(me)) {
    return <JobSeekerDashboard me={me} />
  }

  if (shouldSeeProspectDashboard(me, currentMember)) {
    return <ProspectDashboard me={me} />
  }

  return authReady && !shouldNotSeeGeneric(me)
    ? (
      <JobSeekerDashboard me={me} />
      )
    : (
      <>
        <MembershipDashboardContainer>
          <Briefing />
          <MediaQuery maxWidth={1199}>
            {isMember(me) || isAssociate(me) || !hasMembershipType(me)
              ? (
                <AnnouncementsCard card />
                )
              : null}
          </MediaQuery>
          {isMember(me) || isAssociate(me)
            ? (
              <FinanceWrapper>
                {isMember(me)
                  ? (
                    <ProgramEnrollmentCard />
                    )
                  : isAssociate(me)
                    ? (
                      <ImgFrame src={golfImg} color={white} />
                      )
                    : null}
                {isMember(me) || isAssociate(me) ? <MembershipFinance /> : null}
              </FinanceWrapper>
              )
            : null}
          {hasMembershipType(me)
            ? (
                isMember(me)
                  ? (
                    <MemberResources />
                    )
                  : (
                    <Resources
                      flexBasis={isQualifierEligible(me) || isStudent(me) ? 100 : null}
                    />
                    )
              )
            : null}
        </MembershipDashboardContainer>
        {shouldSeeJobRecommendations(me)
          ? (
            <JobRecommendation {...{ me }} />
            )
          : null}
        <FlexRow>
          <Headline color={primary}>Top Headlines</Headline>
        </FlexRow>
        <FlexRow>
          <News me={me} />
        </FlexRow>
        <CookiesProvider>
          <EducationAppDecisionModal />
        </CookiesProvider>
      </>
      )
}

export const DashboardWithBanner = (props) => (
  <>
    <MarketingBanner {...props} />
    <Dashboard {...props} />
  </>
)

export default withProspectDateQuery(withAuthProvider(DashboardWithBanner))
