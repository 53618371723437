const bannerOptions = [{
  title: 'Registration for Deferred Compensation is now open for all Members in good standing.',
  link: 'https://resources.pga.org/member-benefits/deferred-compensation/',
  linkText: 'Learn More',
  color: 'pearlGrey',
  textColor: 'primary',
  membersOnly: true,
  showBanner: false
}, {
  title: 'We are experiencing issues with our phones. Please email membership@pgahq.com or try again later.',
  color: 'maroon',
  textColor: 'white',
  membersOnly: false,
  showBanner: false
}]

export default bannerOptions
