// Tabs.js
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Theme } from '@pga/pga-component-library'

const TabsContainer = styled.div``

TabsContainer.displayName = 'TabsContainer'

const TabHeaders = styled.div`
  display: flex;
`
TabHeaders.displayName = 'TabHeaders'

const TabHeader = styled.div`
  font-family: ${Theme.fonts.Montserrat};
  font-weight: 600;
  flex: 1;
  padding: 10px;
  text-align: center;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  color: ${({ active }) => (active ? Theme.colors.deepGrey : Theme.colors.lightBlue)};
  background-color: ${({ active }) => (active ? Theme.colors.white : Theme.colors.primaryOpaque)};
`
TabHeader.displayName = 'TabHeader'

const TabContent = styled.div``

TabContent.displayName = 'TabContent'

const TabPanel = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none')};
  flex: 1;
`
TabPanel.displayName = 'TabPanel'

const TabComponent = ({ tabs }) => {
  const initialTab = tabs.findIndex(tab => tab.selected) !== -1 ? tabs.findIndex(tab => tab.selected) : 0
  const [activeTab, setActiveTab] = useState(initialTab)

  return (
    <TabsContainer>
      <TabHeaders>
        {tabs.map((tab, index) => (
          <TabHeader
            key={index}
            active={activeTab === index}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </TabHeader>
        ))}
      </TabHeaders>
      <TabContent>
        {tabs.map((tab, index) => (
          <TabPanel key={index} active={activeTab === index}>
            {tab.content}
          </TabPanel>
        ))}
      </TabContent>
    </TabsContainer>
  )
}

TabComponent.displayName = 'TabComponent'

export default TabComponent
