let AuthProvider,
  PrivateRoute,
  RedirectToLogin,
  RedirectToLogout,
  redirectToLogout,
  RedirectToSignup,
  withAuthProvider,
  getToken

const { getImpersonationHeader, assumeUser, ImpersonationOverlay } = require('@pga/auth-flow-provider').impersonation

if (process.env.REACT_APP_AUTH_IMPL === 'auth0') {
  const AuthFlowProvider = require('@pga/auth-flow-provider').auth0.AuthFlowProvider
  const USER_QUERY = require('../components/queries/member').USER_QUERY
  const print = require('graphql').print
  const fetchCurrentUser = async (token) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_GRAPHQL_ENDPOINT}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          ...getImpersonationHeader()
        },
        body: JSON.stringify({ query: print(USER_QUERY) })
      })

      const json = await res.json()
      const currentUser = json?.data?.me
      if (!currentUser) {
        throw new Error('No user found')
      }
      return [currentUser, null]
    } catch (err) {
      return [null, err]
    }
  }
  const { LoadingComponent } = require('@pga/pga-component-library')
  const renderRedirecting = () => (<LoadingComponent />)
  AuthProvider = ({ children }) => (
    <AuthFlowProvider fetchCurrentUser={fetchCurrentUser} renderRedirecting={renderRedirecting}>
      {children}
    </AuthFlowProvider>
  )

  PrivateRoute = require('@pga/auth-flow-provider').auth0.PrivateRoute
  RedirectToLogin = require('@pga/auth-flow-provider').auth0.RedirectToLogin
  RedirectToLogout = require('@pga/auth-flow-provider').auth0.RedirectToLogout
  redirectToLogout = require('@pga/auth-flow-provider').auth0.redirectToLogout
  RedirectToSignup = require('@pga/auth-flow-provider').auth0.RedirectToSignup
  withAuthProvider = require('@pga/auth-flow-provider').auth0.withAuthProvider
  getToken = require('@pga/auth-flow-provider').auth0.getToken
} else {
  const AuthFlowProvider = require('@pga/auth-flow-provider').onelogin.AuthFlowProvider
  const apolloClient = require('../apolloClient').default
  const USER_QUERY = require('../components/queries/member').USER_QUERY
  AuthProvider = ({ children }) => (
    <AuthFlowProvider apolloClient={apolloClient} query={USER_QUERY} sessionCheckInterval={60000}>
      {children}
    </AuthFlowProvider>
  )

  PrivateRoute = require('@pga/auth-flow-provider').onelogin.PrivateRoute
  RedirectToLogin = require('@pga/auth-flow-provider').onelogin.RedirectToLogin
  RedirectToLogout = require('@pga/auth-flow-provider').onelogin.RedirectToLogout
  redirectToLogout = require('@pga/auth-flow-provider').onelogin.redirectToLogout
  if ((process.env.REACT_APP_AUTH_FLOW_BASE_URI || '').includes('/v2')) {
    RedirectToSignup = require('@pga/auth-flow-provider').auth0.RedirectToSignup
  } else {
    // signup is on account.pga.org/signup when using OneLogin
    RedirectToSignup = () => null
  }
  withAuthProvider = require('@pga/auth-flow-provider').onelogin.withAuthProvider
  getToken = require('@pga/auth-flow-provider').onelogin.getToken
}

export {
  AuthProvider,
  PrivateRoute,
  RedirectToLogin,
  RedirectToLogout,
  redirectToLogout,
  RedirectToSignup,
  withAuthProvider,
  getToken,
  getImpersonationHeader,
  assumeUser,
  ImpersonationOverlay
}
