import React, { useState, useEffect } from 'react'
import { withAuthProvider } from '../auth'
import { COACH_TOOLS_API } from '../../apolloClient'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'

export const USER_QUERY = gql`
  query UserQuery {
    currentMember {
      intercomHmac { web }
      externalId
    }
  }
`
export const UserContext = React.createContext()

const UserProvider = ({ children, me, isLoggedIn }) => {
  const [user, setUser] = useState(me)
  const { data, loading } = useQuery(USER_QUERY, {
    context: {
      api: COACH_TOOLS_API
    },
    skip: !isLoggedIn
  })

  useEffect(() => {
    if (isLoggedIn && !loading && data) {
      const userWithExternalId = { ...me, ...data?.currentMember }
      setUser(userWithExternalId)
    }
  }, [isLoggedIn, loading, data])

  return (
    <UserContext.Provider value={{ user }}>
      {children}
    </UserContext.Provider>
  )
}

export default withAuthProvider(UserProvider)
