import React from 'react'
import { MSRBox, ButtonsBox, TotalWrapper } from '../PDRCredits/Styles/Shared.styles'
import { Title } from '../Widget'
import { Button } from '../../Button'

export default ({ refetch, reload }) => (
  <MSRBox>
    <Title text='Error' />
    <div>
      <TotalWrapper>Failed to load</TotalWrapper>
      <br />
    </div>
    <br />
    <ButtonsBox>
      <Button onClick={() => reload(refetch)}>REFRESH</Button>
    </ButtonsBox>
  </MSRBox>
)
