import React from 'react'
import { getMeData } from '../../utils'
import ErrorLoading from './ErrorLoading'
import WrapWithFetch from '../WrapWithFetch'
import CourseCurriculum, { Loading } from './CourseCurriculum'
import { getMeEducation } from '../../queries/member'

export const CourseCurriculumWrapper = ({
  me,
  loading,
  isFetching,
  error,
  refetch,
  reload
}) => {
  if (loading || isFetching) {
    return <Loading />
  } else if (error || !me) {
    return (
      <ErrorLoading {...{ refetch, reload }} />
    )
  }
  return (
    <CourseCurriculum
      {...{
        me,
        error,
        refetch,
        loading
      }}
    />
  )
}

const CCWithRefetch = WrapWithFetch(CourseCurriculumWrapper)

export default getMeData(getMeEducation)(CCWithRefetch)
