import { gql } from '@apollo/client'
import { BACKGROUND_CHECK_ORDER_FRAGMENT } from '../../utils/prospect/withProspectQuery'

export const INITIATE_BACKGROUND_CHECK_MUTATION = gql`
  mutation {
    initiateMembershipBackgroundCheck {
      url
    }
  }
`

export const BACKGROUND_CHECK_PAYMENT_SESSION = gql`
  mutation ($returnUrl: String!) {
    createMembershipBackgroundCheckPaymentSession(returnUrl: $returnUrl) {
      clientSecret
      id
      stripeAccount
    }
  }
`

export const VERIFY_BACKGROUND_CHECK_PAYMENT = gql`
  query {
    currentMember {
      prospect {
        verifyBackgroundCheckPayment {
          ${BACKGROUND_CHECK_ORDER_FRAGMENT}
        }
      }
    }
  }
`
