import React, { useEffect, useRef } from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import './autocomplete-override.css'
import { StyledInput as Input } from './styles'

const apiLoader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  version: 'weekly',
  libraries: ['places']
})

const getAddressFields = (addresses) => {
  const addressFields = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'short_name',
    postal_code: 'short_name'
  }

  const address = {}
  if (!addresses) return address
  for (const { types: [addressType], [addressFields[addressType]]: value } of addresses) {
    if (addressFields[addressType]) {
      address[addressType] = value
    }
  }

  return address
}

export const handleAPILoad = async (autoCompleteInputRef, { setValue }) => {
  await apiLoader.load()

  const placesAutoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteInputRef.current,
    {
      types: ['address']
    }
  )

  const handleSelection = () => {
    const place = placesAutoComplete.getPlace()
    const components = place.address_components
    const address = getAddressFields(components)
    const handleAddressPopulate = (address) => {
      const streetOrRoute = address.street_number ? `${address.street_number} ${address.route}` : address.route
      setValue('address1', streetOrRoute)
      setValue('address2', '')
      setValue('city', address.locality)
      setValue('state', address.administrative_area_level_1)
      setValue('zip', address.postal_code)
      setValue('country', address.country)
    }
    return handleAddressPopulate(address)
  }

  placesAutoComplete.setFields(['address_components', 'formatted_address'])
  placesAutoComplete.addListener('place_changed', handleSelection)
}

export const AddressAutocomplete = ({ name, testId, placeholder, setValue, field }) => {
  const autoCompleteInputRef = useRef(null)

  useEffect(() => {
    handleAPILoad(autoCompleteInputRef, { setValue })
  }, [])

  return (
    <Input
      {...field}
      data-testid={testId}
      name={name}
      placeholder={placeholder}
      ref={autoCompleteInputRef}
      setValue={setValue}
    />
  )
}

export default AddressAutocomplete
