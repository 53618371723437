import React from 'react'
import { ModalComp } from '../Modal'

const message = {
  type: 'Info',
  text: 'We\'re sorry! Something went wrong and your changes were not saved.',
  titleLabel: 'Error'
}

export default ({ isOpen, toggleModal }) => <ModalComp isOpen={isOpen} message={message} actions={[{ label: 'OK', handler: toggleModal }]} />
