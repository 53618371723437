import styled from 'styled-components'

export default styled.div`
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  position: relative;
  margin: 5px;
  height: ${props => `${props.h ? props.h : 15}px`};
  width: ${props => `${props.w ? `${props.w}px` : 'auto'}`};
  border-radius: ${props => `${props.br ? props.br : 0}px`};

  @keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
  }
`
